import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { faUsers, faCogs, faFolderOpen, faClipboardCheck, faPoundSign, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { userAtom } from "../_atoms/userAtom";
import { useAtom } from "@dbeining/react-atom";
import { Role } from "../_helpers";
import { IRole } from "../customTypings/role";

const SettingsPage = () => {
    const currentUser = useAtom(userAtom);
    return (
        <Container fluid>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <h1>Settings</h1>

            {currentUser && currentUser.identity.roles.some((role: IRole) => [Role.UserAdministrator].indexOf(role.name) >= 0) && (
                <Row>
                    <h3>Users</h3>
                    <Link className="text-decoration-none mb-3" to="/Users" style={{ width: "15rem" }}>
                        <Card className="bg-light shadow">
                            <Card.Body>
                                <FontAwesomeIcon icon={faUsers} size="3x" />
                                <h5 className="my-2">Users</h5>
                                Manage users and roles
                            </Card.Body>
                        </Card>
                    </Link>
                </Row>
            )}
            <Row>
                <h3>General</h3>
                {/*<Link className="text-decoration-none mb-3" to="/General" style={{ width: "15rem" }}>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <FontAwesomeIcon icon={faCogs} size="3x" />
                            <h5 className="my-2">General Settings</h5>
                            Edit general settings
                        </Card.Body>
                    </Card>
                </Link>*/}
                <Link className="text-decoration-none mb-3" to="/JobFolders" style={{ width: "15rem" }}>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <FontAwesomeIcon icon={faFolderOpen} size="3x" />
                            <h5 className="my-2">Job Folders</h5>
                            Manage default job folders
                        </Card.Body>
                    </Card>
                </Link>
                <Link className="text-decoration-none mb-3" to="/Checklists" style={{ width: "15rem" }}>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
                            <h5 className="my-2">Checklists</h5>
                            Manage checklists
                        </Card.Body>
                    </Card>
                </Link>
                {/*<Link className="text-decoration-none mb-3" to="/Financials" style={{ width: "15rem" }}>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <FontAwesomeIcon icon={faPoundSign} size="3x" />
                            <h5 className="my-2">Financials</h5>
                            Manage financial settings
                        </Card.Body>
                    </Card>
                </Link>*/}
                <Link className="text-decoration-none mb-3" to="/EmailTemplates" style={{ width: "15rem" }}>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <FontAwesomeIcon icon={faEnvelope} size="3x" />
                            <h5 className="my-2">Email Templates</h5>
                            Manage email templates
                        </Card.Body>
                    </Card>
                </Link>
            </Row>
        </Container>
    );
};

export { SettingsPage };
