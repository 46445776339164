import { authHeader, handleResponse } from '../_helpers';

export const jobCommunicationService = {
    get,
    getAttachmentNames,
    getRecipients,
};

function get(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/jobcommunication/get?id=${id}`, requestOptions).then(handleResponse);
}

function getAttachmentNames( jobCommunicationId?: string) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/jobcommunicationattachment/getattachmentnames?jobCommunicationId=${jobCommunicationId}`, requestOptions).then(handleResponse);
}

function getRecipients( jobCommunicationId?: string) {
    const data = {
        filter: { jobCommunicationId },
        offset: 0,
        count: 999999
    };
    const requestOptions = { method: 'POST', headers: authHeader(), body: JSON.stringify(data) };
    return fetch(`api/jobcommunicationrecipient/all`, requestOptions).then(handleResponse);
}