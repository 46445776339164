import { ICustomer } from '@/customTypings/Customer';
import { ICustomerContact } from '@/customTypings/CustomerContact';
import { ICustomerContactNew } from '@/customTypings/CustomerContactNew';
import { authHeader, handleResponse } from '../_helpers';

export const customerService = {
    getAllListItems,
    getAllEntries,
    get,
    getFull,
    getAllContacts,
    add,
    addFull,
    addContact,
    edit,
    editFull,
    setPrimaryContact,
    editContact
};

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/customer/alllistitems`, requestOptions).then(handleResponse);
}

function getAllEntries() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/customer/allentries`, requestOptions).then(handleResponse);
}

function get(id: string) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/customer/get?id=${id}`, requestOptions).then(handleResponse);
}

function getFull(id: string) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/customer/getfull?id=${id}`, requestOptions).then(handleResponse);
}

function getAllContacts(id: string) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/customer/getallcontacts?id=${id}`, requestOptions).then(handleResponse);
}

function add(customer: ICustomer) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customer)
    };
    return fetch(`api/customer/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addFull(customer: ICustomerContact) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customer)
    };
    return fetch(`api/customer/addfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addContact(customerContact: ICustomerContactNew) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customerContact)
    };
    return fetch(`api/customer/addcontact`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function edit(customer: ICustomer){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customer)
    };
    return fetch(`api/customer/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function editFull(customer: ICustomerContact) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customer)
    };
    return fetch(`api/customer/editfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function setPrimaryContact(id: string, customerContactId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/customer/setprimarycontact/${id}/${customerContactId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function editContact(customerContact: ICustomerContactNew) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(customerContact)
    };
    return fetch(`api/customer/editcontact`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}