import { IProposal } from '@/customTypings/Proposal';
import { authHeader, handleResponse } from '../_helpers';

export const proposalService = {
    add,
    addWithTemplateFolders,
    setProposalChosen,
    update,
    get,
    getAll,
    getAllForJob,
    sendEmail
};

function add(proposal: IProposal) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };
    return fetch(`api/proposal/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addWithTemplateFolders(proposal: IProposal) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };
    return fetch(`api/proposal/addwithtemplatefolders`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function setProposalChosen(id: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/proposal/setproposalchosen?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function update(proposal: IProposal) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(proposal)
    };
    return fetch(`api/proposal/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function get(proposalId: string) {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader() };
    return fetch(`api/proposal/get/${proposalId}`, requestOptions).then(handleResponse)
}

function getAll() {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader() };
    return fetch(`api/proposal`, requestOptions).then(handleResponse);
}

function getAllForJob(jobId: string) {
    const requestOptions:RequestInit = { method: 'GET', credentials: 'include', headers: authHeader() };
    return fetch(`api/proposal/forjob?jobid=${jobId}`, requestOptions).then(handleResponse);
}

function sendEmail(id: string, emailTemplateId: string, documentIds: string[]){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ id, emailTemplateId, documentIds })
    };
    return fetch(`api/proposal/sendproposal`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });;
}