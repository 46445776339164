import { Alert, Button, Card, Col, ListGroup, Row, Stack } from "react-bootstrap";
import FolderTree from "../_components/FolderTree/FolderTree";
import { useEffect, useState } from "react";
import { jobFolderDefinitionService } from "../_services/jobfolderdefinition.service";
import { IFolderTreeFolder } from "../_components/FolderTree/Declarations";
import { jobTypeService } from "../_services/jobtype.service";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faFolderClosed, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

export default function JobTypeFolderSettings() {
    const [currentJobType, setCurrentJobType] = useState("0b690637-81f7-4eed-9ecd-104b9e1329fd");
    const [jobTypes, setJobTypes] = useState<IValueAndLabel[] | undefined>();

    const [structure, setStructure] = useState<IFolderTreeFolder | undefined>();
    const [status, setStatus] = useState<string | undefined>();
    const [alertVariant, setAlertVariant] = useState("danger");
    const [potentialChange, setPotentialChange] = useState(false);

    useEffect(() => {
        jobTypeService.getAllListItems().then(
            (response: IValueAndLabel[]) => {
                setJobTypes(response);
                if (response[0] !== null) setCurrentJobType(response[0].value);
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
            }
        );
    }, []);

    useEffect(() => {
        getFolder();
    }, [currentJobType]);

    const getFolder = () => {
        jobFolderDefinitionService.getByJobTypeId(currentJobType).then(
            (response) => {
                if (response !== undefined && response !== null && response !== "") {
                    setFolderKeys(response);
                    setStructure(response);
                } else {
                    setStructure({
                        name: "Root",
                        jobTypeId: currentJobType,
                        children: [
                            {
                                name: "Proposals",
                                jobTypeId: currentJobType,
                                specialHandling: "proposals",
                            } as IFolderTreeFolder,
                        ],
                    } as IFolderTreeFolder);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
            }
        );
    };

    const setFolderKeys = (folderTreeFolder: IFolderTreeFolder) => {
        if (folderTreeFolder.children) {
            folderTreeFolder.children?.map((childFolder) => {
                setFolderKeys(childFolder);
            });
        }
        folderTreeFolder.folderKey = folderTreeFolder.id ?? "";
    };

    const onFolderSave = (folderTreeFolder: IFolderTreeFolder) => {
        jobFolderDefinitionService.addFull(folderTreeFolder).then(
            (response) => {
                if (response.status !== "Failure") {
                    setAlertVariant("success");
                    setStatus("Update successful");
                    setPotentialChange(false);
                    getFolder();
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
            }
        );
    };

    const onFolderChange = (folderTreeFolder: IFolderTreeFolder) => {
        setPotentialChange(true);
        setStructure(folderTreeFolder);
    };

    return (
        <>
            <Row>
                <Stack direction="horizontal" gap={2}>
                    <h2>Job Type Folders</h2>
                    <Button
                        className="ms-auto"
                        variant="success"
                        disabled={structure === undefined || !potentialChange}
                        onClick={() =>
                            onFolderSave(
                                structure
                                    ? structure
                                    : ({
                                          name: "Root",
                                          jobTypeId: currentJobType,
                                      } as IFolderTreeFolder)
                            )
                        }
                    >
                        Save <FontAwesomeIcon icon={faFloppyDisk} />
                    </Button>
                    <Button
                        variant="warning"
                        disabled={structure === undefined || !potentialChange}
                        onClick={() => {
                            setPotentialChange(false);
                            getFolder();
                        }}
                    >
                        Reset <FontAwesomeIcon icon={faRotateLeft} />
                    </Button>
                </Stack>
            </Row>
            <Row>
                <Col lg="2">
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <h5 className="my-2">Job Type</h5>
                            {jobTypes ? (
                                <ListGroup variant="flush">
                                    {jobTypes.map((jobType) => (
                                        <ListGroup.Item key={jobType.value} action active={currentJobType === jobType.value} onClick={() => setCurrentJobType(jobType.value)}>
                                            {jobType.label}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : (
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Skeleton width="6rem" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Skeleton width="5rem" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Skeleton width="9rem" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Skeleton width="7rem" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Skeleton width="8rem" />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Skeleton width="5rem" />
                                    </ListGroup.Item>
                                </ListGroup>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            {structure ? (
                                <FolderTree data={structure} onChange={onFolderChange} />
                            ) : (
                                <Stack direction="horizontal" gap={2}>
                                    <FontAwesomeIcon icon={faFolderClosed} />
                                    <Skeleton width="5rem" />
                                </Stack>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {status && (
                <Row>
                    <Alert variant={alertVariant} onClose={() => setStatus(undefined)} dismissible>
                        {status}
                    </Alert>
                </Row>
            )}
        </>
    );
}
