import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
    faPen,
    faPlus,
    faTrash,
    faTrashArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { PagedList } from "../_components/PagedList/PagedList";
import {
    ColumnRender,
    FilterType,
    IColumn,
    IFilter,
    IPagedListAction,
} from "../_components/PagedList/Declarations";
import { AddNewChecklist } from "../_components/AddNewChecklist";
import { IChecklistDefinition } from "@/customTypings/ChecklistDefinition";
import { checklistDefinitionService } from "../_services";
import { EditChecklist } from "../_components/EditChecklist";

export default function ChecklistSettingsPage() {
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState("");

    const [refreshNumber, setRefeshNumber] = useState(0);

    const handleAdd = (added: boolean) => {
        if (added) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowAdd(false);
    };

    const handleEdit = (updated: boolean) => {
        if (updated) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowEdit(false);
    };

    const handleDeleted = (item: IChecklistDefinition) => {
        checklistDefinitionService.remove(item.id ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    setRefeshNumber(refreshNumber + 1);
                } else {
                    console.log(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    console.log(error.title);
                } else {
                    console.log(error);
                }
            }
        );
    };

    const handleRestore = (item: IChecklistDefinition) => {
        checklistDefinitionService.restore(item.id ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    setRefeshNumber(refreshNumber + 1);
                } else {
                    console.log(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    console.log(error.title);
                } else {
                    console.log(error);
                }
            }
        );
    };

    return (
        <Container fluid>
            <Helmet>
                <title>Checklists</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Checklists</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h1 className="my-3">Checklist Definitions</h1>
                    <PagedList
                        columns={[
                            {
                                member: "name",
                                renderAs: ColumnRender.text,
                                title: "Name",
                                width: 100,
                            } as IColumn,
                            {
                                member: "type",
                                renderAs: ColumnRender.text,
                                title: "Type",
                                width: 100,
                            } as IColumn,
                            {
                                member: "isDeleted",
                                renderAs: ColumnRender.deleted,
                                title: "Deleted",
                                width: 100,
                            } as IColumn,
                        ]}
                        itemActions={[
                            {
                                icon: faPen,
                                text: "Edit",
                                onClick: (item) => {
                                    setEditId(item.id);
                                    setShowEdit(true);
                                },
                                primary: true,
                            } as IPagedListAction,
                            {
                                icon: faTrash,
                                text: "Delete",
                                onClick: (item) => {
                                    handleDeleted(item);
                                },
                                availableMember: "isDeleted",
                                availableFliped: true,
                            } as IPagedListAction,
                            {
                                icon: faTrashArrowUp,
                                text: "Restore",
                                onClick: (item) => {
                                    handleRestore(item);
                                },
                                availableMember: "isDeleted",
                            } as IPagedListAction,
                        ]}
                        headActions={[
                            {
                                icon: faPlus,
                                text: "Add Entry",
                                onClick: () => {
                                    setShowAdd(true);
                                },
                            } as IPagedListAction,
                        ]}
                        filters={[
                            {
                                title: "Name",
                                member: "name",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Type",
                                member: "type",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Deleted",
                                member: "isDeleted",
                                type: FilterType.select,
                                options: { Yes: true, No: false, All: null },
                                defaultValue: false,
                            } as IFilter,
                        ]}
                        defaultSortBy="Name"
                        getUrl="api/checklistdefinition/all"
                        avatarUrl="api/avatar/get"
                        rowFormatter={(item: any) => {
                            return null;
                        }}
                        persistanceKey="ChecklistSettingsPage"
                        refreshNumber={refreshNumber}
                    />
                </Col>
            </Row>
            <AddNewChecklist show={showAdd} onClose={handleAdd} />
            <EditChecklist
                show={showEdit}
                onClose={handleEdit}
                checklistDefinitionId={editId}
            />
        </Container>
    );
}
