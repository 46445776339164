import Button from 'react-bootstrap/esm/Button';
import { useEffect, useState } from 'react';
import { Card, Form, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faGripVertical, faPen, faPlus, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { IChecklistDefinitionItem } from '../customTypings/ChecklistDefinitionItem';
import { clone } from '../_helpers/utils';

type Props = {
    checklistDefinitionItemOption: IChecklistDefinitionItem,
    onChange: (checklistDefinitionItem: IChecklistDefinitionItem) => void,
    onDelete: () => void,
}

export const EditChecklistDefitionItem: React.FC<Props> = ({ checklistDefinitionItemOption, onChange, onDelete }) => {
    const [checklistDefinitionItem, setChecklistDefinitionItem] = useState<IChecklistDefinitionItem>(clone(checklistDefinitionItemOption));
    const [editing, setEditing] = useState(false);
    const [updatedName, setUpdatedName] = useState(checklistDefinitionItem.name);
    
    useEffect(()=> { 
        setChecklistDefinitionItem(clone(checklistDefinitionItemOption));
    }, [checklistDefinitionItemOption]);
    
    const handleNameChange = () => {
        const updatedItem = clone(checklistDefinitionItem);
        updatedItem.name = updatedName;

        onChange(updatedItem);
    };

    return (
        <Card className="p-0">
            <div className="w-100 p-2">
                <Stack className="ps-2" direction="horizontal" gap={3}>
                    <FontAwesomeIcon icon={faGripVertical} />
                    {editing ? 
                        <>
                            <Form onSubmit={(e) => {
                                e.preventDefault()
                                handleNameChange()
                                setEditing(false)
                            }}>
                                <Form.Group controlId="formFolderName">
                                    <Form.Control type="text" defaultValue={updatedName} onChange={e => setUpdatedName(e.target.value)} />
                                </Form.Group>
                            </Form>
                            <Button 
                                className="ms-auto"
                                variant="outline-success" 
                                onClick={() => {
                                    handleNameChange()
                                    setEditing(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </Button>
                            <Button 
                                variant="outline-danger" 
                                onClick={() => {
                                    setUpdatedName(checklistDefinitionItem.name)
                                    setEditing(false)
                                }}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                            </Button>
                        </> :
                        <>
                            <label>
                                <span>{checklistDefinitionItem.name}</span>
                            </label>
                            <Button 
                                className="ms-auto"
                                variant="outline-primary" 
                                onClick={() => {
                                    setEditing(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button 
                                variant="outline-danger" 
                                onClick={() => {
                                    onDelete()
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </>
                    }
                </Stack>
            </div>
        </Card>
    );
}