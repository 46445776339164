import { IFolderDefinitionFolder } from "@/customTypings/FolderDefinitionFolder";
import React, { useEffect, useState } from "react";
import FolderDefinitionTreeFolder from "./FolderDefinitionTreeFolder";

type Props = {
    folderKey: string;
    data: IFolderDefinitionFolder[];
    onChange: (data: IFolderDefinitionFolder[], key: string) => void;
};

const FolderDefinitionTreeRecursive: React.FC<Props> = ({ folderKey, data, onChange }) => {
    const [treeState, setTreeState] = useState(data);

    useEffect(() => {
        onChange(treeState, folderKey);
    }, [treeState]);

    const onTreeStateChange = (newState: IFolderDefinitionFolder[], childFolderKey: string) => {
        const updatedFolder: IFolderDefinitionFolder[] = data.map((folder) => {
            if (folder.folderKey === childFolderKey) {
                return { ...folder, children: newState };
            }
            return folder;
        });

        onChange(updatedFolder, folderKey);
        //setTreeState(updatedFolder);
    };

    const onFolderAdd = (childFolderKey: string) => {
        const updatedFolder: IFolderDefinitionFolder[] = data.map((folder) => {
            if (folder.folderKey === childFolderKey) {
                var updatedChildren = [] as IFolderDefinitionFolder[];
                var newFolder = { folderKey: "new", id: undefined, type: folder.type, name: "New Folder" } as IFolderDefinitionFolder;

                if (folder.children) {
                    updatedChildren = folder.children;

                    const currentNewFolderNumber = updatedChildren.filter((folder) => {
                        return folder.name.startsWith("New Folder");
                    }).length;
                    if (currentNewFolderNumber > 0) {
                        newFolder.folderKey += currentNewFolderNumber;
                        newFolder.name += " (" + currentNewFolderNumber + ")";
                    }
                }

                updatedChildren.push(newFolder);
                const ret = { ...folder, children: updatedChildren };
                return ret;
            }
            return folder;
        });

        setTreeState(updatedFolder);
    };

    const onFolderChange = (childFolderKey: string, name: string, isOpen: boolean) => {
        const updatedFolder: IFolderDefinitionFolder[] = data.map((folder) => {
            if (folder.folderKey === childFolderKey) {
                return { ...folder, name: name, isOpen: isOpen };
            }
            return folder;
        });

        onChange(updatedFolder, folderKey);
        //setTreeState(updatedFolder);
    };

    const onFolderDelete = (childFolderKey: string) => {
        const updatedFolder: IFolderDefinitionFolder[] = data.filter((folder) => {
            return folder.folderKey !== childFolderKey;
        });

        setTreeState(updatedFolder);
    };

    return (
        <>
            {data.map((item) => (
                <FolderDefinitionTreeFolder key={item.folderKey} folder={item} onAdd={onFolderAdd} onChange={onFolderChange} onDelete={onFolderDelete} isRoot={false}>
                    {item.children && <FolderDefinitionTreeRecursive folderKey={item.folderKey} data={item.children} onChange={onTreeStateChange} />}
                </FolderDefinitionTreeFolder>
            ))}
        </>
    );
};

export default FolderDefinitionTreeRecursive;
