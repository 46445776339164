import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import { authHeader, handleResponse } from '../_helpers';
import dayjs from 'dayjs';

interface ISession {
  id: string;
  name: string;
  sessionStart: string;
  lastActivity: string;
  lastPath: string;

  duration: number;
  durationText: string;
  lastActivityWidth: number;
  sessionWidth: number;
  color: string;
}

const SessionsPage = () => {

  const [activeSessions, setActiveSessions] = useState<ISession[]>();
  const [inactiveSessions, setInactiveSessions] = useState<ISession[]>();
  const [activeSessionCount, setActiveSessionCount] = useState(0);
  const [update, setUpdate] = useState(Date.now());

  const ticks =  Array.from({length: 12}, (v, k) => k * 10);

  useEffect(() => {
    const interval = setInterval(() => setUpdate(Date.now()), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  
  useEffect(() => {
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch("api/session/GetSessions", requestOptions)
    .then(handleResponse)
    .then((response: ISession[]) => {
        const sessions = response.map(s => {
            let session: ISession = s;
            const lastActivity = dayjs(session.lastActivity);
            const sessionStart = dayjs(session.sessionStart);
            const now = dayjs();

            session.lastActivityWidth = Math.min(now.diff(lastActivity, "m"), 120) / 1.2;
            session.sessionWidth = Math.min(now.diff(sessionStart, "m"), 120) / 1.2;

            session.duration = now.diff(lastActivity, "m");
            session.durationText =
              now.diff(lastActivity, "h") + 
              new Date(now.diff(lastActivity, "ms")).toISOString().substring(13, 19);
            session.lastActivity = lastActivity.format('DD/MM/YYYY HH:mm:ss')
            
            if(session.duration < 5)
              session.color = "red";
            else if(session.duration < 60)
              session.color = "goldenrod";
            else
              session.color = "limegreen";

            return session;
        });

        const active = sessions.filter(s => s.duration < 5);
        const inactive = sessions.filter(s => s.duration >= 5);
        
        setActiveSessions(active);
        setInactiveSessions(inactive);
        setActiveSessionCount(active.length);
      });
  }, [update]);

  const tickCss = {margin: "12px 0 0 2px", fontSize: "0.75em", color: "#888"};

  const sectionHeader = (title: string) => {
    return  (
      <tr>
        <td colSpan={3} className="align-bottom" style={tickCss}>{title}</td>
        <td className="p-0">
          <div className="flex-expand position-relative" style={{height: "2em", margin:"0 0.5em 0 0"}}>
              {ticks.map((t, i) => 
                  <div key={i} style={{position: "absolute", left: `${(100/120)*t}%`, width: `${100/12}%`, height: "100%", borderLeft: "1px dashed rgba(0,0,0,0.1)"}}>
                    <div style={tickCss}>{t}</div>
                  </div>
              )}
          </div>
        </td>
      </tr>
    );
  }

  const sessionRow = (session: ISession) => {
    return (
      <tr key={session.id} title={session.lastPath}>
        <td>{session.name}</td>
        <td>{session.lastActivity}</td>
        <td style={{width: "5em"}}>{session.durationText}</td>
        <td className="p-0">
          <div style={{position: "relative", height: "2em", top: "50%", margin:"0 0.5em 0 0"}}>
            <div style={{position: "absolute", left: 0, top: "2px", bottom: "2px", right: 0, backgroundColor: "#f0f0f0"}}>
              <div style={{position: "absolute", left: 0, width: `${session.sessionWidth}%`, minWidth:"2px", height: "100%", background: "linear-gradient(90deg, transparent, #e0e0e0)", overflow: "visible"}}></div>
              <div style={{position: "absolute", left: 0, width: `${session.lastActivityWidth}%`, minWidth:"2px", height: "100%", backgroundColor: session.color}}></div>
            </div>
            <div className="position-absolute top-0 bottom-0 w-100">
                {ticks.map((t, i) => 
                    <div key={i} style={{position: "absolute", left: `${(100/120)*t}%`, width: `${100/12}%`, height: "100%", borderLeft: "1px dashed rgba(0,0,0,0.1)"}}>
                    </div>
                )}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <Container fluid>
      <Helmet>
        <title>User Sessions</title>
      </Helmet>

      <h1>User Sessions <Badge className="fs-5 align-middle">{activeSessionCount}</Badge></h1>

      <Row className='my-3'>
        <Col>
          <Card className="shadow">
            <Table className="table-sm">
              <thead className="bg-body border-bottom">
                <tr>
                  <th style={{width: "15em"}}>User</th>
                  <th style={{width: "10em"}}>Last Request</th>
                  <th colSpan={2}>Time since last request (vs. session duration)</th>
                </tr>
              </thead>
              <tbody>
                {sectionHeader("Active sessions")}

                {activeSessions && activeSessions.map(session => 
                  sessionRow(session)
                )}
                {inactiveSessions && inactiveSessions.length !== 0 && 
                  <>
                  {sectionHeader("Potentially inactive sessions")}

                  {inactiveSessions.map(session => 
                    sessionRow(session)
                  )}
                  </>
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export { SessionsPage };