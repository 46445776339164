import { Field, Form, Formik } from "formik";
import React from "react";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import { ITaskFlow } from "./Task/TaskFlow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faWarning } from "@fortawesome/free-solid-svg-icons";

type Props = {
    show: boolean;
    onClose: (action: string) => void;
    actions: ITaskFlow[];
    jobId?: string;
    info?: string;
};

const NextActionsModel: React.FC<Props> = ({
    show,
    onClose,
    actions,
    info = "",
}) => {
    const handleClose = () => onClose("");

    return (
        <Modal
            centered
            show={show}
            backdrop="static"
            keyboard={false}
            onHide={handleClose}
        >
            <Formik
                initialValues={{
                    action: "",
                }}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError }
                ) => {
                    setStatus();
                    setSubmitting(false);
                    onClose(values.action);
                }}
            >
                {({ values, errors, status, touched, isSubmitting }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Next Actions</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Stack gap={3}>
                                <div
                                    role="group"
                                    aria-labelledby="my-radio-group"
                                >
                                    <Stack gap={2}>
                                        {actions &&
                                            actions.map((action) => (
                                                <label key={action.value}>
                                                    <Field
                                                        type="radio"
                                                        name="action"
                                                        value={action.value}
                                                        className="me-2"
                                                    />
                                                    {action.label}
                                                </label>
                                            ))}
                                    </Stack>
                                </div>
                                {actions.find((a: ITaskFlow) => a.closeJob)
                                    ?.value === values.action && (
                                    <Alert variant="warning" className="mb-0">
                                        <FontAwesomeIcon
                                            className="me-2"
                                            icon={faWarning}
                                            size="lg"
                                        />{" "}
                                        By selecting this option, the job will
                                        be paused. (hiding any current tasks)
                                    </Alert>
                                )}
                                {info !== "" && (
                                    <Alert variant="info" className="mb-0">
                                        <FontAwesomeIcon
                                            className="me-2"
                                            icon={faInfoCircle}
                                            size="lg"
                                        />{" "}
                                        {info}
                                    </Alert>
                                )}
                                {status && (
                                    <Alert variant="danger" className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Adding..." />
                                    ) : (
                                        "Ok"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { NextActionsModel };
