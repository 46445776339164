import { IValueAndLabel } from "../customTypings/ValueAndLabel";

export enum TimesheetType {
    Other,
    Work,
    Travel,
}


export const allTimesheetTypes: IValueAndLabel[] = [
    { value: TimesheetType.Other.toString(), label: TimesheetType[TimesheetType.Other] },
    { value: TimesheetType.Work.toString(), label: TimesheetType[TimesheetType.Work] },
    { value: TimesheetType.Travel.toString(), label: TimesheetType[TimesheetType.Travel] },
];
