import { ITask } from '@/customTypings/Task';
import { ITaskChecklistItem } from '@/customTypings/TaskChecklistItem';
import { ITaskList } from '@/customTypings/TaskList';
import { authHeader, handleResponse } from '../_helpers';

export const taskService = {
    getAllListItems,
    add,
    get,
    edit,
    addMultiple,
    complete,
    getChecklist,
    editChecklistItem,
    getTaskNotesForJob
};

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/task/alllistitems`, requestOptions).then(handleResponse);
}

function add(task: ITask){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(task)
    };
    return fetch(`api/task/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function get(taskId: string){
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/task/get/${taskId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function edit(task: ITask){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(task)
    };
    return fetch(`api/task/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addMultiple(taskList: ITaskList){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(taskList)
    };
    return fetch(`api/task/addmultiple`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function complete(id: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/task/complete?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getChecklist(taskId: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/taskchecklistitem/getallfortask?taskId=${taskId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function editChecklistItem(taskChecklistItem: ITaskChecklistItem){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(taskChecklistItem)
    };
    return fetch(`api/taskchecklistitem/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getTaskNotesForJob(jobId: string){
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/task/tasknotes/${jobId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}