import React from 'react';
import Skeleton from 'react-loading-skeleton';

type AvatarProps = {
    size?: number;
    showName? : boolean
}

const AvatarSkeleton: React.FC<AvatarProps> = ({ size = 2, showName = true }) => {
    return (
        <div className="d-inline-flex align-items-center" style={{lineHeight: 0}}>
            <Skeleton circle width={`${size}em`} height={`${size}em`} className="d-flex flex-column me-2" />
            {showName && <Skeleton width="8em" height="1em" className="d-flex flex-column" />}
        </div>
    );
}

export { AvatarSkeleton };