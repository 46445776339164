import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { authenticationService } from "../_services";
import Button from "react-bootstrap/Button";
import { Col, Row, Image, Stack, Card, InputGroup, Form } from "react-bootstrap";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import VerificationInput from "react-verification-input";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface stateType {
    from: { pathname: string };
}

const SignInPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let from = (location.state as stateType).from?.pathname || "/";
    const currentUser = useAtom(userAtom);
    const [twoFactorRequired, setTwoFactorRequired] = useState(false);
    const [twoFactorQrCode, setTwoFactorQrCode] = useState("");
    const [usernameEntered, setUsernameEntered] = useState("");
    const [passwordEntered, setPasswordEntered] = useState("");

    const twoFaRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // redirect to home if already logged in
        if (currentUser) {
            navigate("/", { state: { message: "Ok" } });
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Sign In - React Crm Boilerplate</title>
            </Helmet>

            <div
                className="position-fixed top-0 start-0 vw-100 vh-100"
                style={{
                    backgroundImage: "url(SignInBackground.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    zIndex: "-1",
                }}
            ></div>
            <Row className="h-100">
                <Col lg={{ span: 5, offset: 1 }} md={{ span: 6 }} className="my-auto pb-5">
                    <Card>
                        <Card.Body>
                            <Image src="/ReactCrmBoilerplateLogo.png" style={{ height: "5rem" }} />
                            {!twoFactorRequired ? (
                                <>
                                    <h1 className="my-3">Sign in</h1>
                                    <Formik
                                        initialValues={{
                                            username: "",
                                            password: "",
                                            showPassword: false,
                                        }}
                                        validationSchema={Yup.object().shape({
                                            username: Yup.string().required("Username is required"),
                                            password: Yup.string().required("Password is required"),
                                        })}
                                        onSubmit={async ({ username, password }, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            await authenticationService.login(username, password).then(
                                                (response) => {
                                                    if (response.status === "Failure") {
                                                        setSubmitting(false);
                                                        setStatus(response.message);
                                                    } else if (response.status === "AuthenticationRequired") {
                                                        setTwoFactorRequired(true);
                                                        setUsernameEntered(username);
                                                        setPasswordEntered(password);
                                                        if (twoFaRef && twoFaRef.current) twoFaRef.current.focus();
                                                    } else if (response.advisories !== null && response.advisories.twoFaSetupRequired !== null && response.advisories.twoFaSetupRequired === true) {
                                                        navigate("/twofactorSetup", {
                                                            replace: true,
                                                        });
                                                    } else {
                                                        navigate(from, {
                                                            replace: true,
                                                        });
                                                    }
                                                },
                                                (error) => {
                                                    setSubmitting(false);
                                                    setStatus(error);
                                                }
                                            );
                                        }}
                                    >
                                        {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Stack gap={3}>
                                                    <Form.Group className="mb-3" controlId="username">
                                                        <Form.Label>Email address</Form.Label>
                                                        <Field name="username" type="text" className={"form-control" + (errors.username && touched.username ? " is-invalid" : "")} />
                                                        <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="password">
                                                        <Form.Label>Password</Form.Label>
                                                        <InputGroup hasValidation>
                                                            <Field
                                                                name="password"
                                                                type={values.showPassword ? "text" : "password"}
                                                                className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                                                            />
                                                            <Button variant="outline-secondary" onClick={() => setFieldValue("showPassword", !values.showPassword)}>
                                                                <FontAwesomeIcon icon={values.showPassword ? faEyeSlash : faEye} />
                                                            </Button>
                                                            <ErrorMessage
                                                                name="password"
                                                                component="div"
                                                                className="invalid-feedback"
                                                                render={(errorMessage) => <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
                                                            />
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Stack direction="horizontal" gap={3}>
                                                            <div>
                                                                <Button
                                                                    variant="secondary"
                                                                    disabled={isSubmitting}
                                                                    onClick={() =>
                                                                        navigate("/forgotpassword", {
                                                                            state: {
                                                                                message: "Ok",
                                                                            },
                                                                        })
                                                                    }
                                                                >
                                                                    Forgot password?
                                                                </Button>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <Button variant="success" disabled={isSubmitting} type="submit">
                                                                    {isSubmitting ? <LoadingSpinner text="Signing in..." /> : "Sign In"}
                                                                </Button>
                                                            </div>
                                                        </Stack>
                                                    </Form.Group>
                                                    {status && <div className={"alert alert-danger"}>{status}</div>}
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <h1 className="my-3">Two-Factor Authentication</h1>
                                    <p>Enter the authentication code from the authentication app on your mobile device</p>
                                    <Formik
                                        initialValues={{}}
                                        onSubmit={async ({}, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            await authenticationService.login(usernameEntered, passwordEntered, twoFactorQrCode).then(
                                                (response) => {
                                                    if (response.status === "Failure") {
                                                        setSubmitting(false);
                                                        setStatus(response.message);
                                                    } else if (response.status === "AuthenticationRequired") {
                                                        console.log("Error", response);
                                                    } else {
                                                        navigate(from, {
                                                            replace: true,
                                                        });
                                                    }
                                                },
                                                (error) => {
                                                    setSubmitting(false);
                                                    setStatus(error);
                                                }
                                            );
                                        }}
                                    >
                                        {({ status, isSubmitting, handleSubmit }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Stack gap={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="disabledUsername">Email address</Form.Label>
                                                        <Form.Control id="disabledUsername" placeholder={usernameEntered} disabled readOnly />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="validationCode">Validation Code</Form.Label>
                                                        <VerificationInput
                                                            ref={twoFaRef}
                                                            value={twoFactorQrCode}
                                                            length={6}
                                                            validChars={"0-9"}
                                                            placeholder={""}
                                                            onChange={(value) => setTwoFactorQrCode(value)}
                                                        />
                                                    </Form.Group>
                                                    <div className="ms-auto">
                                                        <Button variant="success" disabled={isSubmitting} type="submit">
                                                            {isSubmitting ? <LoadingSpinner text="Validating..." /> : "Validate"}
                                                        </Button>
                                                    </div>
                                                    {status && <div className={"alert alert-danger"}>{status}</div>}
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export { SignInPage };
