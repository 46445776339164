import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { proposalService } from "../../_services";
import { IProposal } from "../../customTypings/Proposal";

type Props = {
    show: boolean;
    onClose: (updatedId?: string | undefined) => void;
    proposal: IProposal;
};

const EditProposalModal: React.FC<Props> = ({ show, onClose, proposal }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(undefined);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    cost: proposal.cost ?? 0,
                    isDNOPermissionRequired: proposal.isDNOPermissionRequired ?? false,
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    var updatedProposal: IProposal = {
                        id: proposal.id,
                        name: proposal.name,
                        cost: values.cost,
                        folderId: proposal.folderId,
                        isDNOPermissionRequired: values.isDNOPermissionRequired,
                        jobId: proposal.jobId,
                        status: proposal.status,
                    };
                    proposalService.update(updatedProposal).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(updatedProposal.id);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("cost", error.errors.Cost);
                                setFieldError("isDNOPermissionRequired", error.errors.IsDNOPermissionRequired);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Proposal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="addCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    name="isDNOPermissionRequired"
                                    className={"mt-1 form-check" + (errors.isDNOPermissionRequired && touched.isDNOPermissionRequired ? " is-invalid" : "")}
                                    defaultChecked={values.isDNOPermissionRequired.valueOf()}
                                    onChange={(e) => {
                                        setFieldValue("isDNOPermissionRequired", e.target.checked == null ? false : e.target.checked);
                                    }}
                                    label="DNO Permission Required?"
                                />
                                <ErrorMessage name="isAllDay" component="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="costInput">
                                <Form.Label column sm={3}>
                                    Cost
                                </Form.Label>
                                <Col sm={9}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>£</InputGroup.Text>
                                        <Field name="cost" type="number" className={"form-control" + (errors.cost && touched.cost ? " is-invalid" : "")} />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                    </InputGroup>
                                    <ErrorMessage name="cost" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Updating proposal..." /> : "Update proposal"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { EditProposalModal };
