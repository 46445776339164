import { useState } from "react";
import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddNewUser } from "../_components";
import { Avatar } from "../_components/Avatar";
import { IIdentity } from "@/customTypings/Identity";
import { useNavigate } from "react-router-dom";

const UsersListPage = () => {
    const [showInviteUser, setShowInviteUser] = useState(false);
    const navigate = useNavigate();

    const handleAddUser = () => {
        setShowInviteUser(true);
    };

    return (
        <>
            <PagedList
                columns={[
                    {
                        member: "displayName",
                        renderAs: ColumnRender.customRenderer,
                        title: "Name",
                        width: 300,
                        customRenderer: (item: IIdentity) => {
                            return (
                                <td>
                                    <Avatar identity={item} />
                                </td>
                            );
                        },
                    } as IColumn,
                    {
                        member: "email",
                        renderAs: ColumnRender.text,
                        title: "Email",
                        width: 250,
                    } as IColumn,
                    {
                        member: "twoFAIsEnabled",
                        renderAs: ColumnRender.checkCross,
                        title: "2FA?",
                        width: 100,
                    } as IColumn,
                    {
                        member: "isLocked",
                        renderAs: ColumnRender.checkCross,
                        title: "Locked?",
                        width: 100,
                    } as IColumn,
                    {
                        member: "isEnabled",
                        renderAs: ColumnRender.checkCross,
                        title: "Enabled?",
                        width: 100,
                    } as IColumn,
                    {
                        member: "color",
                        renderAs: ColumnRender.color,
                        title: "Colour",
                        width: 100,
                        canSort: false,
                    } as IColumn,
                ]}
                itemActions={[
                    {
                        text: "Detail",
                        onClick: (item) => {
                            navigate(`/users/${item.id}`);
                        },
                        primary: true,
                    } as IPagedListAction,
                ]}
                headActions={[
                    {
                        icon: faPlus,
                        text: "Add User",
                        onClick: handleAddUser,
                    } as IPagedListAction,
                ]}
                filters={[
                    {
                        title: "Name",
                        member: "displayName",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Email",
                        member: "email",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Enabled",
                        member: "isEnabled",
                        type: FilterType.select,
                        options: { All: null, Enabled: true, Disabled: false },
                    } as IFilter,
                    {
                        title: "Locked",
                        member: "isLocked",
                        type: FilterType.select,
                        options: { All: null, Locked: true, Unlocked: false },
                    } as IFilter,
                    {
                        title: "2FA is enabled",
                        member: "twoFAIsEnabled",
                        type: FilterType.select,
                        options: { All: null, Enabled: true, Disabled: false },
                    } as IFilter,
                ]}
                defaultSortBy="displayName"
                getUrl="api/users/all"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey="UsersPage"
            />

            <AddNewUser
                show={showInviteUser}
                onClose={() => {
                    setShowInviteUser(false);
                }}
            />
        </>
    );
};

export { UsersListPage };
