import { useEffect, useState } from 'react';
import { Row, Col, Stack, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { clone } from '../_helpers/utils';
import { TaskPlantMachineryChecklistItem } from './TaskPlantMachineryChecklistItem';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ITaskChecklistItem } from '../customTypings/TaskChecklistItem';

type Props = {
    taskChecklistItemOptions: ITaskChecklistItem[],
    onChange: (taskChecklistItemOptions: ITaskChecklistItem[]) => void,
}
   
const TaskPlantMachineryChecklist: React.FC<Props> =  ({taskChecklistItemOptions, onChange}) =>  {
    const [taskChecklistItems, setTaskChecklistItems] = useState<ITaskChecklistItem[]>(clone(taskChecklistItemOptions));
    
    useEffect(()=> { 
        setTaskChecklistItems(clone(taskChecklistItemOptions));
    }, [taskChecklistItemOptions]);
    
    const handleOnAdd = () => {
        const updatedColumns = clone(taskChecklistItems)
        const newItem: ITaskChecklistItem = {
            id: undefined,
            name: "new",
            isComplete: false,
            displayOrder: taskChecklistItems.length,
            taskId: undefined
        }
        updatedColumns.push(newItem)
        setTaskChecklistItems(updatedColumns);
        onChange(updatedColumns);
    };
    
    const handleOnChange = (taskChecklistItem: ITaskChecklistItem) => {
        const updatedColumns = clone(taskChecklistItems);
        updatedColumns.splice(taskChecklistItem.displayOrder, 1, taskChecklistItem);
    
        setTaskChecklistItems(updatedColumns);
        onChange(updatedColumns);
    };
    
    const handleOnDelete = (index: number) => {
        const updatedColumns = clone(taskChecklistItems);
        updatedColumns.splice(index, 1);

        updatedColumns.map((value, index) => {
            value.displayOrder = index
        })
    
        setTaskChecklistItems(updatedColumns);
        onChange(updatedColumns);
    };

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const updatedColumns = clone(taskChecklistItems);
        const [reorderedItem] = updatedColumns.splice(result.source.index, 1);
        updatedColumns.splice(result.destination.index, 0, reorderedItem);

        updatedColumns.map((value, index) => {
            value.displayOrder = index
        })
    
        setTaskChecklistItems(updatedColumns);
        onChange(updatedColumns);
    };

    return (
        <>
            <Stack gap={2} >
                <Row>
                    <Col>
                        <span>
                            You can add, edit or remove items in this list by selecting them below. To change the order, drag and drop a field.
                        </span>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" className="me-2" onClick={() => handleOnAdd()}>
                            <FontAwesomeIcon icon={faPlus} /> Add item
                        </Button>
                    </Col>
                </Row>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="members-list">
                        {(provided) => (
                            <Stack gap={2} {...provided.droppableProps} ref={provided.innerRef}>
                                { taskChecklistItems.map((taskChecklistItem, index) =>
                                    <Draggable key={`${taskChecklistItem.displayOrder}-${taskChecklistItem.name}`} draggableId={`${taskChecklistItem.displayOrder}-${taskChecklistItem.name}`} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <TaskPlantMachineryChecklistItem taskChecklistItemOption={taskChecklistItem} onChange={(updatedItem)=> {handleOnChange(updatedItem)}} onDelete={() => {handleOnDelete(index)}} />
                                            </div>
                                        )}
                                    </Draggable>
                                )}
                                {provided.placeholder}
                            </Stack>
                        )}
                    </Droppable>
                </DragDropContext>
            </Stack>
        </>
    )
}

export { TaskPlantMachineryChecklist };