import { IFolderTreeFolder } from '@/_components/FolderTree/Declarations';
import { authHeader, handleResponse } from '../_helpers';

export const jobFolderDefinitionService = {
    getByJobTypeId,
    addFull
};

function getByJobTypeId(jobTypeId: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/jobfolderdefinition/getbyjobtypeid?jobTypeId=${jobTypeId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addFull(jobFolderTreeFolder: IFolderTreeFolder) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(jobFolderTreeFolder)
    };
    return fetch(`api/jobfolderdefinition/addfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}