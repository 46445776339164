export enum TaskType {
    AdHoc,
    Design,
    DNO,
    ChaseDNO,
    PostInstallationDNO,
    ChasePostInstallationDNO,
    SetInstallationDate,
    Redesign,
    EquipmentReservation,
    ContractAgreement,
    PreSaleSurvey,
    PostSaleSurvey,
    RaiseInvoice,
    ChaseInvoice,
    EquipmentReminder,
    CheckSiteEquipmentOnSitePickedUp,
    Checklists,
    AcceptanceByCustomer,
    MCSCertificate,
    NapitCertificate,
    CompletePaperclip,
    HandoverPackSent,
    Review,
    RaisePreSaleInvoice,
    ChasePreSaleInvoice
}

export namespace TaskType {
    export function toString(taskType: TaskType): string {
        return TaskType[taskType];
    }

    export function fromString(taskType: string): TaskType {
        return (TaskType as any)[taskType];
    }

    export function discription(taskType: TaskType): string {
        switch (taskType) {
            case TaskType.ChaseDNO:
                return 'Chase DNO';
            case TaskType.PostInstallationDNO:
                return 'Post Installation DNO';
            case TaskType.ChasePostInstallationDNO:
                return 'Chase Post Installation DNO';
            case TaskType.SetInstallationDate:
                return 'Set Installation Date';
            case TaskType.EquipmentReservation:
                return 'Equipment Reservation';
            case TaskType.ContractAgreement:
                return 'Contract Agreement';
            case TaskType.PreSaleSurvey:
                return 'Pre-Sale Survey';
            case TaskType.PostSaleSurvey:
                return 'Post-Sale Survey';
            case TaskType.RaiseInvoice:
                return 'Raise Invoice';
            case TaskType.ChaseInvoice:
                return 'Chase Invoice';
            case TaskType.EquipmentReminder:
                return 'Equipment Reminder';
            case TaskType.CheckSiteEquipmentOnSitePickedUp:
                return 'Check Site Equipment on Site / Picked Up';
            case TaskType.AcceptanceByCustomer:
                return 'Acceptance By Customer';
            case TaskType.MCSCertificate:
                return 'MCS Certificate';
            case TaskType.NapitCertificate:
                return 'Napit Certificate';
            case TaskType.CompletePaperclip:
                return 'Complete Paperclip';
            case TaskType.HandoverPackSent:
                return 'Handover Pack Sent';
            case TaskType.RaisePreSaleInvoice:
                return 'Raise Pre-Sale Invoice';
            case TaskType.ChasePreSaleInvoice:
                return 'Chase Pre-Sale Invoice';
            default:
                return TaskType[taskType];
        }
    }
}