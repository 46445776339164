import { IUser } from "../customTypings/user";
import { Atom, swap } from "@dbeining/react-atom";

export const userAtom = Atom.of<IUser | undefined>(undefined);
export function updateUserStateAtom(identity: IUser | undefined) {
    swap(userAtom, () => identity);
    if(identity){
        localStorage.setItem('currentUser', JSON.stringify(identity));
        console.log("Updating Local Storage")
    } else {
        localStorage.removeItem('currentUser');
        console.log("Removing Local Storage")
    }
}
export function updateUserStateAtomToLocalStorage() {
    const currentUserLocalStorage: string | null = localStorage.getItem('currentUser');
    const currentUserJSON: IUser | undefined = currentUserLocalStorage === null ? undefined : JSON.parse(currentUserLocalStorage);
    updateUserStateAtom(currentUserJSON)
}