import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from "react-helmet";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { timesheetService } from "../_services/timesheet.service";
import { useEffect, useState } from "react";
import { ITimesheet } from "@/customTypings/Timesheet";
import { Alert, Button, Card, Placeholder, Stack } from "react-bootstrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ConfirmationModel } from "../_components/ConfirmationModel";
import Skeleton from "react-loading-skeleton";
import { TimesheetType } from "../_constants/TimesheetType";
import { AddTimesheetModel } from "../_components/AddTimesheetModel";
import { jobService } from "../_services";
import { IJob } from "../customTypings/Job";
import { NavLink } from "react-router-dom";
dayjs.extend(relativeTime);

const MyTimesheet = () => {
    const currentUser = useAtom(userAtom);
    const [loading, setLoading] = useState(true);
    const [alertText, setAlertText] = useState<string | undefined>(undefined);
    const [activeTimesheet, setActiveTimesheet] = useState<ITimesheet | null>(null);
    const [activeJob, setActiveJob] = useState<IJob | null>(null);
    const [showStartDayConfirm, setShowStartDayConfirm] = useState(false);
    const [showCompleteDayConfirm, setShowCompleteDayConfirm] = useState(false);
    const [showAddTimsheetEntry, setShowAddTimsheetEntry] = useState(false);

    useEffect(() => {
        handleGetTimesheet();
    }, []);

    useEffect(() => {
        if (activeTimesheet?.jobId != undefined) {
            handleGetJob();
        } else {
            setActiveJob(null);
        }
    }, [activeTimesheet?.jobId]);

    const handleGetTimesheet = () => {
        setAlertText(undefined);
        timesheetService.getActive().then(
            (response) => {
                if (response.status !== "Failure") {
                    setActiveTimesheet(response);
                    setLoading(false);
                } else {
                    setAlertText(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setAlertText(error);
                } else {
                    setAlertText(error);
                }
            }
        );
    };

    const handleGetJob = () => {
        setAlertText(undefined);
        jobService.get(activeTimesheet?.jobId ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    setActiveJob(response);
                    setLoading(false);
                } else {
                    setAlertText(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setAlertText(error);
                } else {
                    setAlertText(error);
                }
            }
        );
    };

    const handleCompleteTask = () => {
        setAlertText(undefined);
        timesheetService.complete(activeTimesheet?.id ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    handleGetTimesheet();
                } else {
                    setAlertText(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setAlertText(error);
                } else {
                    setAlertText(error);
                }
            }
        );
    };

    const handleStartDay = () => {
        setAlertText(undefined);
        const newTimesheet: ITimesheet = {
            type: TimesheetType.Travel,
        };
        timesheetService.add(newTimesheet).then(
            (response) => {
                if (response.status !== "Failure") {
                    handleGetTimesheet();
                } else {
                    setAlertText(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    setAlertText(error);
                } else {
                    setAlertText(error);
                }
            }
        );
    };

    return (
        <Container fluid>
            <Helmet>
                <title>My Timesheet</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">My Timesheet</h1>
                    <Card>
                        <Card.Body>
                            {loading ? (
                                <>
                                    <Card.Title>Active Task</Card.Title>
                                    <Row>
                                        <Col sm={3}>Type</Col>
                                        <Col>
                                            <Skeleton width="20rem" height="2rem" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>Start</Col>
                                        <Col>
                                            <Skeleton width="20rem" height="2rem" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={3}>Started</Col>
                                        <Col>
                                            <Skeleton width="20rem" height="2rem" />
                                        </Col>
                                    </Row>
                                    <Stack gap={3} direction="horizontal">
                                        <Placeholder.Button variant="outline-success" xs={1} className="ms-auto" />
                                        <Placeholder.Button variant="success" xs={1} />
                                    </Stack>
                                </>
                            ) : (
                                <>
                                    {activeTimesheet ? (
                                        <>
                                            <Card.Title>Active Task</Card.Title>
                                            {activeJob && (
                                                <Row>
                                                    <Col sm={3}>Job</Col>
                                                    <Col>
                                                        <NavLink to={"/jobs/" + activeJob.id}>{activeJob.jobNumber}</NavLink>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col sm={3}>Type</Col>
                                                <Col>{activeTimesheet.type ? activeTimesheet.type : "Not set yet"}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>Start</Col>
                                                <Col>{activeTimesheet.startDate ? dayjs(activeTimesheet.startDate).format("DD-MM-YYYY HH:mm:ss") : "Not set yet"}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>Started</Col>
                                                <Col>{dayjs(activeTimesheet.startDate).fromNow()}</Col>
                                            </Row>
                                            <Stack gap={3} direction="horizontal">
                                                <Button className="ms-auto" variant="outline-success" onClick={() => setShowCompleteDayConfirm(true)}>
                                                    Complete day
                                                </Button>
                                                <Button variant="success" onClick={() => setShowAddTimsheetEntry(true)}>
                                                    New Task
                                                </Button>
                                            </Stack>
                                        </>
                                    ) : (
                                        <>
                                            <Card.Title>Active Task</Card.Title>
                                            <Row>
                                                <Col sm={3}>Type</Col>
                                                <Col>Not set yet</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>Start</Col>
                                                <Col>Not set yet</Col>
                                            </Row>
                                            <Row>
                                                <Col sm={3}>Started</Col>
                                                <Col></Col>
                                            </Row>
                                            <Stack gap={3} direction="horizontal">
                                                <Button className="ms-auto" variant="success" onClick={() => setShowStartDayConfirm(true)}>
                                                    Start day
                                                </Button>
                                            </Stack>
                                        </>
                                    )}
                                </>
                            )}
                            {alertText && (
                                <Alert variant="danger" className="mt-3">
                                    {alertText}
                                </Alert>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ConfirmationModel
                show={showStartDayConfirm}
                onClose={(confirmed) => {
                    if (confirmed) {
                        handleStartDay();
                    }
                    setShowStartDayConfirm(false);
                }}
                title="Start Day"
                buttonSuccessText="Start"
                buttenCancelText="Cancel"
            />
            <ConfirmationModel
                show={showCompleteDayConfirm}
                onClose={(confirmed) => {
                    if (confirmed) {
                        handleCompleteTask();
                    }
                    setShowCompleteDayConfirm(false);
                }}
                title="Complete Day"
                buttonSuccessText="Complete"
                buttenCancelText="Cancel"
            />
            <AddTimesheetModel
                show={showAddTimsheetEntry}
                onClose={(added) => {
                    if (added) {
                        handleGetTimesheet();
                    }
                    setShowAddTimsheetEntry(false);
                }}
            />
        </Container>
    );
};

export { MyTimesheet };
