import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Col, Row, Placeholder } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { customerService } from "../_services";
import { ICustomer } from "@/customTypings/Customer";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    customerId: string;
};

const EditCustomerModel: React.FC<Props> = ({ show, onClose, customerId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentCustomer, setCurrentCustomer] = useState<ICustomer>();
    const [loading, setLoading] = useState(true);

    const customerTypes = [
        { name: "Domestic", value: false },
        { name: "Business or organisation", value: true },
    ];

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            customerService.get(customerId).then((customer) => {
                setCurrentCustomer(customer);
                setLoading(false);
            });
        }
    }, [customerId, show]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {loading || !currentCustomer ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Contact</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Customer Type</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Customer Name</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Can Organise Schemes?</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="3rem" height="1rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={3} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={2} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                        name: currentCustomer.name ?? "",
                        isBusiness: currentCustomer.isBusiness.toString(),
                        isSchemeOrganisation: currentCustomer.isSchemeOrganisation,
                    }}
                    validationSchema={Yup.object().shape({
                        isBusiness: Yup.boolean(),
                        name: Yup.string().when("isBusiness", {
                            is: true,
                            then: Yup.string().required("Customer Name is required"),
                        }),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        setSubmitting(false);
                        const isBusiness = values.isBusiness === true.toString();
                        const updatedCustomer: ICustomer = {
                            id: currentCustomer.id,
                            name: !isBusiness || values.name === "" ? undefined : values.name,
                            isBusiness: isBusiness,
                            isSchemeOrganisation: !isBusiness ? false : values.isSchemeOrganisation,
                            customerNumber: currentCustomer.customerNumber,
                        };
                        customerService.edit(updatedCustomer).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("name", error.errors.Name);
                                    setFieldError("isBusiness", error.errors.isBusiness);
                                    setFieldError("isSchemeOrganisation", error.errors.isSchemeOrganisation);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Customer</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="customerTypeRadios">
                                    <Form.Label column sm={3}>
                                        Customer Type
                                    </Form.Label>
                                    <Col sm={9} className="pt-2">
                                        {customerTypes &&
                                            customerTypes.map((customerType) => (
                                                <label key={uuidv4()} className="d-block">
                                                    <Field type="radio" name="isBusiness" value={customerType.value.toString()} className="me-2" />
                                                    {customerType.name}
                                                </label>
                                            ))}
                                    </Col>
                                </Form.Group>
                                {values.isBusiness === true.toString() && (
                                    <>
                                        <Form.Group as={Row} className="mb-3" controlId="customerName">
                                            <Form.Label column sm={3}>
                                                Customer Name
                                            </Form.Label>
                                            <Col sm={9}>
                                                <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3" controlId="schemeOrg">
                                            <Form.Label column sm={3}>
                                                Can Organise Schemes?
                                            </Form.Label>
                                            <Col sm={9} className="pt-2">
                                                <Form.Check
                                                    type="switch"
                                                    name="isSchemeOrganisation"
                                                    className={"mt-1 form-check" + (errors.isSchemeOrganisation && touched.isSchemeOrganisation ? " is-invalid" : "")}
                                                    onChange={(e) => setFieldValue("isSchemeOrganisation", e.target.checked == null ? false : e.target.checked)}
                                                    defaultChecked={values.isSchemeOrganisation}
                                                />
                                                <ErrorMessage name="isSchemeOrganisation" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Updating customer..." /> : "Update customer"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditCustomerModel };
