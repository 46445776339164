import { faCheck, faFolderClosed, faFolderMinus, faFolderOpen, faFolderPlus, faFolderTree, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Form, Stack } from 'react-bootstrap';
import { IFolderTreeFolder } from './Declarations';

type Props = {
    folder: IFolderTreeFolder,
    onAdd: (folderKey: string) => void,
    onChange: (folderKey: string, name: string, isOpen: boolean, specialHandling: string) => void,
    onDelete: (folderKey: string) => void,
    children?: JSX.Element,
    isRoot: boolean
  };

const Folder: React.FC<Props> = ({ folder, onAdd, onChange, onDelete, children, isRoot }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isOpenCurrently, setIsOpenCurrently] = useState(folder.isOpen ?? true);
    const [folderName, setFolderName] = useState(folder.name);
    const [folderNameSave, setFolderNameSave] = useState(folder.name);
    const [specialHandling, setSpecialHandling] = useState(folder.specialHandling ?? "");

    useEffect(() => {
        onFolderChange()
    }, [isOpenCurrently, folderNameSave, specialHandling]);

    const onFolderChange = () => {
        onChange(folder.folderKey, folderNameSave, isOpenCurrently, specialHandling);
    };

    const onFolderReset = () => {
        setFolderName(folder.name);
    };

    return (
        <>
            <Stack direction="horizontal" gap={2}>
                <FontAwesomeIcon icon={isOpenCurrently ? faFolderOpen : faFolderClosed} className="onclick-hover" onClick={() => setIsOpenCurrently(!isOpenCurrently)}/>
                {!isEditingName ? 
                    <span style={{ userSelect: "none" }} className="onclick-hover" onClick={() => setIsEditing(!isEditing)}>{folderName} {folder.specialHandling === "proposals" ? "{#}" : ""}</span> :
                    <>
                        <Form onSubmit={(e) => {
                            e.preventDefault()
                            setIsEditingName(false)
                            setIsEditing(!isEditing)
                            setFolderNameSave(folderName)
                        }}>
                            <Form.Group controlId="formFolderName">
                                <Form.Control type="text" defaultValue={folder.name} onChange={e => setFolderName(e.target.value)} />
                            </Form.Group>
                        </Form>
                        <FontAwesomeIcon icon={faCheck} className="onclick-hover" onClick={() => {
                            setIsEditingName(!isEditingName)
                            setIsEditing(!isEditing)
                            setFolderNameSave(folderName)
                        }}/>
                        <FontAwesomeIcon icon={faXmark} className="onclick-hover" onClick={() => {
                            setIsEditingName(!isEditingName)
                            setIsEditing(!isEditing)
                            onFolderReset()
                        }}/>
                    </>
                }
                {isEditing && <>
                        {!isRoot && <FontAwesomeIcon icon={faPenToSquare} className="onclick-hover" onClick={() => {
                            setIsEditingName(!isEditingName)
                            setIsEditing(!isEditing)
                        }}/>}
                        <FontAwesomeIcon icon={faFolderPlus} className="onclick-hover" onClick={() => onAdd(folder.folderKey)}/>
                        {!(isRoot || folder.specialHandling === "proposals") && <FontAwesomeIcon icon={faFolderMinus} className="onclick-hover" onClick={() => onDelete(folder.folderKey)}/>}
                        {!(isRoot || folder.specialHandling === "proposals") && <FontAwesomeIcon icon={faFolderTree} className="onclick-hover" onClick={() => setSpecialHandling("proposals")}/>}
                </>}
            </Stack>
            {isOpenCurrently && children && <div className="ps-4" >{children}</div>}
        </>
    );
};

export default Folder