import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";

import {
    accountService,
    authenticationService,
    invitationService,
} from "../_services";

import { IInviteAcceptance } from "../customTypings/InviteAcceptance";
import {
    Col,
    Container,
    Row,
    Image,
    Stack,
    Spinner,
    Alert,
    Card,
    Form,
} from "react-bootstrap";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { IPasswordComplexityResult } from "../customTypings/PasswordComplexityResult";
import { PasswordStrength } from "../_components";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";

type AcceptInvitationPageProps = {
    id: string;
};

const AcceptInvitationPage: React.FC<AcceptInvitationPageProps> = ({ id }) => {
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [inviteAcceptance, setInviteAcceptance] =
        useState<IInviteAcceptance | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showLogout, setShowLogout] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [email, setEmail] = useState("");
    const [passwordComplexity, setPasswordComplexity] =
        useState<IPasswordComplexityResult>({
            isSuccess: false,
            score: 0,
            messages: [],
        });
    const currentUser = useAtom(userAtom);

    useEffect(() => {
        setIsLoading(true);
        setShowLogout(false);
        invitationService.exists(id).then(
            () => {
                if (currentUser) {
                    invitationService
                        .checkIsForEmail(id, currentUser.identity.email)
                        .then(
                            (response) => {
                                if (response.status !== "Failure") {
                                    navigate("/invitations", { replace: true });
                                } else {
                                    setErrorMessage(response.message);
                                }
                            },
                            (error) => {
                                if (error.status === 400) {
                                    setErrorMessage("Not a valid invitation.");
                                } else {
                                    setErrorMessage(error);
                                    setShowLogout(true);
                                }
                            }
                        );
                } else {
                    invitationService.getEmailIfAccountExists(id).then(
                        (response) => {
                            if (response.status === 0) {
                                setEmail(response.email);
                                setShowLogin(true);
                            } else {
                                invitationService.getById(id).then(
                                    (inviteAcceptance) => {
                                        setInviteAcceptance(inviteAcceptance);
                                    },
                                    (error) => {
                                        if (error.status === 400) {
                                            setErrorMessage(
                                                "Not a valid invitation."
                                            );
                                        } else {
                                            setErrorMessage(error);
                                        }
                                    }
                                );
                            }
                        },
                        (error) => {
                            if (error.status === 400) {
                                setErrorMessage("Not a valid invitation.");
                            } else {
                                setErrorMessage(error);
                            }
                            setErrorMessage(error);
                        }
                    );
                }
            },
            (error) => {
                if (error.status === 400) {
                    setErrorMessage("Not a valid invitation.");
                } else {
                    setErrorMessage(error);
                }
            }
        );
        setIsLoading(false);
    }, [id, currentUser]);

    function logout() {
        setIsLoading(true);
        authenticationService.logout();
    }

    function validatePassword(value: string) {
        if (value === "") {
            setPasswordComplexity({ isSuccess: false, score: 0, messages: [] });
        } else {
            accountService
                .passwordComplexityCheck(inviteAcceptance?.email ?? "", value)
                .then(
                    (result: IPasswordComplexityResult) => {
                        setPasswordComplexity(result);
                    },
                    (error) => {
                        setPasswordComplexity({
                            isSuccess: false,
                            score: 0,
                            messages: [error],
                        });
                    }
                );
        }
    }

    if (isLoading) {
        return (
            <div className="d-flex flex-nowrap vh-100 vw-100">
                <Spinner animation={"border"} className="mx-auto my-auto" />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Accept Invitation</title>
            </Helmet>
            <Container fluid>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 6, offset: 3 }}
                        sm={{ span: 6, offset: 3 }}
                    >
                        <Card className="shadow">
                            <Card.Body>
                                <Image
                                    src="/ReactCrmBoilerplateLogo.png"
                                    style={{ height: "5rem" }}
                                />
                                {!isLoading && (
                                    <>
                                        <h1 className="my-3">
                                            Accept Invitation
                                        </h1>
                                        <p>
                                            This page is for accepting an
                                            invitation to use the React Crm
                                            Boilerplate software. Please enter your
                                            name as you'd like it to be shown in
                                            the software and a password below to
                                            create a user account.
                                        </p>
                                        {inviteAcceptance && (
                                            <Formik
                                                initialValues={{
                                                    displayName: "",
                                                    password: "",
                                                    confirmPassword: "",
                                                }}
                                                onSubmit={(
                                                    values,
                                                    {
                                                        setStatus,
                                                        setSubmitting,
                                                        setFieldError,
                                                    }
                                                ) => {
                                                    setStatus();
                                                    inviteAcceptance.displayName =
                                                        values.displayName;
                                                    inviteAcceptance.password =
                                                        values.displayName;
                                                    inviteAcceptance.password =
                                                        values.password;
                                                    inviteAcceptance.confirmPassword =
                                                        values.confirmPassword;
                                                    invitationService
                                                        .accept(
                                                            inviteAcceptance
                                                        )
                                                        .then(
                                                            (response) => {
                                                                setSubmitting(
                                                                    false
                                                                );
                                                                if (
                                                                    response.status !==
                                                                    "Failure"
                                                                ) {
                                                                    authenticationService
                                                                        .login(
                                                                            inviteAcceptance.email,
                                                                            inviteAcceptance.password
                                                                        )
                                                                        .then(
                                                                            (
                                                                                response
                                                                            ) => {
                                                                                setSubmitting(
                                                                                    false
                                                                                );
                                                                                if (
                                                                                    response.status !==
                                                                                    "Failure"
                                                                                ) {
                                                                                    navigate(
                                                                                        "/",
                                                                                        {
                                                                                            replace:
                                                                                                true,
                                                                                        }
                                                                                    );
                                                                                } else {
                                                                                    setStatus(
                                                                                        response.message
                                                                                    );
                                                                                }
                                                                            },
                                                                            (
                                                                                error
                                                                            ) => {
                                                                                setSubmitting(
                                                                                    false
                                                                                );
                                                                                if (
                                                                                    error.status ===
                                                                                    400
                                                                                ) {
                                                                                    setStatus(
                                                                                        error.message
                                                                                    );
                                                                                } else {
                                                                                    setStatus(
                                                                                        error
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                } else {
                                                                    setStatus(
                                                                        response.message
                                                                    );
                                                                }
                                                            },
                                                            (error) => {
                                                                setSubmitting(
                                                                    false
                                                                );
                                                                if (
                                                                    error.status ===
                                                                    400
                                                                ) {
                                                                    setFieldError(
                                                                        "displayName",
                                                                        error
                                                                            .errors
                                                                            .displayName
                                                                    );
                                                                    setFieldError(
                                                                        "password",
                                                                        error
                                                                            .errors
                                                                            .Password
                                                                    );
                                                                    setFieldError(
                                                                        "confirmPassword",
                                                                        error
                                                                            .errors
                                                                            .ConfirmPassword
                                                                    );
                                                                    setStatus(
                                                                        error.title
                                                                    );
                                                                } else {
                                                                    setStatus(
                                                                        error
                                                                    );
                                                                }
                                                            }
                                                        );
                                                }}
                                            >
                                                {({
                                                    errors,
                                                    status,
                                                    touched,
                                                    isSubmitting,
                                                    handleSubmit,
                                                }) => (
                                                    <Form
                                                        noValidate
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <Stack gap={3}>
                                                            <div>
                                                                Email:{" "}
                                                                {
                                                                    inviteAcceptance.email
                                                                }
                                                            </div>
                                                            <Form.Group
                                                                as={Row}
                                                                className="mb-3"
                                                                controlId="displayName"
                                                            >
                                                                <Form.Label
                                                                    column
                                                                    sm={3}
                                                                >
                                                                    Display name
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Field
                                                                        name="displayName"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.displayName &&
                                                                            touched.displayName
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="displayName"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                            <Form.Group
                                                                as={Row}
                                                                className="mb-3"
                                                                controlId="password"
                                                            >
                                                                <Form.Label
                                                                    column
                                                                    sm={3}
                                                                >
                                                                    Password
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Field
                                                                        name="password"
                                                                        type="password"
                                                                        validate={
                                                                            validatePassword
                                                                        }
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.password &&
                                                                            touched.password
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="password"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                            {passwordComplexity && (
                                                                <PasswordStrength
                                                                    passwordComplexityResult={
                                                                        passwordComplexity
                                                                    }
                                                                />
                                                            )}
                                                            <Form.Group
                                                                as={Row}
                                                                className="mb-3"
                                                                controlId="confirmPassword"
                                                            >
                                                                <Form.Label
                                                                    column
                                                                    sm={3}
                                                                >
                                                                    Confirm
                                                                    Password
                                                                </Form.Label>
                                                                <Col sm={9}>
                                                                    <Field
                                                                        name="confirmPassword"
                                                                        type="password"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.confirmPassword &&
                                                                            touched.confirmPassword
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="confirmPassword"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                            <div className="form-group d-grid">
                                                                <Button
                                                                    variant="primary"
                                                                    disabled={
                                                                        isSubmitting
                                                                    }
                                                                    type="submit"
                                                                >
                                                                    {isSubmitting ? (
                                                                        <LoadingSpinner text="Creating user account..." />
                                                                    ) : (
                                                                        "Create User Account"
                                                                    )}
                                                                </Button>
                                                            </div>
                                                            {status && (
                                                                <div
                                                                    className={
                                                                        "alert alert-danger"
                                                                    }
                                                                >
                                                                    {status}
                                                                </div>
                                                            )}
                                                        </Stack>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                        {!inviteAcceptance && (
                                            <Alert variant="danger">
                                                {errorMessage}
                                            </Alert>
                                        )}
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal
                centered
                show={showLogout}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Sign out?</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => navigate("/", { replace: true })}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={logout}>
                        Sign Out
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showLogin} backdrop="static" keyboard={false}>
                <Formik
                    initialValues={{
                        username: email,
                        password: "",
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().required("Username is required"),
                        password: Yup.string().required("Password is required"),
                    })}
                    onSubmit={(
                        { username, password },
                        { setStatus, setSubmitting }
                    ) => {
                        setStatus();
                        authenticationService.login(username, password).then(
                            (user) => {
                                navigate("/invitations", { replace: true });
                            },
                            (error) => {
                                setSubmitting(false);
                                setStatus(error);
                            }
                        );
                    }}
                >
                    {({
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        handleSubmit,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header>
                                <Modal.Title>Login</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="username"
                                >
                                    <Form.Label column sm={3}>
                                        Username
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name="username"
                                            disabled={true}
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.username &&
                                                touched.username
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="username"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="password"
                                >
                                    <Form.Label column sm={3}>
                                        Password
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field
                                            name="password"
                                            type="password"
                                            className={
                                                "form-control" +
                                                (errors.password &&
                                                touched.password
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                        />
                                        <ErrorMessage
                                            name="password"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <div className={"alert alert-danger"}>
                                        {status}
                                    </div>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        {isSubmitting ? (
                                            <LoadingSpinner text="Loading..." />
                                        ) : (
                                            "Sign In"
                                        )}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export { AcceptInvitationPage };
