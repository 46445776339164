import { TaskType } from "../../_constants/TaskType";

export interface ITaskFlow {
    value: string;
    label: string;
    tasks: TaskType[];
    closeJob: boolean;
    scheduleCallback: boolean;
}

export function TaskFlowNextAction(taskType?: string) {
    switch (taskType) {
        case TaskType[TaskType.RaisePreSaleInvoice]:
            return [
                {
                    value: "0",
                    label: "Proceed to chase invoice",
                    tasks: [TaskType.ChasePreSaleInvoice],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.ChasePreSaleInvoice]:
            return [
                {
                    value: "0",
                    label: "Proceed to pre-sales survey",
                    tasks: [TaskType.PreSaleSurvey],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Proceed to design",
                    tasks: [TaskType.Design],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.PreSaleSurvey]:
            return [
                {
                    value: "0",
                    label: "Proceed to design",
                    tasks: [TaskType.Design],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.Design]:
            return [
                {
                    value: "0",
                    label: "Proceed to set installation date",
                    tasks: [TaskType.EquipmentReservation, TaskType.SetInstallationDate],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.DNO]:
            return [
                {
                    value: "0",
                    label: "Complete DNO",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "DNO permission unsuccsesful",
                    tasks: [TaskType.Redesign],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.ChaseDNO]:
            return [
                {
                    value: "0",
                    label: "New chase DNO task required",
                    tasks: [TaskType.ChaseDNO],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Complete chase DNO",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.Redesign]:
            return [
                {
                    value: "0",
                    label: "Complete redesign",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.EquipmentReservation]:
            return [
                {
                    value: "0",
                    label: "Complete equipment reservation",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.SetInstallationDate]:
            return [
                {
                    value: "0",
                    label: "Proceed to post-sale survey",
                    tasks: [TaskType.PostSaleSurvey, TaskType.ContractAgreement, TaskType.EquipmentReminder, TaskType.CheckSiteEquipmentOnSitePickedUp, TaskType.AcceptanceByCustomer],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Proceed without post-sale survey",
                    tasks: [TaskType.ContractAgreement, TaskType.EquipmentReminder, TaskType.CheckSiteEquipmentOnSitePickedUp, TaskType.AcceptanceByCustomer],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.ContractAgreement]:
            return [
                {
                    value: "0",
                    label: "Complete contract agreement",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.PostSaleSurvey]:
            return [
                {
                    value: "0",
                    label: "Complete post-sale survey",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.RaiseInvoice]:
            return [
                {
                    value: "0",
                    label: "Proceed to chase invoice",
                    tasks: [TaskType.ChaseInvoice],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.ChaseInvoice]:
            return [
                {
                    value: "0",
                    label: "Complete chase invoice",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.EquipmentReminder]:
            return [
                {
                    value: "0",
                    label: "Complete equipment reminder",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.CheckSiteEquipmentOnSitePickedUp]:
            return [
                {
                    value: "0",
                    label: "Complete check site equipment on site / picked up",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.Checklists]:
            return [
                {
                    value: "0",
                    label: "Complete checklist",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.AcceptanceByCustomer]:
            return [
                {
                    value: "0",
                    label: "Complete acceptance by customer",
                    tasks: [TaskType.MCSCertificate, TaskType.NapitCertificate, TaskType.PostInstallationDNO, TaskType.HandoverPackSent, TaskType.CompletePaperclip, TaskType.Review],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.MCSCertificate]:
            return [
                {
                    value: "0",
                    label: "Complete MCS certificate",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.NapitCertificate]:
            return [
                {
                    value: "0",
                    label: "Complete Napit certificate",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.PostInstallationDNO]:
            return [
                {
                    value: "0",
                    label: "Complete Post Installation DNO",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.ChasePostInstallationDNO]:
            return [
                {
                    value: "0",
                    label: "New chase post installation DNO task required",
                    tasks: [TaskType.ChasePostInstallationDNO],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Complete chase post installation DNO",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.HandoverPackSent]:
            return [
                {
                    value: "0",
                    label: "Complete Handover Pack Sent",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.CompletePaperclip]:
            return [
                {
                    value: "0",
                    label: "Complete Complete Paperclip",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        case TaskType[TaskType.Review]:
            return [
                {
                    value: "0",
                    label: "Complete Review",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
        default:
            return [
                {
                    value: "0",
                    label: "Proceed to pre-sales physical survey",
                    tasks: [TaskType.PreSaleSurvey],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "1",
                    label: "Proceed to raise invoice for pre-sales",
                    tasks: [TaskType.RaisePreSaleInvoice],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "2",
                    label: "Proceed to design",
                    tasks: [TaskType.Design],
                    closeJob: false,
                    scheduleCallback: false,
                },
                {
                    value: "3",
                    label: "Schedule another callback",
                    tasks: [],
                    closeJob: false,
                    scheduleCallback: true,
                },
                {
                    value: "4",
                    label: "Not proceeding",
                    tasks: [],
                    closeJob: true,
                    scheduleCallback: false,
                },
            ] as ITaskFlow[];
    }
}
