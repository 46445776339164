import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { IJobCommunication } from "@/customTypings/jobCommunication";
import { removeHTMLTagsString } from "../_helpers/utils";
import { useState } from "react";
import { JobCommunicationDetailsModel } from "../_components/JobCommunicationDetailsModel";

type Props = {
    jobId?: string;
};

const JobCommunicationListPage: React.FC<Props> = ({ jobId }) => {
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [editTaskId, setEditTaskId] = useState<string>();

    const handleOpenDetails = (id: string) => {
        setEditTaskId(id);
        setShowTaskDetails(true);
    };

    return (
        <>
            <PagedList
                columns={[
                    {
                        member: "subject",
                        renderAs: ColumnRender.text,
                        title: "Subject",
                        width: 400,
                    } as IColumn,
                    {
                        member: "body",
                        renderAs: ColumnRender.customRenderer,
                        title: "Body",
                        width: 500,
                        customRenderer: (item: IJobCommunication) => {
                            return (
                                <td>
                                    <p className="truncate-to-450">{removeHTMLTagsString(item.body)}</p>
                                </td>
                            );
                        },
                    } as IColumn,
                    {
                        member: "timestamp",
                        renderAs: ColumnRender.date,
                        title: "Date",
                        width: 100,
                    } as IColumn,
                    {
                        member: "from",
                        renderAs: ColumnRender.text,
                        title: "Sent by",
                        width: 300,
                    } as IColumn,
                ]}
                itemActions={[
                    {
                        text: "Detail",
                        onClick: (item: IJobCommunication) => {
                            handleOpenDetails(item.id);
                        },
                        primary: true,
                    } as IPagedListAction,
                ]}
                staticFilterValues={
                    {
                        jobId: jobId,
                    } as Record<string, any>
                }
                filters={[
                    {
                        title: "Subject",
                        member: "subject",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Body",
                        member: "body",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "From date",
                        member: "fromTimestamp",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "To date",
                        member: "toTimestamp",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "Sent by",
                        member: "from",
                        type: FilterType.text,
                    } as IFilter,
                ]}
                defaultSortBy="Timestamp"
                getUrl="api/jobcommunication/all"
                avatarUrl="api/avatar/get"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey="JobCommunicationListPage"
            />

            <JobCommunicationDetailsModel show={showTaskDetails} onClose={() => setShowTaskDetails(false)} jobCommunicationId={editTaskId} />
        </>
    );
};

export { JobCommunicationListPage };
