import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Col, Row } from "react-bootstrap";
import { taskService } from "../_services";
import { ITask } from "../customTypings/Task";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    taskId: string | undefined;
};

const TaskDetailsModel: React.FC<Props> = ({ show, onClose, taskId }) => {
    const [currentTask, setCurrentTask] = useState<ITask>();
    const [loading, setLoading] = useState(true);

    const handleClose = () => onClose(false);

    useEffect(() => {
        setLoading(true);
        if (taskId) {
            taskService.get(taskId).then((task) => {
                setCurrentTask(task);
                setLoading(false);
            });
        } else {
            setCurrentTask(undefined);
            setLoading(false);
        }
    }, [taskId]);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {loading || !currentTask ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <Skeleton width="4rem" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={3}>Creation Date</Col>
                            <Col md={9}>
                                <Skeleton width="10rem" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>Original Due Date</Col>
                            <Col md={9}>
                                <Skeleton width="10rem" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>Due Date</Col>
                            <Col md={9}>
                                <Skeleton width="10rem" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>Completed Date</Col>
                            <Col md={9}>
                                <Skeleton width="12rem" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>Notes</Col>
                            <Col md={9}>
                                <Skeleton width="20rem" height="3rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </>
            ) : (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{currentTask.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={3}>Creation Date</Col>
                            <Col md={9}>{dayjs(currentTask.creationDate).format("dddd DD MMMM YYYY")}</Col>
                        </Row>
                        <Row>
                            <Col md={3}>Original Due Date</Col>
                            <Col md={9}>{dayjs(currentTask.originalDueDate).format("dddd DD MMMM YYYY")}</Col>
                        </Row>
                        <Row>
                            <Col md={3}>Due Date</Col>
                            <Col md={9}>{dayjs(currentTask.dueDate).format("dddd DD MMMM YYYY")}</Col>
                        </Row>
                        <Row>
                            <Col md={3}>Completed Date</Col>
                            <Col md={9}>{dayjs(currentTask.completedDate).format("dddd DD MMMM YYYY")}</Col>
                        </Row>
                        <Row>
                            <Col md={3}>Notes</Col>
                            <Col md={9}>
                                <Form.Control as="textarea" name="notes" value={currentTask.notes ?? ""} disabled />
                            </Col>
                        </Row>
                    </Modal.Body>
                </>
            )}
            <Modal.Footer>
                <div className="form-group">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export { TaskDetailsModel };
