import { userAtom } from '../_atoms/userAtom';
import { useAtom } from '@dbeining/react-atom';
import { Navigate, useLocation } from 'react-router-dom';

import { IRole } from '../customTypings/role'

const PrivateRoute = ({ 
    children, 
    roles,
}: { 
    children: JSX.Element,
    roles?: Array<string>;
}) => {
    let location = useLocation();
    const currentUser = useAtom(userAtom)
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/signin" state={{ from: location }} />
    }

    // check if route is restricted by role
    if (roles && !currentUser.identity.roles.some((role: IRole) => (roles.indexOf(role.name) >= 0 ))) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/'}} />
    }

    // authorised so return component
    return children
}

export default PrivateRoute;