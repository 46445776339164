import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from "react-helmet";
import { TaskListPage } from "../Task/TaskListPage";
import { TaskType } from "../_constants/TaskType";
import { Tabs, Tab } from "react-bootstrap";

const AccountsPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Accounts</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Accounts</h1>
                    <Tabs defaultActiveKey="required" className="mb-3" mountOnEnter>
                        <Tab eventKey="required" title="Invoices Required">
                            <TaskListPage taskTypes={[TaskType.RaisePreSaleInvoice, TaskType.RaiseInvoice]} isCompleted={false} />
                        </Tab>
                        <Tab eventKey="due" title="Invoices Due">
                            <TaskListPage taskTypes={[TaskType.ChasePreSaleInvoice, TaskType.ChaseInvoice]} isCompleted={false} />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

export { AccountsPage };
