import { authHeader, handleResponse } from '../_helpers';
import { IUser } from '../customTypings/user'
import { clone } from '../_helpers/utils';
import { updateUserStateAtom } from '../_atoms/userAtom';

export const authenticationService = {
    login,
    logout,
    switchuser,
    endOtherSessions,
    updateCurrentUser,
    generateTwoFAQRCode,
    updateTwoFASecretKey,
    checkAuthorised
};

async function login(username: string, password: string, twoFACode?: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: twoFACode === undefined ? JSON.stringify({ username, password }) : JSON.stringify({ username, password, twoFACode })
    };

    return await fetch(`api/account/signinuser`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if(user.status === 'AuthenticationRequired' || user.status === 'Failure'){
                return user;
            }
            updateCurrentUser(user)
            return user;
        });
}

function logout() {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: authHeader()
    };
    
    return fetch(`api/account/signoutuser`, requestOptions)
    .then(handleResponse)
    .then(() => {
        updateCurrentUser(undefined)
    });
}

async function switchuser(id: string) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return await fetch(`api/account/switchuser?requestedId=${id}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            updateCurrentUser(user)
            return user;
        });
}

async function endOtherSessions() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return await fetch(`api/account/endothersessions`, requestOptions)
        .then(handleResponse)
        .then(user => {
            updateCurrentUser(user)
            return user;
        });
}

function updateCurrentUser(user: IUser | undefined) {
    if(user){
        const copy = clone<IUser>(user as IUser);
        updateUserStateAtom(copy)
    } else {
        updateUserStateAtom(undefined)
    }
}

async function generateTwoFAQRCode() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return await fetch(`api/account/generatetwofaqrcode`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function updateTwoFASecretKey(secretKey: string, twoFACode: string) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ secretKey, twoFACode })
    };

    return await fetch(`api/account/updatetwofactorsecretkey`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function checkAuthorised() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return await fetch(`api/account/checkauthorised`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return true;
        }, error => {
            return false;
        });
}