import { useState } from "react";
import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType, SortOrder } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AddNewUser, InvitationRoleEdit } from "../_components";
import { ConfirmationModel } from "../_components/ConfirmationModel";
import { invitationService } from "../_services";

const UserInvitationsPage = () => {
    const [showInviteUser, setShowInviteUser] = useState(false);

    const handleAddUser = () => {
        setShowInviteUser(true);
    };

    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState("");

    const [refreshNumber, setRefeshNumber] = useState(0);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const handleEdit = (updated: boolean) => {
        if (updated) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowEdit(false);
    };

    const handleDelete = () => {
        invitationService.remove(editId).then(
            (response) => {
                if (response.status !== "Failure") {
                    setRefeshNumber(refreshNumber + 1);
                } else {
                    //setStatus(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    //setStatus(error.title);
                } else {
                    //setStatus(error);
                }
            }
        );
    };

    return (
        <>
            <PagedList
                columns={[
                    {
                        member: "email",
                        renderAs: ColumnRender.text,
                        title: "Email",
                        width: 250,
                    } as IColumn,
                    {
                        member: "organisationName",
                        renderAs: ColumnRender.text,
                        title: "Organisation Name",
                        width: 250,
                    } as IColumn,
                    {
                        member: "expiryDate",
                        renderAs: ColumnRender.date,
                        title: "Expiry Date",
                        width: 100,
                    } as IColumn,
                    {
                        member: "isExpired",
                        renderAs: ColumnRender.deleted,
                        title: "Expired",
                        width: 100,
                    } as IColumn,
                ]}
                itemActions={[
                    {
                        icon: faPen,
                        text: "Edit",
                        onClick: (item) => {
                            setEditId(item.id);
                            setShowEdit(true);
                        },
                        availableMember: "isExpired",
                        availableFliped: true,
                    } as IPagedListAction,
                    {
                        icon: faTrash,
                        text: "Delete",
                        onClick: (item) => {
                            setEditId(item.id);
                            setShowDeleteConfirm(true);
                        },
                    } as IPagedListAction,
                ]}
                headActions={[
                    {
                        icon: faPlus,
                        text: "Add User",
                        onClick: handleAddUser,
                    } as IPagedListAction,
                ]}
                filters={[
                    {
                        title: "Organisation Name",
                        member: "organisationName",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Email",
                        member: "email",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Expired",
                        member: "isExpired",
                        type: FilterType.select,
                        options: { All: null, Expired: true, Active: false },
                        defaultValue: false,
                    } as IFilter,
                ]}
                defaultSortBy="expiryDate"
                defaultSortOrder={SortOrder.descending}
                getUrl="api/invitations/all"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey="UserInvitationsPage"
                refreshNumber={refreshNumber}
            />

            <AddNewUser
                show={showInviteUser}
                onClose={() => {
                    setShowInviteUser(false);
                }}
            />
            <InvitationRoleEdit show={showEdit} onClose={handleEdit} invitationId={editId} />
            <ConfirmationModel
                show={showDeleteConfirm}
                onClose={(confirmed) => {
                    if (confirmed) {
                        handleDelete();
                    }
                    setShowDeleteConfirm(false);
                }}
                title="Delete Invitation"
                buttonSuccessVariant="danger"
                buttonSuccessText="Delete"
                buttenCancelText="Cancel"
            />
        </>
    );
};

export { UserInvitationsPage };
