import { ITask } from '@/customTypings/Task';
import { taskService } from '../_services';
import { useState } from 'react';
import { Row, Col, Stack, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ITaskMetadata } from '../customTypings/TaskMetadata';
import { clone } from '../_helpers/utils';
import { TaskChecklist } from './TaskChecklist';

type Props = {
    task: ITask,
    onEdit: (updated: boolean) => void,
}

export interface ITaskMetadataChecklist {
    identityId: string,
    checklistDefinitionId: string
}
   
const TaskChecklists: React.FC<Props> =  ({task,onEdit}) =>  {
    var test: ITaskMetadataChecklist[] | undefined
    if(task.metadata){
        var data = task.metadata.find(item => item.name === "checklistTasksToCreate")?.value
        if(data){
            test = JSON.parse(data)
        }
    }
    const [currentChecklistTasksToCreateMetadata, setCurrentChecklistTasksToCreateMetadata] = useState<ITaskMetadataChecklist[] | undefined>(test);
    const [currentTaskMetadata, setCurrentTaskMetadata] = useState<ITaskMetadata[] | undefined>(task.metadata);

    const handleAddChecklist = () => {
        var updatedChecklistTasksToCreate: ITaskMetadataChecklist[]
        if(currentChecklistTasksToCreateMetadata){
            updatedChecklistTasksToCreate = clone(currentChecklistTasksToCreateMetadata)
            updatedChecklistTasksToCreate.push({ identityId:"",checklistDefinitionId:"" } as ITaskMetadataChecklist)
        } else {
            updatedChecklistTasksToCreate = [{ identityId:"",checklistDefinitionId:"" } as ITaskMetadataChecklist]
        }
        setCurrentChecklistTasksToCreateMetadata(updatedChecklistTasksToCreate)
        const updatedTaskMetadata = currentTaskMetadata?.map(item => {
            if(item.name === "checklistTasksToCreate"){
                item.value = JSON.stringify(updatedChecklistTasksToCreate)
            }
            return item
        })
        if(!(updatedTaskMetadata?.some(item => item.name === "checklistTasksToCreate"))){
            updatedTaskMetadata?.push({ id:undefined,name:"checklistTasksToCreate",value:JSON.stringify(updatedChecklistTasksToCreate) } as ITaskMetadata)
        }
        handleUpdate(updatedTaskMetadata)
    }

    const handleEditChecklist = (index: number, identityId?: string, checklistDefinitionId?: string) => {
        if(currentChecklistTasksToCreateMetadata){
            const updatedChecklistTasksToCreate = clone(currentChecklistTasksToCreateMetadata)
            updatedChecklistTasksToCreate[index] = { identityId: identityId ?? "", checklistDefinitionId: checklistDefinitionId ?? "" } as ITaskMetadataChecklist
            setCurrentChecklistTasksToCreateMetadata(updatedChecklistTasksToCreate)
            const updatedTaskMetadata = currentTaskMetadata?.map(item => {
                if(item.name === "checklistTasksToCreate"){
                    item.value = JSON.stringify(updatedChecklistTasksToCreate)
                }
                return item
            })
            handleUpdate(updatedTaskMetadata)
        }
    }

    const handleRemoveChecklist = (index: number) => {
        if(currentChecklistTasksToCreateMetadata){
            const updatedChecklistTasksToCreate = clone(currentChecklistTasksToCreateMetadata)
            updatedChecklistTasksToCreate.splice(index, 1)
            setCurrentChecklistTasksToCreateMetadata(updatedChecklistTasksToCreate)
            const updatedTaskMetadata = currentTaskMetadata?.map(item => {
                if(item.name === "checklistTasksToCreate"){
                    item.value = JSON.stringify(updatedChecklistTasksToCreate)
                }
                return item
            })
            handleUpdate(updatedTaskMetadata)
        }
    }

    const handleUpdate = (updatedTaskMetadata: ITaskMetadata[] | undefined) => {
        var updatedTask = clone(task)
        updatedTask.metadata = updatedTaskMetadata
        taskService.edit(updatedTask).then(response => {
            onEdit(true)
            setCurrentTaskMetadata(updatedTaskMetadata)
        })
    }

    return (
        <>
            <Stack direction='vertical'>
                <div className="bg-light px-2 py-1 mb-2">
                    <Row>
                        <Col xs={3}>Lead Engineer</Col>
                        <Col xs={9}>Checklist</Col>
                    </Row>
                </div>
                {currentChecklistTasksToCreateMetadata && currentChecklistTasksToCreateMetadata.map((checklistMetadata, index) =>
                    <TaskChecklist 
                        key={index}
                        index={index} 
                        identityId={checklistMetadata.identityId}
                        checklistDefinitionId={checklistMetadata.checklistDefinitionId}
                        onDelete={(index: number) => {handleRemoveChecklist(index)}}
                        onEdit={(index: number, identityId?: string, checklistDefinitionId?: string) => {handleEditChecklist(index, identityId,checklistDefinitionId)}}
                        />
                )}
                <div>
                  <Button variant='outline-primary' className="w-auto mb-1 text-start" onClick={() => handleAddChecklist()}>
                    <Stack direction='horizontal' gap={2}>
                      <FontAwesomeIcon icon={faPlus} size='2x' className='me-1' />
                      Add Checklist
                    </Stack>
                  </Button>
                </div>
            </Stack>
        </>
    )
}

export { TaskChecklists };