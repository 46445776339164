import { ITask } from '@/customTypings/Task';
import { taskService, userService } from '../_services';
import { useEffect, useState } from 'react';
import { Row, Col, Stack, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ITaskMetadata } from '../customTypings/TaskMetadata';
import { clone } from '../_helpers/utils';
import { TaskInvoice } from './TaskInvoice';
import { v4 as uuidv4 } from 'uuid';
import { IValueAndLabel } from '../customTypings/ValueAndLabel';

type Props = {
    task: ITask,
    onEdit: (updated: boolean) => void,
}

export interface ITaskMetadataInvoice {
    identityId: string,
    amount: number,
    dueDate: Date,
}
   
const TaskInvoices: React.FC<Props> =  ({task,onEdit}) =>  {
    var test: ITaskMetadataInvoice[] | undefined
    if(task.metadata){
        var data = task.metadata.find(item => item.name === "invoiceTasksToCreate")?.value
        if(data){
            test = JSON.parse(data)
        }
    }
    const [currentInvoiceTasksToCreateMetadata, setCurrentInvoiceTasksToCreateMetadata] = useState<ITaskMetadataInvoice[] | undefined>(test);
    const [currentTaskMetadata, setCurrentTaskMetadata] = useState<ITaskMetadata[] | undefined>(task.metadata);

    const [loadingLeadEngineerIdentity, setLoadingLeadEngineerIdentity] = useState(true);
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>()
    
    useEffect(() => {
        userService.getAllListItems().then(identities => {
            setAllIdentities(identities)
            setLoadingLeadEngineerIdentity(false)
        })
    }, []);

    const handleAddInvoice = () => {
        var updatedInvoiceTasksToCreate: ITaskMetadataInvoice[]
        if(currentInvoiceTasksToCreateMetadata){
            updatedInvoiceTasksToCreate = clone(currentInvoiceTasksToCreateMetadata)
            updatedInvoiceTasksToCreate.push({ identityId:"",amount:0,dueDate:new Date() } as ITaskMetadataInvoice)
        } else {
            updatedInvoiceTasksToCreate = [{ identityId:"",amount:0,dueDate:new Date() } as ITaskMetadataInvoice]
        }
        setCurrentInvoiceTasksToCreateMetadata(updatedInvoiceTasksToCreate)
        const updatedTaskMetadata = currentTaskMetadata?.map(item => {
            if(item.name === "invoiceTasksToCreate"){
                item.value = JSON.stringify(updatedInvoiceTasksToCreate)
            }
            return item
        })
        if(!(updatedTaskMetadata?.some(item => item.name === "invoiceTasksToCreate"))){
            updatedTaskMetadata?.push({ id:undefined,name:"invoiceTasksToCreate",value:JSON.stringify(updatedInvoiceTasksToCreate) } as ITaskMetadata)
        }
        handleUpdate(updatedTaskMetadata)
    }

    const handleEditInvoice = (index: number, identityId?: string, amount?: number, dueDate?: Date) => {
        if(currentInvoiceTasksToCreateMetadata){
            const updatedInvoiceTasksToCreate = clone(currentInvoiceTasksToCreateMetadata)
            updatedInvoiceTasksToCreate[index] = { identityId: identityId ?? "", amount: amount ?? 0, dueDate: dueDate ?? new Date() } as ITaskMetadataInvoice
            setCurrentInvoiceTasksToCreateMetadata(updatedInvoiceTasksToCreate)
            const updatedTaskMetadata = currentTaskMetadata?.map(item => {
                if(item.name === "invoiceTasksToCreate"){
                    item.value = JSON.stringify(updatedInvoiceTasksToCreate)
                }
                return item
            })
            handleUpdate(updatedTaskMetadata)
        }
    }

    const handleRemoveInvoice = (index: number) => {
        if(currentInvoiceTasksToCreateMetadata){
            const updatedInvoiceTasksToCreate = clone(currentInvoiceTasksToCreateMetadata)
            updatedInvoiceTasksToCreate.splice(index, 1)
            setCurrentInvoiceTasksToCreateMetadata(updatedInvoiceTasksToCreate)
            const updatedTaskMetadata = currentTaskMetadata?.map(item => {
                if(item.name === "invoiceTasksToCreate"){
                    item.value = JSON.stringify(updatedInvoiceTasksToCreate)
                }
                return item
            })
            handleUpdate(updatedTaskMetadata)
        }
    }

    const handleUpdate = (updatedTaskMetadata: ITaskMetadata[] | undefined) => {
        var updatedTask = clone(task)
        updatedTask.metadata = updatedTaskMetadata
        taskService.edit(updatedTask).then(response => {
            onEdit(true)
            setCurrentTaskMetadata(updatedTaskMetadata)
        })
    }

    return (
        <>
            <Stack direction='vertical'>
                <div className="bg-light px-2 py-1 mb-2">
                    <Row>
                        <Col xs={3}>Assignee</Col>
                        <Col xs={3}>Amount</Col>
                        <Col >Due Date</Col>
                    </Row>
                </div>
                {!loadingLeadEngineerIdentity && currentInvoiceTasksToCreateMetadata && currentInvoiceTasksToCreateMetadata.map((invoiceMetadata, index) =>
                    <TaskInvoice 
                        key={uuidv4()}
                        index={index} 
                        identityId={invoiceMetadata.identityId}
                        amount={invoiceMetadata.amount}
                        dueDate={invoiceMetadata.dueDate}
                        onDelete={(index: number) => {handleRemoveInvoice(index)}}
                        onEdit={(index: number, identityId?: string, amount?: number, dueDate?: Date) => {handleEditInvoice(index, identityId,amount,dueDate)}}
                        allIdentities={allIdentities}
                        />
                )}
                <div>
                  <Button variant='outline-primary' className="w-auto mb-1 text-start" onClick={() => handleAddInvoice()}>
                    <Stack direction='horizontal' gap={2}>
                      <FontAwesomeIcon icon={faPlus} size='2x' className='me-1' />
                      Add Invoice
                    </Stack>
                  </Button>
                </div>
            </Stack>
        </>
    )
}

export { TaskInvoices };