import { taskService } from "../_services";
import { useState } from "react";
import { Row, Col, Form, Stack } from "react-bootstrap";
import { ITaskChecklistItem } from "../customTypings/TaskChecklistItem";
import { clone } from "../_helpers/utils";

type Props = {
    checklist: ITaskChecklistItem[];
    onUpdate: (taskChecklistItems: ITaskChecklistItem[]) => void;
};

const TaskChecklistItem: React.FC<Props> = ({ checklist, onUpdate }) => {
    return (
        <Form noValidate>
            <Stack gap={3}>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3} htmlFor={"checklist"}>
                        Checklist
                    </Form.Label>
                    <Col sm={9}>
                        {checklist.map((item: ITaskChecklistItem) => (
                            <Item
                                taskChecklistItem={item}
                                onUpdate={(updatedItem) => {
                                    var updatedChecklist = checklist.map(
                                        (checklistItem) => {
                                            if (
                                                checklistItem.id ===
                                                updatedItem.id
                                            )
                                                return updatedItem;
                                            else return checklistItem;
                                        }
                                    );
                                    onUpdate(updatedChecklist);
                                }}
                            />
                        ))}
                    </Col>
                </Form.Group>
            </Stack>
        </Form>
        //<>
        //    <Form.Check
        //        type="checkbox"
        //        name={"checklistitem-" + taskChecklistItem.name}
        //        label={taskChecklistItem.name}
        //        defaultChecked={checked}
        //        className={"form-check"}
        //        onChange={(e) => {
        //            const checkedUpdate =
        //                e.target.checked == null ? false : e.target.checked;
        //
        //            const updatedTaskChecklistItem: ITaskChecklistItem =
        //                clone(taskChecklistItem);
        //            updatedTaskChecklistItem.isComplete = checkedUpdate;
        //            taskService
        //                .editChecklistItem(updatedTaskChecklistItem)
        //                .then(
        //                    () => {
        //                        setChecked(checkedUpdate);
        //                    },
        //                    (error) => {
        //                        setChecked(!checkedUpdate);
        //                        console.log("Error: ", error);
        //                        //setSubmitting(false);
        //                        //if (error.status === 400) {
        //                        //    setStatus(error.title);
        //                        //} else {
        //                        //    setStatus(error);
        //                        //}
        //                    }
        //                );
        //        }}
        //    />
        //</>
    );
};

type ItemProps = {
    taskChecklistItem: ITaskChecklistItem;
    onUpdate: (taskChecklistItem: ITaskChecklistItem) => void;
};

const Item: React.FC<ItemProps> = ({ taskChecklistItem, onUpdate }) => {
    const [checked, setChecked] = useState(taskChecklistItem.isComplete);

    return (
        <>
            <Form.Check
                type="checkbox"
                name={"checklistitem-" + taskChecklistItem.name}
                label={taskChecklistItem.name}
                defaultChecked={checked}
                className={"form-check"}
                onChange={(e) => {
                    const checkedUpdate =
                        e.target.checked == null ? false : e.target.checked;

                    const updatedTaskChecklistItem: ITaskChecklistItem =
                        clone(taskChecklistItem);
                    updatedTaskChecklistItem.isComplete = checkedUpdate;
                    taskService
                        .editChecklistItem(updatedTaskChecklistItem)
                        .then(
                            (response) => {
                                if (response.status !== "Failure") {
                                    setChecked(checkedUpdate);
                                    onUpdate(updatedTaskChecklistItem);
                                } else {
                                    setChecked(!checkedUpdate);
                                    console.log(response.message);
                                }
                            },
                            (error) => {
                                setChecked(!checkedUpdate);
                                console.log("Error: ", error);
                                //setSubmitting(false);
                                //if (error.status === 400) {
                                //    setStatus(error.title);
                                //} else {
                                //    setStatus(error);
                                //}
                            }
                        );
                }}
            />
        </>
    );
};

export { TaskChecklistItem };
