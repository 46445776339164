import React, { useEffect, useState } from 'react'
import { IFolderTreeFolder } from './Declarations';
import Folder from './Folder';

type Props = {
    folderKey: string,
    data: IFolderTreeFolder[]
    onChange: (data: IFolderTreeFolder[], key: string, specialHandling: string) => void;
    }

const FolderTreeRecursive: React.FC<Props> = ({ folderKey, data, onChange }) => {
    const [treeState, setTreeState] = useState(data);

    useEffect(() => {
        onChange(treeState, folderKey, "")
    }, [treeState]);

    const onTreeStateChange = (newState: IFolderTreeFolder[], childFolderKey: string, specialHandling: string) => {
        const updatedFolder: IFolderTreeFolder[] = data.map(folder => {
            if(folder.folderKey === childFolderKey)
            {
                return {...clearSpecialHandling(folder, specialHandling, false), children:newState }
            } else if(specialHandling !== ""){
                return clearSpecialHandling(folder, specialHandling, true)
            }
            return folder
        })

        onChange(updatedFolder, folderKey, specialHandling)
        //setTreeState(updatedFolder);
    };

    const onFolderAdd = (childFolderKey: string) => {
        const updatedFolder: IFolderTreeFolder[] = data.map(folder => {
            if(folder.folderKey === childFolderKey)
            {
                var updatedChildren = [] as IFolderTreeFolder[]
                var newFolder = {folderKey: "new", id: undefined, jobTypeId: folder.jobTypeId, name: "New Folder", specialHandling: ""} as IFolderTreeFolder

                if(folder.children)
                {
                    updatedChildren = folder.children

                    const currentNewFolderNumber = updatedChildren.filter((folder) => {
                        return folder.name.startsWith('New Folder')
                    }).length
                    if(currentNewFolderNumber > 0){
                        newFolder.folderKey += currentNewFolderNumber
                        newFolder.name += ' ('+currentNewFolderNumber+')'
                    }
                }

                updatedChildren.push(newFolder)
                const ret = {...folder, children: updatedChildren};
                return ret;
            }
            return folder
        })

        setTreeState(updatedFolder);
    }

    const onFolderChange = (childFolderKey: string, name: string, isOpen: boolean, specialHandling: string) => {
        const updatedFolder: IFolderTreeFolder[] = data.map(folder => {
            if(folder.folderKey === childFolderKey)
            {
                if(folder.children){
                    const childFolders: IFolderTreeFolder[] = folder.children.map(folder2 => {
                        return clearSpecialHandling(folder2, specialHandling, true)
                    })
                    return {...folder, name: name, isOpen: isOpen, specialHandling: specialHandling, children: childFolders}
                }
                return {...folder, name: name, isOpen: isOpen, specialHandling: specialHandling}
            } else if(specialHandling !== ""){
                return clearSpecialHandling(folder, specialHandling, true)
            }
            return folder
        })

        onChange(updatedFolder, folderKey, specialHandling)
        //setTreeState(updatedFolder);
    }

    const onFolderDelete = (childFolderKey: string) => {
        const updatedFolder: IFolderTreeFolder[] = data.filter(folder => {
            return folder.folderKey !== childFolderKey
        })

        setTreeState(updatedFolder);
    }

    const clearSpecialHandling = (folder: IFolderTreeFolder, specialHandlingToClear: string, includeChildren: boolean): IFolderTreeFolder => {
        if(folder.children && includeChildren){
            folder.children?.map(childFolder => {
                clearSpecialHandling(childFolder, specialHandlingToClear, includeChildren)
            })
        }
        if(specialHandlingToClear === folder.specialHandling){
            folder.specialHandling = ""
        }
        return folder
    };
    
    return (
        <>
            {data.map((item) =>
                <Folder key={item.folderKey} folder={item} onAdd={onFolderAdd} onChange={onFolderChange} onDelete={onFolderDelete} isRoot={false}>
                    {item.children && <FolderTreeRecursive folderKey={item.folderKey} data={item.children} onChange={onTreeStateChange} />}
                </Folder>
            )}
        </>
    )
}

export default FolderTreeRecursive