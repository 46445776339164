import { jobService } from '../_services';
import { useEffect, useState } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';
import { IJobCustomerDetails } from '@/customTypings/JobCustomerDetails';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

type Props = {
    jobId?: string,
}
   
const JobCustomerDetails: React.FC<Props> =  ({jobId}) =>  {
    const [jobCustomerDetails, setJobCustomerDetails] = useState<IJobCustomerDetails>();
    const [address, setAddress] = useState("");

    useEffect(() => {
        jobService.getCustomerDetails(jobId || "").then((customerDetails: IJobCustomerDetails) => {
            setJobCustomerDetails(customerDetails);
            if(customerDetails.address){
                var address = ""
                address += customerDetails.address.line1
                if(customerDetails.address.line2 && customerDetails.address.line2.trim())
                {
                    address += ", "+customerDetails.address.line2
                }
                if(customerDetails.address.line3 && customerDetails.address.line3.trim())
                {
                    address += ", "+customerDetails.address.line3
                }
                if(customerDetails.address.town && customerDetails.address.town.trim())
                {
                    address += ", "+customerDetails.address.town
                }
                if(customerDetails.address.county && customerDetails.address.county.trim())
                {
                    address += ", "+customerDetails.address.county
                }
                if(customerDetails.address.postcode && customerDetails.address.postcode.trim())
                {
                    address += ", "+customerDetails.address.postcode
                }
                setAddress(address)
            }
        });
    }, []);

    return (
        <>
            {jobCustomerDetails &&
            <>
                {jobCustomerDetails.propertyOwnerPreferredContact && <Row>
                    <Col md={3}>Customer</Col>
                    <Col md={9}>
                        <NavLink to={"/customers/" + jobCustomerDetails.propertyOwnerId}>{jobCustomerDetails.propertyOwnerPreferredContact.name}</NavLink>
                        <div>{jobCustomerDetails.propertyOwnerPreferredContact.emailAddress}</div>
                        <div>{jobCustomerDetails.propertyOwnerPreferredContact.phoneNumber}</div>
                    </Col>
                </Row>}
                {jobCustomerDetails.occupier && <Row>
                    <Col md={3}>Occupant</Col>
                    <Col md={9}>
                        <div>{jobCustomerDetails.occupier.name}</div>
                        <div>{jobCustomerDetails.occupier.emailAddress}</div>
                        <div>{jobCustomerDetails.occupier.phoneNumber}</div>
                    </Col>
                </Row>}
                {jobCustomerDetails.address && <Row>
                    <Col md={3}>Address</Col>
                    <Col md={9}>
                        {jobCustomerDetails.address.latitude && jobCustomerDetails.address.longitude ?
                            <a 
                            href={"https://maps.google.com/?q=" + jobCustomerDetails.address.latitude + "," + jobCustomerDetails.address.longitude} 
                            target="_blank"
                            >
                                {address}
                            </a> :
                            <div>{address}</div>
                        }
                    </Col>
                </Row>}
                {jobCustomerDetails.address && <Row>
                    <Col md={3}>What3Words</Col>
                    <Col md={9}>
                        <a 
                            href={"https://what3words.com/" + jobCustomerDetails.address.what3words} 
                            target="_blank"
                        >
                            ///{jobCustomerDetails.address.what3words}
                        </a>
                    </Col>
                </Row>}
            </>}
            {!jobCustomerDetails && 
            <>
                <Row>
                    <Col md={3}>Customer</Col>
                    <Col md={9}>
                        <Skeleton width="6rem" />
                        <Skeleton width="8rem" />
                        <Skeleton width="7rem" />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>Address</Col>
                    <Col md={9}>
                        <Skeleton width="10rem" />
                        <Skeleton width="12rem" />
                        <Skeleton width="9rem" />
                        <Skeleton width="8rem" />
                        <Skeleton width="7rem" />
                        <Skeleton width="4rem" />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>What3Words</Col>
                    <Col md={9}>
                        <Skeleton width="8rem" />
                    </Col>
                </Row>
            </>}   
        </>
    )
}

export { JobCustomerDetails };