import { IEmailTemplate } from "@/customTypings/EmailTemplate";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { emailTemplateService } from "../_services";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Container, Breadcrumb, Col, Row, Stack, Form, Alert, InputGroup, DropdownButton, Dropdown, Placeholder } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { IPlaceholder } from "@/customTypings/Placeholder";
import Skeleton from "react-loading-skeleton";
import ReactQuill from "react-quill";

export default function EmailTemplateEditPage() {
    let params = useParams();
    const [loading, setLoading] = useState(true);
    const [allPlaceholders, setAllPlaceholders] = useState<IPlaceholder>();
    const [currentEmailTemplate, setCurrentEmailTemplate] = useState<IEmailTemplate>();

    const [alertVariant, setAlertVariant] = useState("danger");

    const quillRef = useRef<any>();

    useEffect(() => {
        emailTemplateService.getPlaceholders().then((placeholders: IPlaceholder) => {
            setAllPlaceholders(placeholders);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        if (params.id) {
            emailTemplateService.get(params.id).then((emailTemplate: IEmailTemplate) => {
                setCurrentEmailTemplate(emailTemplate);
                setLoading(false);
            });
        }
    }, [params.id]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Container fluid>
            <Helmet>
                <title>{`Email template edit`}</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/EmailTemplates" }}>
                    Email Templates
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{currentEmailTemplate ? currentEmailTemplate?.name : <Skeleton width="6em" />}</Breadcrumb.Item>
            </Breadcrumb>
            <>
                <h1 className="my-3">New Email Template</h1>

                {loading ? (
                    <>
                        <Stack gap={3}>
                            <Row>
                                <Col md={labelSpan}>Name</Col>
                                <Col md={fieldSpan}>
                                    <Skeleton width="100%" height="2rem" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} lg={6} xl={labelSpan - 2}>
                                    Subject
                                </Col>
                                <Col xs={6} lg={6} xl={2}>
                                    <Stack direction="horizontal">
                                        <Placeholder.Button className="ms-auto" variant="outline-secondary" xs={8} />
                                    </Stack>
                                </Col>
                                <Col xs={12} lg={12} xl={fieldSpan}>
                                    <Skeleton width="100%" height="2rem" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} lg={6} xl={labelSpan - 2}>
                                    Notes
                                </Col>
                                <Col xs={6} lg={6} xl={2}>
                                    <Stack direction="horizontal">
                                        <Placeholder.Button className="ms-auto" variant="outline-secondary" xs={8} />
                                    </Stack>
                                </Col>
                                <Col xs={12} lg={12} xl={fieldSpan}>
                                    <Skeleton width="100%" height="4rem" />
                                </Col>
                            </Row>
                            <Stack gap={3} direction="horizontal">
                                <Placeholder.Button variant="success" xs={1} className="ms-auto" />
                            </Stack>
                        </Stack>
                    </>
                ) : (
                    <Formik
                        initialValues={{
                            name: currentEmailTemplate?.name ?? "",
                            subject: currentEmailTemplate?.subject ?? "",
                            subjectCursorLocation: 0,
                            body: currentEmailTemplate?.body ?? "",
                            bodyCursorLocation: 0,
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required("Field is required"),
                            subject: Yup.string().required("Field is required"),
                            body: Yup.string().required("Field is required"),
                        })}
                        onSubmit={(values, { setStatus, setSubmitting, setFieldError, resetForm }) => {
                            setStatus();
                            setSubmitting(false);
                            const newEmailTemplate: IEmailTemplate = {
                                id: currentEmailTemplate?.id,
                                name: values.name,
                                subject: values.subject,
                                body: values.body,
                                isDeleted: currentEmailTemplate?.isDeleted ?? false,
                            };
                            emailTemplateService.edit(newEmailTemplate).then(
                                (response) => {
                                    setSubmitting(false);
                                    if (response.status !== "Failure") {
                                        setAlertVariant("success");
                                        setStatus("Succsessfully updated");
                                    } else {
                                        setAlertVariant("danger");
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    setAlertVariant("danger");
                                    if (error.status === 400) {
                                        setStatus(error.title);
                                        setFieldError("name", error.errors.Name);
                                        setFieldError("type", error.errors.type);
                                    } else {
                                        setStatus(error);
                                    }
                                    setSubmitting(false);
                                }
                            );
                        }}
                    >
                        {({ values, errors, status, touched, isSubmitting, handleSubmit, handleChange, setStatus, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Stack gap={3}>
                                    <Form.Group as={Row} controlId="name">
                                        <Form.Label column xs={12} lg={12} xl={labelSpan}>
                                            Name
                                        </Form.Label>
                                        <Col xs={12} lg={12} xl={fieldSpan}>
                                            <Field
                                                name="name"
                                                type="text"
                                                className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                                validate={() => {
                                                    setStatus();
                                                }}
                                            />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="subject">
                                        <Form.Label column xs={6} lg={6} xl={labelSpan - 2}>
                                            Subject
                                        </Form.Label>
                                        <Col xs={6} lg={6} xl={2}>
                                            <Stack direction="horizontal">
                                                <DropdownButton className="ms-auto" variant="outline-secondary" title="Insert placeholder" id="input-group-dropdown-2" align="end">
                                                    {allPlaceholders &&
                                                        Object.entries(allPlaceholders).map(([key, value]) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setFieldValue(
                                                                            "subject",
                                                                            values.subject.slice(0, values.subjectCursorLocation) + key + values.subject.slice(values.subjectCursorLocation)
                                                                        )
                                                                    }
                                                                >
                                                                    {value}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                </DropdownButton>
                                            </Stack>
                                        </Col>
                                        <Col xs={12} lg={12} xl={fieldSpan}>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="subject"
                                                    value={values.subject ?? ""}
                                                    onChange={handleChange}
                                                    isInvalid={touched.subject && !!errors.subject}
                                                    onBlur={(e) => {
                                                        setFieldValue("subjectCursorLocation", e.target.selectionStart);
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="subject"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    render={(errorMessage) => <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="body">
                                        <Form.Label column xs={6} lg={6} xl={labelSpan - 2}>
                                            Body
                                        </Form.Label>
                                        <Col xs={6} lg={6} xl={2}>
                                            <Stack direction="horizontal">
                                                <DropdownButton className="ms-auto" variant="outline-secondary" title="Insert placeholder" id="input-group-dropdown-2" align="end">
                                                    {allPlaceholders &&
                                                        Object.entries(allPlaceholders).map(([key, value]) => {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        //setFieldValue("body", values.body.slice(0, values.bodyCursorLocation) + key + values.body.slice(values.bodyCursorLocation))
                                                                        quillRef.current.editor.insertText(values.bodyCursorLocation, key)
                                                                    }
                                                                >
                                                                    {value}
                                                                </Dropdown.Item>
                                                            );
                                                        })}
                                                </DropdownButton>
                                            </Stack>
                                        </Col>
                                        <Col xs={12} lg={12} xl={fieldSpan}>
                                            <InputGroup hasValidation>
                                                {/*<Form.Control
                                                    type="textarea"
                                                    as="textarea"
                                                    name="body"
                                                    value={values.body ?? ""}
                                                    onChange={handleChange}
                                                    isInvalid={touched.body && !!errors.body}
                                                    onBlur={(e) => {
                                                        setFieldValue("bodyCursorLocation", e.target.selectionStart);
                                                    }}
                                                    style={{ height: "250px" }}
                                                />*/}
                                                <ReactQuill
                                                    ref={quillRef}
                                                    value={values.body ?? ""}
                                                    onChange={(value, delta, source, editor) => {
                                                        setFieldValue("body", value);
                                                        setFieldValue("bodyCursorLocation", editor.getSelection(true).index ?? 0);
                                                    }}
                                                    onBlur={(previousSelection, source, editor) => {
                                                        //console.log(editor.getSelection(true).index);
                                                        setFieldValue("bodyCursorLocation", previousSelection?.index ?? 0);
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="body"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    render={(errorMessage) => <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3" onClose={() => setStatus()} dismissible>
                                            {status}
                                        </Alert>
                                    )}
                                    <Form.Group>
                                        <Stack direction="horizontal" gap={3}>
                                            <div className="ms-auto">
                                                <Button variant="success" disabled={isSubmitting} type="submit">
                                                    {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                                                </Button>
                                            </div>
                                        </Stack>
                                    </Form.Group>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                )}
            </>
        </Container>
    );
}
