import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Col, Row } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { jobService } from "../_services";
import dayjs from "dayjs";
import { IJob } from "../customTypings/Job";
import { clone } from "../_helpers/utils";
import Skeleton from "react-loading-skeleton";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    jobId: string;
};

const EditJobScheduleCallModel: React.FC<Props> = ({ show, onClose, jobId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentJob, setCurrentJob] = useState<IJob>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (jobId) {
            jobService.get(jobId).then((job) => setCurrentJob(job));
        }
    }, [jobId]);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {currentJob ? (
                <Formik
                    initialValues={{
                        scheduledCall: "", //dayjs(currentJob.scheduledCall).format("YYYY-MM-DD"),
                        pendingCallbackNotes: currentJob.pendingCallbackNotes,
                    }}
                    validationSchema={Yup.object().shape({
                        scheduledCall: Yup.string().required("Due date is required"),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        setSubmitting(false);
                        var updatedJob = clone(currentJob);
                        updatedJob.scheduledCall = new Date(values.scheduledCall);
                        updatedJob.pendingCallbackNotes = values.pendingCallbackNotes;
                        jobService.edit(updatedJob).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("scheduledCall", error.errors.ScheduledCall);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add callback</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="scheduledCall">
                                    <Form.Label column sm={3}>
                                        Due Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field name="scheduledCall" type="date" className={"form-control" + (errors.scheduledCall && touched.scheduledCall ? " is-invalid" : "")} />
                                        <ErrorMessage name="scheduledCall" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="pendingCallbackNotes">
                                    <Form.Label column sm={3}>
                                        Notes
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control
                                            as="textarea"
                                            name="pendingCallbackNotes"
                                            value={values.pendingCallbackNotes}
                                            onChange={handleChange}
                                            isValid={touched.pendingCallbackNotes && !errors.pendingCallbackNotes}
                                        />
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Adding callback..." /> : "Add callback"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            ) : (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Add callback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={3}>Due Date</Col>
                            <Col md={9}>
                                <Skeleton width="10rem" />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>Notes</Col>
                            <Col md={9}>
                                <Skeleton width="20rem" height="3rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group">
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </div>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
};

export { EditJobScheduleCallModel };
