import { useState } from "react";
import { Breadcrumb, Container, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import JobDNOFolderSettings from "./JobDNOFolderSettings";
import JobProposalFolderSettings from "./JobProposalFolderSettings";
import JobTypeFolderSettings from "./JobTypeFolderSettings";

export default function JobFolderSettingsPage() {
    const [key, setKey] = useState("type");
    return (
        <Container fluid>
            <Helmet>
                <title>Job Folders</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Job Folders</Breadcrumb.Item>
            </Breadcrumb>
            <h1>Job Folders</h1>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k ?? "home")} className="mb-3" mountOnEnter>
                <Tab eventKey="type" title="Job Types">
                    <JobTypeFolderSettings />
                </Tab>
                <Tab eventKey="proposal" title="Proposals">
                    <JobProposalFolderSettings />
                </Tab>
                {/*<Tab eventKey="dno" title="DNO's">
                    <JobDNOFolderSettings />
    </Tab>*/}
            </Tabs>
        </Container>
    );
}
