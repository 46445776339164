import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from "react-helmet";
import { TaskListPage } from "../Task/TaskListPage";
import { TaskType } from "../_constants/TaskType";

const DesignPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Designs</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Designs</h1>
                    <TaskListPage
                        taskTypes={[TaskType.Design, TaskType.Redesign]}
                        isCompleted={false}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export { DesignPage };
