import { IFolderDefinitionFolder } from "@/customTypings/FolderDefinitionFolder";
import React, { useEffect, useState } from "react";
import FolderDefinitionTreeFolder from "./FolderDefinitionTreeFolder";
import FolderDefinitionTreeRecursive from "./FolderDefinitionTreeRecursive";
import { v4 as uuidv4 } from "uuid";

type Props = {
    data: IFolderDefinitionFolder;
    onChange: (data: IFolderDefinitionFolder) => void;
};

const FolderDefinitionTree: React.FC<Props> = ({ data, onChange }) => {
    const [treeState, setTreeState] = useState(data);

    useEffect(() => {
        onChange(treeState);
    }, [treeState]);

    const onTreeStateChange = (newState: IFolderDefinitionFolder[]) => {
        const updatedFolder: IFolderDefinitionFolder = { ...data, children: newState };

        setTreeState(updatedFolder);
    };

    const onFolderAdd = (folderKey: string) => {
        var updatedChildren = [] as IFolderDefinitionFolder[];
        var newFolder = { folderKey: "new", id: undefined, type: data.type, name: "New Folder" } as IFolderDefinitionFolder;
        if (data.children) {
            updatedChildren = data.children;

            var filterd = updatedChildren.map((folder) => {
                if (folder.name.startsWith("New Folder")) {
                    return parseInt(folder.name.replace(/^\D+|\D+$/g, ""), 10);
                } else {
                    return 0;
                }
            });
            const currentNewFolderNumber = updatedChildren.filter((folder) => {
                return folder.name.startsWith("New Folder");
            }).length;
            newFolder.folderKey = uuidv4();
            newFolder.name += " (" + currentNewFolderNumber + ")";
        }
        updatedChildren.push(newFolder);
        const updatedFolder: IFolderDefinitionFolder = { ...data, children: updatedChildren };

        setTreeState(updatedFolder);
    };

    const onFolderChange = (folderKey: string, name: string, isOpen: boolean) => {
        const updatedFolder: IFolderDefinitionFolder = { ...data, name: name, isOpen: isOpen };

        setTreeState(updatedFolder);
    };

    const onFolderDelete = (folderKey: string) => {
        alert("Unable to remove root folder");
    };

    return (
        <>
            <FolderDefinitionTreeFolder key={data.folderKey} folder={data} onAdd={onFolderAdd} onChange={onFolderChange} onDelete={onFolderDelete} isRoot={true}>
                {data.children && <FolderDefinitionTreeRecursive folderKey={data.folderKey} data={data.children} onChange={onTreeStateChange} />}
            </FolderDefinitionTreeFolder>
        </>
    );
};

export default FolderDefinitionTree;
