import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";

import { rolesService, userService } from "../_services";
import { Alert, Button, Modal, Form, Col, Row } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { IRole } from "../customTypings/role";
import { IIdentity } from "@/customTypings/Identity";
import { Avatar } from "../_components/Avatar";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
    user: IIdentity;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const UserRoleEdit: React.FC<Props> = ({
    show,
    setShow,
    user,
    setUserInteraction,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allRoles, setAllRoles] = useState<IRole[]>();

    const handleClose = () => setShow(false);

    useEffect(() => {
        rolesService.getAllForGroup(0).then((roles) => setAllRoles(roles));
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {allRoles && (
                <Formik
                    initialValues={{
                        roles: user.roles.map((role) => role.id),
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        userService.updateRoles(user.id, values.roles).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    if (setUserInteraction !== undefined) {
                                        setUserInteraction(true);
                                    }
                                    handleClose();
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ status, isSubmitting, handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Roles</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5 className="mb-3">
                                    <Avatar identity={user} />
                                </h5>

                                <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="roleCheckboxs"
                                >
                                    <Form.Label column sm={3}>
                                        Roles
                                    </Form.Label>
                                    <Col sm={9} className="pt-2">
                                        {allRoles &&
                                            allRoles.map((role) => (
                                                <label
                                                    key={role.id}
                                                    className="d-block"
                                                >
                                                    <Field
                                                        type="checkbox"
                                                        name="roles"
                                                        value={role.id}
                                                        className="me-2"
                                                    />
                                                    {role.name}
                                                </label>
                                            ))}
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert
                                        variant={alertVariant}
                                        className="mt-3"
                                    >
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button
                                        variant="primary"
                                        disabled={isSubmitting}
                                        type="submit"
                                        className="me-2"
                                    >
                                        {isSubmitting ? (
                                            <LoadingSpinner text="Updating..." />
                                        ) : (
                                            "Update"
                                        )}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { UserRoleEdit };
