import { createContext, useContext, useEffect, useState } from "react";

export const ViewportContext = createContext({ width: 0, height: 0 });

export const ViewportProvider = ({ children }: { children: JSX.Element }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};

export const useViewport = () => {
    const { width, height } = useContext(ViewportContext);
    return { width, height };
};
