import { TaskListPage } from "../Task/TaskListPage";

type Props = {
    jobId?: string,
    isCompleted?: boolean,
}
   
const JobTasks: React.FC<Props> =  ({jobId, isCompleted}) =>  {
    return (
        <TaskListPage 
            jobId={jobId}
            isCompleted={isCompleted}
        />
    )
}

export { JobTasks };