import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { taskService } from "../../_services";
import { ITask } from "../../customTypings/Task";
import { ITaskList } from "../../customTypings/TaskList";
import { TaskType } from "../../_constants/TaskType";
import { CreatePreSaleSurvey } from "./CreatePreSaleSurvey";
import { CreatePreSaleInvoice } from "./CreatePreSaleInvoice";
import { CreateDesign } from "./CreateDesign";
import { EventCalendar } from "../EventCalendar/EventCalendar";
import { CreateDNO } from "./CreateDNO";
import { CreateChaseDNO } from "./CreateChaseDNO";
import { CreateSetInstallationDate } from "./CreateSetInstallationDate";
import { CreatePostSaleSurvey } from "./CreatePostSaleSurvey";
import { CreateEquipmentReservation } from "./CreateEquipmentReservation";
import { CreateAcceptanceByCustomer } from "./CreateAcceptanceByCustomer";
import { CreateChaseInvoice } from "./CreateChaseInvoice";
import { CreateChasePreSaleInvoice } from "./CreateChasePreSaleIncoive";
import { CreateContractAgreement } from "./CreateContractAgreement";
import { CreateEquipmentReminder } from "./CreateEquipmentReminder";
import { CreateCheckSiteEquipmentOnSitePickedUp } from "./CreateCheckSiteEquipmentOnSitePickedUp";
import { CreateMCSCertificate } from "./CreateMCSCertificate";
import { JobStatus } from "../../_constants/JobStatus";
import { CreateNapitCertificate } from "./CreateNapitCertificate";
import { CreatePostInstallationDNO } from "./CreatePostInstallationDNO";
import { CreateRedesign } from "./CreateRedesign";
import { CreateCompletePaperclip } from "./CreateCompletePaperclip";
import { CreateHandoverPackSent } from "./CreateHandoverPackSent";
import { CreateReview } from "./CreateReview";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    tasktypes?: TaskType[];
    assignedId?: string;
    jobId?: string;
    sorceTask?: ITask;
    jobStatus?: JobStatus;
};

type KeyTasks = {
    key: string;
    tasks: ITask[];
};

const CreateTask: React.FC<Props> = ({ show, onClose, tasktypes, assignedId, jobId, sorceTask, jobStatus }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [tasksFixed, setTasksFixed] = useState<KeyTasks[]>([]);

    /*useEffect(() => {
        console.log("tasksFixed: ", tasksFixed);
    }, [tasksFixed]);*/

    const handleClose = () => onClose(false);

    const handleEditTasks = (listKey: string, updatedTasks: ITask[]) => {
        setTasksFixed((tf) => {
            var updatedTaskList = tf.map((item) => {
                if (item.key === listKey) {
                    return {
                        ...item,
                        tasks: updatedTasks,
                    };
                } else {
                    return item;
                }
            });
            if (!updatedTaskList.some((e) => e.key === listKey)) {
                const newTasks = {
                    key: listKey,
                    tasks: updatedTasks,
                } as KeyTasks;
                updatedTaskList.push(newTasks);
            }
            return updatedTaskList;
        });
    };

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose} size="lg">
            <Formik
                initialValues={{
                    tasks: [] as KeyTasks[],
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    var newTasks = [] as ITask[];
                    tasksFixed.map((taskList) => {
                        newTasks = newTasks.concat(taskList.tasks);
                    });
                    const newTaskList: ITaskList = {
                        jobId: jobId,
                        jobStatus: jobStatus,
                        currentTaskId: sorceTask?.id,
                        tasks: newTasks,
                    };
                    taskService.addMultiple(newTaskList).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("name", error.errors.Name);
                                setFieldError("job", error.errors.Job);
                                setFieldError("dueDate", error.errors.DueDate);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Assign Tasks</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {tasktypes && (
                                <>
                                    {tasktypes.some((v) => v === TaskType.PreSaleSurvey) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="preSaleSurveyTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.PreSaleSurvey)}</h4>
                                                <CreatePreSaleSurvey
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.PreSaleSurvey.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>

                                            <EventCalendar newEventAllowed={true} setView="timeGridWeek" isTask={true} jobId={jobId} />
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.Design) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="designTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.Design)}</h4>
                                                <CreateDesign
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.Design.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.RaisePreSaleInvoice) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="preSaleInvoiceTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.RaisePreSaleInvoice)}</h4>
                                                <CreatePreSaleInvoice
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.RaisePreSaleInvoice.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.ChasePreSaleInvoice) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="chasePreSaleInvoiceTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.ChasePreSaleInvoice)}</h4>
                                                <CreateChasePreSaleInvoice
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.ChasePreSaleInvoice.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                    amount={parseInt(sorceTask?.metadata?.find((item) => item.name === "Amount")?.value ?? "0", 10)}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.DNO) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="dNOTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.DNO)}</h4>
                                                <CreateDNO
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.DNO.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.ChaseDNO) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="chaseDNOTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.ChaseDNO)}</h4>
                                                <CreateChaseDNO
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.ChaseDNO.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.Redesign) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="redesignTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.Redesign)}</h4>
                                                <CreateRedesign
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.Redesign.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.EquipmentReservation) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="equipmentReservationTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.EquipmentReservation)}</h4>
                                                <CreateEquipmentReservation
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.EquipmentReservation.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.SetInstallationDate) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.SetInstallationDate)}</h4>
                                                <CreateSetInstallationDate
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.SetInstallationDate.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.ContractAgreement) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.ContractAgreement)}</h4>
                                                <CreateContractAgreement
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.ContractAgreement.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.PostSaleSurvey) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.PostSaleSurvey)}</h4>
                                                <CreatePostSaleSurvey
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.PostSaleSurvey.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.RaiseInvoice) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.RaiseInvoice)}</h4>
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.ChaseInvoice) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="chaseInvoiceTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.ChaseInvoice)}</h4>
                                                <CreateChaseInvoice
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.ChaseInvoice.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                    amount={parseInt(sorceTask?.metadata?.find((item) => item.name === "Amount")?.value ?? "0", 10)}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.EquipmentReminder) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="equipmentReminderTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.EquipmentReminder)}</h4>
                                                <CreateEquipmentReminder
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.EquipmentReminder.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.CheckSiteEquipmentOnSitePickedUp) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.CheckSiteEquipmentOnSitePickedUp)}</h4>
                                                <CreateCheckSiteEquipmentOnSitePickedUp
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.CheckSiteEquipmentOnSitePickedUp.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.Checklists) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.Checklists)}</h4>
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.AcceptanceByCustomer) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="installationDateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.AcceptanceByCustomer)}</h4>
                                                <CreateAcceptanceByCustomer
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.AcceptanceByCustomer.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.MCSCertificate) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="mCSCertificateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.MCSCertificate)}</h4>
                                                <CreateMCSCertificate
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.MCSCertificate.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.NapitCertificate) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="napitCertificateTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.NapitCertificate)}</h4>
                                                <CreateNapitCertificate
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.NapitCertificate.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.PostInstallationDNO) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="postInstallationDNOTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.PostInstallationDNO)}</h4>
                                                <CreatePostInstallationDNO
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.PostInstallationDNO.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.HandoverPackSent) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="handoverPackSentTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.HandoverPackSent)}</h4>
                                                <CreateHandoverPackSent
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.HandoverPackSent.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.CompletePaperclip) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="completePaperclipTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.CompletePaperclip)}</h4>
                                                <CreateCompletePaperclip
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.CompletePaperclip.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                    {tasktypes.some((v) => v === TaskType.Review) && (
                                        <>
                                            <Form.Group className="mb-3" controlId="reviewTasksField">
                                                <h4>Assign {TaskType.discription(TaskType.Review)}</h4>
                                                <CreateReview
                                                    onUpdate={(tasks: ITask[]) => {
                                                        handleEditTasks(TaskType.Review.toString(), tasks);
                                                    }}
                                                    assignedId={assignedId}
                                                    jobId={jobId}
                                                />
                                            </Form.Group>
                                            <hr />
                                        </>
                                    )}
                                </>
                            )}
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating task..." /> : "Create task"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { CreateTask };
