import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { userService } from "../_services";
import { Alert, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { Avatar } from "./Avatar";
import { IIdentity } from "@/customTypings/Identity";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
    user: IIdentity;
    color: string;
    setUserInteraction?: React.Dispatch<React.SetStateAction<any>>;
};

const UserColorEdit: React.FC<Props> = ({
    show,
    setShow,
    user,
    color,
    setUserInteraction,
}) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => setShow(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    color: color ?? "",
                }}
                validationSchema={Yup.object().shape({
                    color: Yup.string().required("Colour is required"),
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    userService.updateColor(user.id, values.color).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                if (setUserInteraction !== undefined) {
                                    setUserInteraction(true);
                                }
                                handleClose();
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, touched, status, isSubmitting, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Colour</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className="mb-3">
                                <Avatar identity={user} />
                            </h5>

                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="color"
                            >
                                <Form.Label column sm={3}>
                                    Colour
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="color"
                                        type="color"
                                        autoFocus
                                        className={
                                            "form-control" +
                                            (errors.color && touched.color
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="color"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Updating..." />
                                    ) : (
                                        "Update"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { UserColorEdit };
