import React from 'react';

import { ListGroup, ProgressBar } from 'react-bootstrap';
import { IPasswordComplexityResult } from '@/customTypings/PasswordComplexityResult';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
    passwordComplexityResult: IPasswordComplexityResult
  }

enum PasswordStrenghtEnum {
  worst,
  bad,
  weak,
  good,
  strong
}

const PasswordStrength: React.FC<Props> = ({passwordComplexityResult}) => {
  return (
    <>
      <h4>Password strength: {PasswordStrenghtEnum[passwordComplexityResult.score]}</h4>
      <ProgressBar variant={passwordComplexityResult.isSuccess ? 'primary' : passwordComplexityResult.score >= 2 ? 'warning' : 'danger'} now={20+(passwordComplexityResult.score*20)} />
      {!passwordComplexityResult.isSuccess && passwordComplexityResult.messages.length > 0 &&
        <ListGroup>
          {passwordComplexityResult.messages.map(message =>
            <ListGroup.Item className="d-flex justify-content-between align-items-start" key={message} variant='warning'>
              {message}
            </ListGroup.Item>
          )}
        </ListGroup>
      }
      {passwordComplexityResult.isSuccess && passwordComplexityResult.messages.length > 0 &&
        <p>
          <FontAwesomeIcon icon={faCheck} /> Great! Now type the same password in the field below.
        </p>
      }
    </>
  );
}

export { PasswordStrength };