import { number } from "yup";

export function clone<Type,>(source: Type): Type {
    return JSON.parse(JSON.stringify(source)) as Type;
};

export function foreColorForBack (hex: string) {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
    */
    var threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */



   // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });



   var hRed = hexToR(hex);
    var hGreen = hexToG(hex);
    var hBlue = hexToB(hex);



   function hexToR(h: string) { return parseInt(cutHex(h).substring(0, 2), 16); }
    function hexToG(h: string) { return parseInt(cutHex(h).substring(2, 4), 16); }
    function hexToB(h: string) { return parseInt(cutHex(h).substring(4, 6), 16); }
    function cutHex(h: string) { return (h.charAt(0) === "#") ? h.substring(1, 7) : h; }



   var cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;



   return cBrightness > threshold
        ? "#000000"
        : "#ffffff";
}

export function currencyFormat (value: number) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
}

export function truncateString (value: string, length: number) {
    return value.length > length ? `${value.substring(0, length)}...` : value
}

export function removeHTMLTagsString (value: string) {
    return value.replace(/<[^>]*>?/gm, " ")
}

export const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;