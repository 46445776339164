import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from 'react-helmet';
import { EventCalendar } from "../_components/EventCalendar/EventCalendar";
import { useEffect, useState } from "react";
import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import { userService } from "../_services";
import Select from 'react-select'
import { ReactSelectBootstrapStyle } from '../_styles/ReactSelectBootstrapStyle'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

const CalendarPage = () => {
    const [filters, setFilters] = useState<string[]>([])
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>()

    useEffect(() => {
        userService.getAllListItems().then(identities => setAllIdentities(identities))
    }, []);

    return (
        <Container fluid>
            <Helmet>
                <title>Company Calendar</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Calendar</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                   <Select
                        isMulti
                        closeMenuOnSelect={false}
                        placeholder={<><FontAwesomeIcon icon={faUsers} /> Select users...</>}
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect z-2 mb-2"
                        onChange={value=>{
                            var identityIds: string[] = []
                            value.map(item => {
                                identityIds.push(item.value)
                            })
                            setFilters(identityIds)
                        }}
                        isClearable
                        options={allIdentities}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EventCalendar identityIds={filters} newEventAllowed={true} updateEventAllowed={true} />
                </Col>
            </Row>
        </Container>
    )
}

export { CalendarPage }; 