import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { documentService, emailTemplateService, proposalService } from "../../_services";
import { ReactSelectBootstrapStyle } from "../../_styles/ReactSelectBootstrapStyle";
import Select from "react-select";
import { IEmailTemplate } from "@/customTypings/EmailTemplate";
import { IProposal } from "@/customTypings/Proposal";
import { IDocument } from "@/customTypings/Document";
import ReactQuill from "react-quill";

type Props = {
    show: boolean;
    onClose: (sent: boolean) => void;
    proposal: IProposal;
};

const SendProposalModal: React.FC<Props> = ({ show, onClose, proposal }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate>();
    const [allEmailTemplates, setAllEmailTemplates] = useState<IEmailTemplate[]>();
    const [allAvailableDocuments, setAllAvailableDocuments] = useState<IDocument[]>();
    const [selectedDocuments, setSelectedDocuments] = useState<IDocument[]>();
    const [loading, setLoading] = useState(true);

    const handleClose = (sent: boolean) => {
        onClose(sent);
        setEmailTemplate(undefined);
        setSelectedDocuments(undefined);
    };

    useEffect(() => {
        if (show && proposal.id && proposal.folderId) {
            setLoading(true);
            Promise.all([emailTemplateService.getAllActive(proposal.id), documentService.getDocumentsInFolderRecursive(proposal.folderId)]).then((response) => {
                setAllEmailTemplates(response[0]);
                setAllAvailableDocuments(response[1]);
                setLoading(false);
            });
        } else {
            setAllEmailTemplates(undefined);
            setAllAvailableDocuments(undefined);
        }
    }, [show]);

    return allEmailTemplates ? (
        <Modal centered show={show} keyboard={false} onHide={() => handleClose(false)} size="lg">
            <Formik
                initialValues={{
                    cost: 0,
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    proposalService.sendEmail(proposal.id ?? "", emailTemplate?.id ?? "", selectedDocuments?.map((document) => document.id) ?? []).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status === "Success") {
                                setAlertVariant("success");
                                setStatus("Invitation sent successfully.");
                                setTimeout(() => {
                                    handleClose(true);
                                }, 3000);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                                setSubmitting(false);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue, resetForm }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Send Proposal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="templateEmailInput">
                                <Form.Label column sm={3}>
                                    Template
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect z-2"
                                        onChange={(value) => {
                                            setEmailTemplate(value == null ? undefined : (value as IEmailTemplate));
                                        }}
                                        isClearable
                                        options={allEmailTemplates}
                                        getOptionLabel={(option: IEmailTemplate) => `${option.name}`}
                                        getOptionValue={(option: IEmailTemplate) => `${option["id"]}`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>
                                    To
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control value={emailTemplate?.to ?? ""} readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>
                                    Subject
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control readOnly value={emailTemplate?.subject ?? ""} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>
                                    Attachments
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect z-2"
                                        isMulti
                                        onChange={(value) => {
                                            setSelectedDocuments(value == null ? undefined : (value as IDocument[]));
                                        }}
                                        isClearable
                                        options={allAvailableDocuments}
                                        getOptionLabel={(option: IDocument) => `${option.fileName}`}
                                        getOptionValue={(option: IDocument) => `${option["id"]}`}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={3}>
                                    body
                                </Form.Label>
                                <Col sm={9}>
                                    <ReactQuill value={emailTemplate?.body ?? ""} readOnly modules={{ toolbar: false }} />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting || !emailTemplate} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Sending proposal..." /> : "Send proposal"}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        handleClose(false);
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    ) : (
        <></>
    );
};

export { SendProposalModal };
