import React, { useState } from "react";

import { invitationService } from "../_services";
import { Alert, Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { IInvitation } from "../customTypings/Invitation";
import { InvitationRoleEdit } from "./InvitationRoleEdit";
import { LoadingSpinner } from "./LoadingSpinner";

type Props = {
    invitation: IInvitation;
    onUpdate: () => void;
};

const InvitationAdmin: React.FC<Props> = ({ invitation, onUpdate }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [status, setStatus] = useState("");
    const [showEditRoles, setShowEditRoles] = useState(false);

    return (
        <Container>
            <Row>
                <Col>
                    <p>{invitation.email}</p>
                    <p>{invitation.organisationName}</p>
                </Col>
                <Col md="auto">
                    <Button
                        className="mt-2 me-2"
                        variant="success"
                        disabled={showEditRoles}
                        onClick={() => {
                            setShowEditRoles(true);
                            onUpdate();
                        }}
                    >
                        Edit Roles
                    </Button>
                    <InvitationRoleEdit
                        show={showEditRoles}
                        onClose={(updated) => {
                            if (updated) {
                                onUpdate();
                            } else {
                                setShowEditRoles(false);
                            }
                        }}
                        invitationId={invitation.tokenId}
                    />
                    <Button
                        className="mt-2"
                        variant="danger"
                        disabled={isDeleting}
                        onClick={() => {
                            setIsDeleting(true);
                            invitationService.remove(invitation.tokenId).then(
                                (response) => {
                                    if (response.status !== "Failure") {
                                        setIsDeleting(false);
                                        onUpdate();
                                    } else {
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    if (error.status === 400) {
                                        setStatus(error.title);
                                    } else {
                                        setStatus(error);
                                    }
                                    setIsDeleting(false);
                                }
                            );
                        }}
                    >
                        {isDeleting ? <LoadingSpinner text="Removing..." /> : "Remove"}
                    </Button>
                </Col>
            </Row>
            <Row>
                {status && (
                    <Alert variant="danger" dismissible>
                        {status}
                    </Alert>
                )}
                <div>Roles</div>
                <ListGroup>
                    {invitation.roles.map((role) => (
                        <ListGroup.Item className="d-flex justify-content-between align-items-start" key={invitation.tokenId + "_" + role.id}>
                            {role.name}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Row>
        </Container>
    );
};

export { InvitationAdmin };
