import { authHeader, handleResponse } from '../_helpers';

export const organisationService = {
    getAll,
    create,
    disableWithId,
    enableWithId,
    updateDisplayName,
    deleteWithId
};

function getAll(displayName?: string | null, isEnabled?: boolean | null, isDeleted?: boolean | null) {
    const requestOptions = { 
        method: 'POST', 
        headers: authHeader(),
        body: JSON.stringify({ displayName, isEnabled, isDeleted }) 
    };
    return fetch(`api/organisations/getall`, requestOptions).then(handleResponse);
}

function create(displayName: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ displayName })
    };
    return fetch(`api/organisations/create`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function disableWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/organisations/disablewithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function enableWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/organisations/enablewithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updateDisplayName(id: string, displayName: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/organisations/updatedisplayname?id=${id}&displayname=${displayName}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function deleteWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/organisations/delete?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}