import { checklistDefinitionService, userService } from "../_services";
import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IChecklistDefinition } from "../customTypings/ChecklistDefinition";
import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";
import Skeleton from "react-loading-skeleton";

type Props = {
    index: number;
    identityId?: string;
    checklistDefinitionId?: string;
    onDelete: (index: number) => void;
    onEdit: (index: number, identityId?: string, checklistDefinitionId?: string) => void;
};

const TaskChecklist: React.FC<Props> = ({ index, identityId, checklistDefinitionId, onDelete, onEdit }) => {
    const [loadingLeadEngineerIdentity, setLoadingLeadEngineerIdentity] = useState(true);
    const [loadingChecklistDefinition, setLoadingChecklistDefinition] = useState(true);
    const [leadEngineerIdentity, setLeadEngineerIdentity] = useState<IValueAndLabel>();
    const [checklistDefinition, setChecklistDefinition] = useState<IChecklistDefinition>();

    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();
    const [allChecklistDefinitions, setAllChecklistDefinitions] = useState<IChecklistDefinition[]>();

    useEffect(() => {
        userService.getAllListItems().then((identities) => {
            setAllIdentities(identities);
            setLeadEngineerIdentity(identities.find((item: IValueAndLabel) => item.value === identityId));
            setLoadingLeadEngineerIdentity(false);
        });
        checklistDefinitionService.getAllActive().then((checklistDefinitions) => {
            setAllChecklistDefinitions(checklistDefinitions);
            setChecklistDefinition(checklistDefinitions.find((item: IChecklistDefinition) => item.id === checklistDefinitionId));
            setLoadingChecklistDefinition(false);
        });
    }, []);

    return !loadingLeadEngineerIdentity && !loadingChecklistDefinition ? (
        <Row>
            <Col xs={3}>
                {allIdentities && (
                    <Select
                        defaultValue={allIdentities.find((item) => item.value === identityId)}
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect z-2"
                        onChange={(value) => {
                            setLeadEngineerIdentity(value == null ? "" : value);
                            onEdit(index, (value == null ? "" : value)?.value, checklistDefinition?.id);
                        }}
                        isClearable
                        options={allIdentities}
                    />
                )}
            </Col>
            <Col xs={8} className="lh-lg">
                {allChecklistDefinitions && (
                    <Select
                        defaultValue={allChecklistDefinitions.find((item) => item.id === checklistDefinitionId)}
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect z-2"
                        onChange={(value) => {
                            setChecklistDefinition(value == null ? undefined : (value as IChecklistDefinition));
                            onEdit(index, leadEngineerIdentity?.value, (value == null ? undefined : (value as IChecklistDefinition))?.id);
                        }}
                        isClearable
                        options={allChecklistDefinitions}
                        getOptionLabel={(option: IChecklistDefinition) => `${option.name}`}
                        getOptionValue={(option: IChecklistDefinition) => `${option["id"]}`}
                    />
                )}
            </Col>
            <Col xs={1} className="lh-lg">
                <Button
                    variant="danger"
                    onClick={() => {
                        onDelete(index);
                    }}
                    className="me-2"
                >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                </Button>
            </Col>
        </Row>
    ) : (
        <Skeleton />
    );
};

export { TaskChecklist };
