import { useEffect, useState } from 'react';
import { Button, Stack, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCheck, faGripVertical, faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ITaskChecklistItem } from '@/customTypings/TaskChecklistItem';
import { clone } from '../_helpers/utils';

type Props = {
    taskChecklistItemOption: ITaskChecklistItem,
    onChange: (checklistDefinitionItem: ITaskChecklistItem) => void,
    onDelete: () => void,
}
   
const TaskPlantMachineryChecklistItem: React.FC<Props> =  ({taskChecklistItemOption, onChange, onDelete}) =>  {
    const [taskChecklistItem, setTaskChecklistItem] = useState<ITaskChecklistItem>(clone(taskChecklistItemOption));
    const [editing, setEditing] = useState(false);
    const [updatedName, setUpdatedName] = useState(taskChecklistItem.name);
    
    useEffect(()=> { 
        setTaskChecklistItem(clone(taskChecklistItemOption));
    }, [taskChecklistItemOption]);
    
    const handleNameChange = () => {
        const updatedItem = clone(taskChecklistItem);
        updatedItem.name = updatedName;

        onChange(updatedItem);
    };

    return (
        <Card className="p-0">
            <div className="w-100 p-2">
                {editing ? 
                    <>
                        <Form className="me-auto" onSubmit={(e) => {
                            e.preventDefault()
                            handleNameChange()
                            setEditing(false)
                        }}>
                            <Stack className="ps-2" direction="horizontal" gap={3}>
                                <FontAwesomeIcon icon={faGripVertical} />
                                <Form.Control className="me-auto" type="text" defaultValue={updatedName} onChange={e => setUpdatedName(e.target.value)} />
                                <Button 
                                    variant="outline-success" 
                                    onClick={() => {
                                        handleNameChange()
                                        setEditing(false)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </Button>
                                <Button 
                                    variant="outline-danger" 
                                    onClick={() => {
                                        setUpdatedName(taskChecklistItem.name)
                                        setEditing(false)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faXmark} />
                                </Button>
                            </Stack>
                        </Form>
                    </> :
                    <>
                        <Stack className="ps-2" direction="horizontal" gap={3}>
                            <FontAwesomeIcon icon={faGripVertical} />
                            <label>
                                <span>{taskChecklistItem.name}</span>
                            </label>
                            <Button 
                                className="ms-auto"
                                variant="outline-primary" 
                                onClick={() => {
                                    setEditing(true)
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button 
                                variant="outline-danger" 
                                onClick={() => {
                                    onDelete()
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </Stack>
                    </>
                }
            </div>
        </Card>
    )
}

export { TaskPlantMachineryChecklistItem };