import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from 'react-helmet';
import { TaskListPage } from "../Task/TaskListPage";
import { TaskType } from "../_constants/TaskType";

const DNOPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>DNOs</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">DNOs</h1>
                    <TaskListPage 
                        taskTypes={
                            [
                                TaskType.DNO,
                                TaskType.ChaseDNO,
                                TaskType.PostInstallationDNO,
                                TaskType.ChasePostInstallationDNO
                            ]
                        }
                        isCompleted={false}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export { DNOPage }; 
