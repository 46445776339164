import { authHeader, handleResponse } from '../_helpers';

export const forgotPasswordService = {
    create,
    isActive,
    isForCurrentUser,
    resetPassword
};

function isActive(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/forgotpassword/isactive?id=${id}`, requestOptions).then(handleResponse);
}

function isForCurrentUser(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/forgotpassword/isforcurrentuser?id=${id}`, requestOptions).then(handleResponse);
}

function create(email: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/forgotpassword/create?email=${email}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });;
}

function resetPassword(tokenId: string, newPassword: string, confirmPassword: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ tokenId, newPassword, confirmPassword })
    };
    return fetch(`api/forgotpassword/resetpassword`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}