import { IJobField } from "@/customTypings/JobField";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { jobService, jobtypegroupService } from "../_services";
import { ErrorMessage, Field, Formik, useFormikContext, FormikValues } from "formik";
import { useEffect, useState } from "react";
import { Button, Container, Col, Row, Stack, Form, Spinner, Image, InputGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";
import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import { IContact } from "@/customTypings/Contact";
import { IAddress } from "@/customTypings/Address";
import { IJobNew } from "@/customTypings/JobNew";
import { IJobFieldDefinition } from "@/customTypings/JobFieldDefinition";
import JobFieldSelection from "../_components/JobFieldSelection";
import { IJobTypeGroup } from "@/customTypings/JobTypeGroup";
import { useParams } from "react-router-dom";
import { JobStatus } from "../_constants/JobStatus";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { NextActionsModel } from "../_components/NextAcrionsModel";
import { ITask } from "../customTypings/Task";
import { ITaskList } from "../customTypings/TaskList";
import { TaskType } from "../_constants/TaskType";
import { CreateTask } from "../_components/Task/CreateTask";
import { ITaskFlow, TaskFlowNextAction } from "../_components/Task/TaskFlow";
import { EditJobScheduleCallModel } from "../_components/EditJobScheduleCallModel";

const EnquiryPage = () => {
    let params = useParams();
    const allSectors: IValueAndLabel[] = [
        { value: "unknown", label: "Not Known" },
        { value: "domestic", label: "Domestic" },
        { value: "commercial", label: "Commercial" },
        { value: "public", label: "Public sector" },
    ];
    const [job, setJob] = useState<any>();
    const [initJobFields, setInitJobFields] = useState<IJobField[]>();
    const [initExtraJobFields, setInitExtraJobFields] = useState<IJobField[]>();
    const [initJobTypesSelected, setInitJobTypesSelected] = useState<string[]>();
    const [jobTypeGroups, setJobTypeGroups] = useState<IJobTypeGroup[]>();
    const [customerId, setCustomerId] = useState("");
    const [loading, setLoading] = useState(true);
    const currentUser = useAtom(userAtom);
    const [showNextActions, setShowNextActions] = useState(false);
    const [showNextActionsTasks, setShowNextActionsTasks] = useState(false);
    const [showNextActionsCallback, setShowNextActionsCallback] = useState(false);
    const [nextActionsTaskTypes, setNextActionsTaskTypes] = useState<TaskType[]>();

    const [actions, setActions] = useState<ITaskFlow[]>([]);

    useEffect(() => {
        setActions(TaskFlowNextAction());
        Promise.all([jobService.getEnquiry(params.jobId ?? ""), jobtypegroupService.getAllEntities()])
            .then((response) => {
                var jobEntry = response[0];
                setJob(jobEntry);
                setCustomerId(jobEntry.propertyOwner.id == null ? "" : jobEntry.propertyOwner.id);

                var jobTypeArray: string[] = [];
                if (jobEntry.jobTypes !== null) {
                    jobEntry.jobTypes.map((jobType: any) => {
                        jobTypeArray.push(jobType.id);
                    });
                }
                setInitJobTypesSelected(jobTypeArray);

                var jobFieldArray: IJobField[] = [];
                var extraJobFieldArray: IJobField[] = [];
                if (jobEntry.jobFields !== null) {
                    jobEntry.jobFields.map((jobField: any) => {
                        if (jobField.jobFieldDefinition.isStandard) {
                            jobFieldArray.push(jobField);
                        } else {
                            extraJobFieldArray.push(jobField);
                        }
                    });
                }
                setInitJobFields(jobFieldArray);
                setInitExtraJobFields(extraJobFieldArray);

                setJobTypeGroups(response[1]);
                setLoading(false);
            })
            .catch((rejected) => {
                /*setAlertMessage("Could not retrieve list of folders and documents: " + rejected)*/
                setLoading(false);
            });
    }, []);

    const handleNextActionSelection = (action: string) => {
        setShowNextActions(false);
        if (action.length > 0) {
            const tasksToBeCreated = actions.find((item) => item.value === action);
            if (tasksToBeCreated?.closeJob) {
                jobService.disable(params.jobId ?? "");
            } else if (tasksToBeCreated?.scheduleCallback) {
                setShowNextActionsCallback(true);
            } else {
                setNextActionsTaskTypes(tasksToBeCreated?.tasks);
                setShowNextActionsTasks(true);
            }
        }
    };

    if (loading) {
        return (
            <div className="d-flex align-content-center justify-content-center flex-wrap vh-100">
                <Stack className="justify-content-center mb-5" gap={3}>
                    <Image src="/ReactCrmBoilerplateLogo.png" className="mx-auto" style={{ height: "5rem" }} />
                    <Spinner animation={"border"} variant="info" className="mx-auto" />
                </Stack>
            </div>
        );
    }

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Container fluid>
            <Helmet>
                <title>Enquiry</title>
            </Helmet>
            <>
                <h1 className="my-3">Enquiry</h1>
                <Form>
                    <Stack gap={3}>
                        <h2>Customer</h2>
                        <Row>
                            <Col>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={6} htmlFor="customerName">
                                        Business Name
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control placeholder={job.propertyOwner.businessName} disabled />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={6} htmlFor="customerNumber">
                                        Customer Number
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control placeholder={job.propertyOwner.customerNumber} disabled />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactName">
                                Contact Name
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.name} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactPhone">
                                Phone
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.phoneNumber} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactEmail">
                                Email
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.emailAddress} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressLine1">
                                Address Line 1
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.line1} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressLine2">
                                Address Line 2
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.line2} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressLine3">
                                Address Line 3
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.line3} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressTown">
                                Town
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.town} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressCounty">
                                County
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.county} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressPostcode">
                                Postcode
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <Form.Control placeholder={job.propertyOwner.primaryContact.address.postcode} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={labelSpan} htmlFor="contactAddressWhat3Words">
                                What3Words
                            </Form.Label>
                            <Col sm={fieldSpan}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>///</InputGroup.Text>
                                    <Form.Control placeholder={job.propertyOwner.primaryContact.address.what3words} disabled />
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Stack>
                </Form>
                <Formik
                    initialValues={{
                        sector: job.sector,
                        scheme: job.scheme,
                        isDiffrentAddress: job.address !== null,
                        installationAddressLine1: job.address !== null ? job.address.line1 ?? "" : "",
                        installationAddressLine2: job.address !== null ? job.address.line2 ?? "" : "",
                        installationAddressLine3: job.address !== null ? job.address.line3 ?? "" : "",
                        installationAddressTown: job.address !== null ? job.address.town ?? "" : "",
                        installationAddressCounty: job.address !== null ? job.address.county ?? "" : "",
                        installationAddressPostcode: job.address !== null ? job.address.postcode ?? "" : "",
                        installationAddressWhat3Words: job.address !== null ? job.address.what3words ?? "" : "",
                        isTenant: job.occupier !== null,
                        tenantName: job.occupier !== null ? job.occupier.name ?? "" : "",
                        tenantPhone: job.occupier !== null ? job.occupier.phoneNumber ?? "" : "",
                        tenantEmail: job.occupier !== null ? job.occupier.emailAddress ?? "" : "",
                        types: initJobTypesSelected as string[],
                        standardColumns: initJobFields as IJobField[],
                        extraColumns: initExtraJobFields as IJobField[],
                        notes: job.notes,
                    }}
                    /*validationSchema={Yup.object().shape({
                        newPassword: Yup.string().required('New Password is required'),
                        confirmPassword: Yup.string().required('Confirm Password is required')
                    })}*/
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        console.log(values.types);
                        var newTenant: IContact | undefined;
                        var newAddress: IAddress | undefined;
                        if (values.isTenant) {
                            newTenant = {
                                name: values.tenantName,
                                emailAddress: values.tenantEmail,
                                phoneNumber: values.tenantPhone,
                            };
                        }
                        if (values.isDiffrentAddress) {
                            newAddress = {
                                line1: values.installationAddressLine1,
                                line2: values.installationAddressLine2 === "" ? undefined : values.installationAddressLine2,
                                line3: values.installationAddressLine3 === "" ? undefined : values.installationAddressLine3,
                                town: values.installationAddressTown,
                                county: values.installationAddressCounty,
                                postcode: values.installationAddressPostcode,
                                what3words: values.installationAddressWhat3Words,
                                latitude: undefined,
                                longitude: undefined,
                            };
                        }
                        var jobFieldArray: IJobField[] = [...values.standardColumns, ...values.extraColumns];
                        console.log("Tests: ", [job.sector]);
                        console.log("Tests: ", allSectors);
                        console.log("Tests: ", [allSectors.indexOf(job.sector)]);
                        console.log("Tests: ", [allSectors[allSectors.indexOf(job.sector)].value]);
                        const newJob: IJobNew = {
                            JobNumber: undefined,
                            Sector: allSectors[allSectors.indexOf(job.sector)].value,
                            scheme: values.scheme,
                            PropertyOwnerId: customerId,
                            PropertyOwnerPreferredContactId: undefined,
                            SchemeOrganizationId: undefined,
                            SchemeOrganisationPreferredContactId: undefined,
                            Scheme: undefined,
                            Occupier: newTenant,
                            Address: newAddress,
                            EnquiryDate: new Date(),
                            InstallationDate: undefined,
                            IsJobTypesLocked: false,
                            IsActive: true,
                            JobFields: jobFieldArray,
                            JobTypeIds: values.types,
                            Status: JobStatus.pendingCallback,
                            IdentityId: currentUser ? currentUser.identity.id : undefined,
                            notes: values.notes,
                        };
                        jobService.addFull(newJob).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                } else {
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setSubmitting(false);
                                if (error.status === 400) {
                                    setFieldError("newPassword", error.errors.newPassword);
                                    setFieldError("confirmPassword", error.errors.ConfirmPassword);
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                            }
                        );
                    }}
                >
                    {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue, handleChange }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Stack gap={3}>
                                <Form.Group as={Row} className="mb-3" controlId="sector">
                                    <Form.Label column sm={labelSpan}>
                                        Job Sector
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Select
                                            defaultValue={allSectors.find((obj) => {
                                                return obj.value === job.sector;
                                            })}
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("sector", value == null ? "" : value.value)}
                                            options={allSectors}
                                        />
                                        <ErrorMessage name="sector" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.sector === "public" && (
                                    <Form.Group as={Row} controlId="scheme">
                                        <Form.Label column sm={labelSpan}>
                                            Job Scheme
                                        </Form.Label>
                                        <Col sm={fieldSpan}>
                                            <Field
                                                name="scheme"
                                                type="text"
                                                disabled={values.sector !== "public"}
                                                className={"form-control" + (errors.scheme && touched.scheme ? " is-invalid" : "")}
                                            />
                                            <ErrorMessage name="scheme" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                )}
                                <h2>Installation address</h2>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={labelSpan} htmlFor="isDiffrentAddress">
                                        Is installation at diffrent address?
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Form.Check
                                            type="switch"
                                            name="isDiffrentAddress"
                                            defaultChecked={values.isDiffrentAddress}
                                            className={"form-check" + (errors.isDiffrentAddress && touched.isDiffrentAddress ? " is-invalid" : "")}
                                            onChange={(e) => setFieldValue("isDiffrentAddress", e.target.checked == null ? false : e.target.checked)}
                                        />
                                        <ErrorMessage name="isDiffrentAddress" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.isDiffrentAddress && (
                                    <>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine1">
                                                Address 1
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine1"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine1 && touched.installationAddressLine1 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine1" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine2">
                                                Address 2
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine2"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine2 && touched.installationAddressLine2 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine2" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine3">
                                                Address 3
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine3"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine3 && touched.installationAddressLine3 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine3" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={6} htmlFor="installationAddressTown">
                                                Town
                                            </Form.Label>
                                            <Col sm={6}>
                                                <Field
                                                    name="installationAddressTown"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressTown && touched.installationAddressTown ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressTown" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressCounty">
                                                County
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressCounty"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressCounty && touched.installationAddressCounty ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressCounty" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressPostcode">
                                                Postcode
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressPostcode"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressPostcode && touched.installationAddressPostcode ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressPostcode" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressWhat3Words">
                                                What3Words
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Text>///</InputGroup.Text>
                                                    <Field
                                                        name="installationAddressWhat3Words"
                                                        type="text"
                                                        disabled={!values.isDiffrentAddress}
                                                        className={"form-control" + (errors.installationAddressWhat3Words && touched.installationAddressWhat3Words ? " is-invalid" : "")}
                                                    />
                                                </InputGroup>
                                                <ErrorMessage name="installationAddressWhat3Words" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={labelSpan} htmlFor="isTenant">
                                        Is there a tenant?
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Form.Check
                                            type="switch"
                                            name="isTenant"
                                            defaultChecked={values.isTenant}
                                            className={"form-check" + (errors.isTenant && touched.isTenant ? " is-invalid" : "")}
                                            onChange={(e) => setFieldValue("isTenant", e.target.checked == null ? false : e.target.checked)}
                                        />
                                        <ErrorMessage name="isTenant" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.isTenant && (
                                    <>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="tenantName">
                                                Tenant Name
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantName"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantName && touched.tenantName ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantName" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="tenantPhone">
                                                Tenant Phone
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantPhone"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantPhone && touched.tenantPhone ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantPhone" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label column sm={labelSpan} htmlFor="tenantEmail">
                                                Tenant Email
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantEmail"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantEmail && touched.tenantEmail ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantEmail" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                <h2>Job Details</h2>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={labelSpan} htmlFor="types">
                                        Type of enquiry
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        {jobTypeGroups &&
                                            jobTypeGroups.map((jobTypeGroup) => (
                                                <div role="group" aria-labelledby="checkbox-group" key={jobTypeGroup.id}>
                                                    <h6 key={jobTypeGroup.id} id="checkbox-group" className="mt-3">
                                                        {jobTypeGroup.name} enquiry
                                                    </h6>
                                                    {jobTypeGroup.jobTypes &&
                                                        jobTypeGroup.jobTypes.map((jobType) => (
                                                            <label key={jobType.id} className="d-block">
                                                                <Field type="checkbox" name="types" value={jobType.id} className="me-2" />
                                                                {jobType.name}
                                                            </label>
                                                        ))}
                                                </div>
                                            ))}
                                    </Col>
                                </Form.Group>
                                {values.standardColumns.map((jobField, index) => (
                                    <JobFieldSelection
                                        key={jobField.jobFieldDefinition?.id}
                                        index={index}
                                        jobFieldDefinition={jobField.jobFieldDefinition}
                                        jobFields={values.standardColumns}
                                        setFieldValue={setFieldValue}
                                        fieldValueName="standardColumns"
                                        defaultValue={jobField.value}
                                    />
                                ))}
                                <hr />
                                {values.extraColumns.map((jobField, index) => (
                                    <JobFieldSelection
                                        key={jobField.jobFieldDefinition?.id}
                                        index={index}
                                        jobFieldDefinition={jobField.jobFieldDefinition}
                                        jobFields={values.extraColumns}
                                        setFieldValue={setFieldValue}
                                        fieldValueName="extraColumns"
                                        defaultValue={jobField.value}
                                    />
                                ))}
                                <h2>Notes</h2>
                                <Form.Group controlId="jobNotes">
                                    <Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="ms-auto">
                                            <Button variant="success" disabled={isSubmitting} type="submit">
                                                {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                                            </Button>
                                        </div>
                                        <Button variant="secondary" disabled={!(params.jobId && params.jobId.length)} onClick={() => setShowNextActions(true)}>
                                            Next Actions
                                        </Button>
                                    </Stack>
                                </Form.Group>
                                {status && <div className={"alert alert-danger"}>{status}</div>}
                            </Stack>
                            <FormObserver currentFields={initExtraJobFields || []} />
                        </Form>
                    )}
                </Formik>
                <NextActionsModel show={showNextActions} onClose={handleNextActionSelection} actions={actions} />
                <CreateTask show={showNextActionsTasks} onClose={(added) => setShowNextActionsTasks(false)} jobId={params.jobId} tasktypes={nextActionsTaskTypes} jobStatus={JobStatus.enquiry} />
                <EditJobScheduleCallModel show={showNextActionsCallback} onClose={() => setShowNextActionsCallback(false)} jobId={params.jobId ?? ""} />
            </>
        </Container>
    );
};

const FormObserver: React.FC<{ currentFields: IJobField[] }> = ({ currentFields }) => {
    const formik = useFormikContext();
    const values = formik.values as FormikValues;
    useEffect(() => {
        jobService.getExtraJobFieldDefinitions(values.types).then((jobFieldDefinitions) => {
            var jobFieldArray: IJobField[] = [];
            jobFieldDefinitions.map((jobFieldDefinition: IJobFieldDefinition) => {
                var currentField = currentFields.find((field) => field.jobFieldDefinition?.id === jobFieldDefinition.id);
                if (currentField) {
                    jobFieldArray.push({
                        id: currentField.id,
                        jobId: currentField.jobId,
                        jobFieldDefinition: jobFieldDefinition,
                        value: currentField.value,
                    });
                } else {
                    jobFieldArray.push({
                        id: undefined,
                        jobId: undefined,
                        jobFieldDefinition: jobFieldDefinition,
                        value: jobFieldDefinition.selectValues ? jobFieldDefinition.selectValues.split(",")[0] : undefined,
                    });
                }
            });
            formik.setFieldValue("extraColumns", jobFieldArray);
        });
    }, [values.types]);
    return null;
};

export { EnquiryPage };
