import React, { useEffect, useRef } from 'react';

type Props = {
    children: React.ReactNode;
    scrollWidth: number;
    scrollLeft?: number;
    onScroll: (value:number) => void;
}

const StickyScrollBar: React.FC<Props> = ({ scrollWidth, onScroll, scrollLeft, children }) => {
    const scrollBarElement = useRef<HTMLDivElement | null>(null);

    useEffect(()=>{
        if(scrollBarElement.current !== null && scrollLeft !== undefined) {
            scrollBarElement.current.scrollLeft = scrollLeft;
        }
    }, [scrollLeft]);

    return (
        <div className="position-relative">
            {children}
            <div onScroll={(e)=>onScroll(e.currentTarget.scrollLeft)} style={{overflowX: "auto", position: "sticky", bottom: 0}} ref={scrollBarElement}>
                <div style={{width: scrollWidth, height: "1px"}}>
                </div>
            </div>
        </div>
    )
}

export { StickyScrollBar };
