import { calendarEventService } from "../../_services";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { LoadingSpinner } from ".././LoadingSpinner";
import { IEvent } from "./EventCalendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    eventToBeUpdated?: IEvent;
};

const EventRemove: React.FC<Props> = ({ show, onClose, eventToBeUpdated }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    isRemoveAll: false,
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    setStatus();
                    setSubmitting(true);
                    calendarEventService
                        .remove(
                            eventToBeUpdated?.groupId ?? "",
                            values.isRemoveAll
                                ? undefined
                                : eventToBeUpdated?.identityId
                        )
                        .then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remove Event</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                className="mb-3"
                                controlId="removeCheckbox"
                            >
                                <Form.Check
                                    type="checkbox"
                                    name="isRemoveAll"
                                    className={
                                        "mt-1 form-check" +
                                        (errors.isRemoveAll &&
                                        touched.isRemoveAll
                                            ? " is-invalid"
                                            : "")
                                    }
                                    defaultChecked={values.isRemoveAll.valueOf()}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "isRemoveAll",
                                            e.target.checked == null
                                                ? false
                                                : e.target.checked
                                        )
                                    }
                                    label="Remove this event for all attendees?"
                                />
                                <ErrorMessage
                                    name="isAllDay"
                                    component="div"
                                    className="invalid-feedback"
                                />
                            </Form.Group>
                            {values.isRemoveAll && (
                                <Alert variant="info" className="mb-0">
                                    <FontAwesomeIcon
                                        className="me-2"
                                        icon={faInfoCircle}
                                        size="lg"
                                    />{" "}
                                    Please be aware this will remove the event
                                    from all attendees calendars.
                                </Alert>
                            )}
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="danger"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Removing event..." />
                                    ) : (
                                        "Remove event"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { EventRemove };
