import { useState } from 'react';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IValueAndLabel } from '../customTypings/ValueAndLabel';
import Select from 'react-select'
import { ReactSelectBootstrapStyle } from '../_styles/ReactSelectBootstrapStyle'
import dayjs from 'dayjs';

type Props = {
    index: number,
    identityId?: string,
    amount?: number,
    dueDate?: Date,
    onDelete: (index: number) => void,
    onEdit: (index: number, identityId?: string, amount?: number, dueDate?: Date) => void,
    allIdentities?: IValueAndLabel[],
}
   
const TaskInvoice: React.FC<Props> =  ({index, identityId, amount, dueDate, onDelete, onEdit, allIdentities = []}) =>  {
    const [isEditing, setIsEditing] = useState(false);
    const [leadEngineerIdentity, setLeadEngineerIdentity] = useState<string | undefined>(identityId);
    const [selectedAmount, setSelectedAmount] = useState<number | undefined>(amount);
    const [selectedDueDate, setSelectedDueDate] = useState<Date | undefined>(dueDate);

    const handleEdit = (e: any) => {
        e.preventDefault()
        onEdit(index, leadEngineerIdentity, selectedAmount, selectedDueDate)
        setIsEditing(false)
    }

    const handleReset = () => {
        setLeadEngineerIdentity(identityId)
        setSelectedAmount(amount);
        setSelectedDueDate(dueDate);
        setIsEditing(false)
    };

    return (
            <Form noValidate onSubmit={handleEdit}>
                <Row className="align-items-center">
                    <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputAssignee" visuallyHidden>
                            Assignee
                        </Form.Label>
                        {allIdentities && <Select
                            value={allIdentities.filter(function(item) {
                                return item.value === leadEngineerIdentity
                            })}
                            styles={ReactSelectBootstrapStyle}
                            className="stateManagedSelect z-2"
                            onChange={value=>setLeadEngineerIdentity(value === null ? undefined : value.value)}
                            isClearable
                            options={allIdentities}
                            isDisabled={!isEditing}
                        />}
                    </Col>
                    <Col sm={3} className="my-1">
                        <Form.Label htmlFor="inlineFormInputGroupAmount" visuallyHidden>
                            Amount
                        </Form.Label>
                        <InputGroup>
                            <InputGroup.Text>£</InputGroup.Text>
                            <Form.Control
                                type="number"
                                name="amount"
                                value={selectedAmount}
                                onChange={(e) => setSelectedAmount(parseInt(e.target.value, 10))}
                                disabled={!isEditing}
                            />
                            <InputGroup.Text>.00</InputGroup.Text>
                        </InputGroup>
                    </Col>
                    <Col xs="auto" className="my-1">
                        <Form.Control
                            type="date"
                            name="dueDate"
                            value={dayjs(selectedDueDate).format('YYYY-MM-DD')}
                            onChange={(e) => setSelectedDueDate(new Date(e.target.value))}
                            disabled={!isEditing}
                        />
                    </Col>
                    <Col xs="auto" className="ms-auto">
                        {isEditing ? 
                            <>
                                <Button 
                                    key="submit"
                                    type="submit"
                                    className="me-2" 
                                >
                                    Save
                                </Button> 
                                <Button 
                                    key="delete"
                                    type="button"
                                    variant="danger" 
                                    onClick={() => {
                                        onDelete(index)
                                    }}
                                    className="me-2" 
                                >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                </Button>
                                <Button 
                                    key="cancel" 
                                    type="reset"
                                    variant="secondary" 
                                    onClick={() => {
                                        handleReset()
                                    }}
                                    className="me-2" 
                                >
                                    Cancel
                                </Button>
                            </>
                            :
                            <>
                                <Button 
                                    key="edit"
                                    type="button"
                                    variant="secondary" 
                                    onClick={() => {
                                        setIsEditing(true)
                                    }}
                                    className="me-2" 
                                >
                                    <FontAwesomeIcon icon={faPen} /> Edit
                                </Button>
                            </>
                        }
                    </Col>
                </Row>
            </Form>
    )
}

export { TaskInvoice };