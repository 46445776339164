import { Alert, Button, Card, Col, Form, ListGroup, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFloppyDisk, faFolderClosed, faPlus, faRotateLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import { folderDefinitionService } from "../_services/folderdefinition.service";
import { IFolderDefinitionFolder } from "@/customTypings/FolderDefinitionFolder";
import { useEffect, useState } from "react";
import FolderDefinitionTree from "./FolderDefinitionTree";

export default function JobProposalFolderSettings() {
    const [currentFolderId, setCurrentFolderId] = useState<string>();
    const [rootFolders, setRootFolders] = useState<IFolderDefinitionFolder[]>();
    const [structure, setStructure] = useState<IFolderDefinitionFolder | undefined>();
    const [status, setStatus] = useState<string | undefined>();
    const [alertVariant, setAlertVariant] = useState("danger");
    const [potentialChange, setPotentialChange] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [isAddingTemplate, setIsAddingTemplate] = useState(false);
    const [folderName, setFolderName] = useState("");

    useEffect(() => {
        getTemplateFolders();
    }, []);

    useEffect(() => {
        getFolder();
    }, [currentFolderId]);

    const getTemplateFolders = () => {
        folderDefinitionService.getByType("proposal").then(
            (response: IFolderDefinitionFolder[]) => {
                setRootFolders(response);
                if (currentFolderId) {
                    if (response.some((item) => currentFolderId !== item.id)) {
                        if (response[0] !== null) setCurrentFolderId(response[0].id);
                    } else {
                        setCurrentFolderId(undefined);
                    }
                } else {
                    if (response[0] !== null) setCurrentFolderId(response[0].id);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
            }
        );
    };

    const getFolder = () => {
        if (currentFolderId) {
            folderDefinitionService.getFull(currentFolderId).then(
                (response) => {
                    if (response !== undefined && response !== null && response !== "") {
                        setFolderKeys(response);
                        setStructure(response);
                    } else {
                        setStructure({
                            name: "Root",
                            id: currentFolderId,
                        } as IFolderDefinitionFolder);
                    }
                },
                (error) => {
                    setAlertVariant("danger");
                    if (error.status === 400) {
                        setStatus(error.title);
                    } else {
                        setStatus(error);
                    }
                }
            );
        } else {
            setStructure({
                name: "Root",
                id: currentFolderId,
            } as IFolderDefinitionFolder);
        }
    };

    const setFolderKeys = (folderDefinitionFolder: IFolderDefinitionFolder) => {
        if (folderDefinitionFolder.children) {
            folderDefinitionFolder.children?.map((childFolder) => {
                setFolderKeys(childFolder);
            });
        }
        folderDefinitionFolder.folderKey = folderDefinitionFolder.id ?? "";
    };

    const onFolderSave = (folderDefinitionFolder: IFolderDefinitionFolder) => {
        folderDefinitionService.addFull(folderDefinitionFolder).then(
            (response) => {
                if (response.status !== "Failure") {
                    setAlertVariant("success");
                    setStatus("Update successful");
                    setPotentialChange(false);
                    getFolder();
                } else {
                    setAlertVariant("danger");
                    setStatus(response.message);
                }
            },
            (error) => {
                setAlertVariant("danger");
                if (error.status === 400) {
                    setStatus(error.title);
                } else {
                    setStatus(error);
                }
            }
        );
    };

    const onFolderChange = (folderDefinitionFolder: IFolderDefinitionFolder) => {
        setPotentialChange(true);
        setStructure(folderDefinitionFolder);
    };

    return (
        <>
            <Row>
                <Stack direction="horizontal" gap={2}>
                    <h2>Proposal Folders</h2>
                    <Button
                        className="ms-auto"
                        variant="success"
                        disabled={structure === undefined || !potentialChange}
                        onClick={() =>
                            onFolderSave(
                                structure
                                    ? structure
                                    : ({
                                          name: "Root",
                                          id: currentFolderId,
                                      } as IFolderDefinitionFolder)
                            )
                        }
                    >
                        Save <FontAwesomeIcon icon={faFloppyDisk} />
                    </Button>
                    <Button
                        variant="warning"
                        disabled={structure === undefined || !potentialChange}
                        onClick={() => {
                            setPotentialChange(false);
                            getFolder();
                        }}
                    >
                        Reset <FontAwesomeIcon icon={faRotateLeft} />
                    </Button>
                </Stack>
            </Row>
            <Row>
                <Col lg="2">
                    <Card className="bg-light shadow">
                        <Card.Body>
                            <h5 className="my-2">Proposal Folder Templates</h5>
                            <ListGroup variant="flush" className="mb-2">
                                {rootFolders ? (
                                    <ListGroup variant="flush">
                                        {rootFolders.map((rootFolder: IFolderDefinitionFolder) => (
                                            <ListGroup.Item key={rootFolder.id} action active={currentFolderId === rootFolder.id} onClick={() => setCurrentFolderId(rootFolder.id)}>
                                                {rootFolder.name}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                ) : (
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            <Skeleton width="6rem" />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Skeleton width="5rem" />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Skeleton width="9rem" />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Skeleton width="7rem" />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Skeleton width="8rem" />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Skeleton width="5rem" />
                                        </ListGroup.Item>
                                    </ListGroup>
                                )}
                            </ListGroup>
                            {!isAddingTemplate ? (
                                <Button className="ms-auto" variant="success" onClick={() => setIsAddingTemplate(true)}>
                                    New <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            ) : (
                                <Stack direction="horizontal" gap={2}>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            folderDefinitionService.addFull({ folderKey: "new", id: undefined, name: folderName, type: "proposal" } as IFolderDefinitionFolder).then(
                                                (response) => {
                                                    if (response.status !== "Failure") {
                                                        setAlertVariant("success");
                                                        setStatus("Add successful");
                                                        getTemplateFolders();
                                                        setIsAddingTemplate(false);
                                                    } else {
                                                        setAlertVariant("danger");
                                                        setStatus(response.message);
                                                    }
                                                    setSubmitting(false);
                                                },
                                                (error) => {
                                                    setAlertVariant("danger");
                                                    if (error.status === 400) {
                                                        setStatus(error.title);
                                                    } else {
                                                        setStatus(error);
                                                    }
                                                    setSubmitting(false);
                                                }
                                            );
                                        }}
                                    >
                                        <Form.Group controlId="formFolderName">
                                            <Form.Control type="text" defaultValue={""} onChange={(e) => setFolderName(e.target.value)} />
                                        </Form.Group>
                                    </Form>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="onclick-hover"
                                        onClick={() => {
                                            folderDefinitionService.addFull({ folderKey: "new", id: undefined, name: folderName, type: "proposal" } as IFolderDefinitionFolder).then(
                                                (response) => {
                                                    if (response.status !== "Failure") {
                                                        setAlertVariant("success");
                                                        setStatus("Add successful");
                                                        getTemplateFolders();
                                                        setIsAddingTemplate(false);
                                                    } else {
                                                        setAlertVariant("danger");
                                                        setStatus(response.message);
                                                    }
                                                    setSubmitting(false);
                                                },
                                                (error) => {
                                                    setAlertVariant("danger");
                                                    if (error.status === 400) {
                                                        setStatus(error.title);
                                                    } else {
                                                        setStatus(error);
                                                    }
                                                    setSubmitting(false);
                                                }
                                            );
                                        }}
                                    />
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        className="onclick-hover"
                                        onClick={() => {
                                            setIsAddingTemplate(!isAddingTemplate);
                                            setFolderName("");
                                        }}
                                    />
                                </Stack>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="bg-light shadow">
                        <Card.Body>
                            {structure ? (
                                <FolderDefinitionTree data={structure} onChange={onFolderChange} />
                            ) : (
                                <Stack direction="horizontal" gap={2}>
                                    <FontAwesomeIcon icon={faFolderClosed} />
                                    <Skeleton width="5rem" />
                                </Stack>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {status && (
                <Row>
                    <Alert variant={alertVariant} onClose={() => setStatus(undefined)} dismissible>
                        {status}
                    </Alert>
                </Row>
            )}
        </>
    );
}
