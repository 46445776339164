import React, { useEffect, useState } from 'react'
import { IFolderTreeFolder } from './Declarations';
import Folder from './Folder';
import FolderTreeRecursive from './FolderTreeRecursive';

type Props = {
    data: IFolderTreeFolder
    onChange: (data: IFolderTreeFolder) => void;
    }

const FolderTree: React.FC<Props> = ({ data, onChange }) => {
    const [treeState, setTreeState] = useState(data);

    useEffect(() => {
        onChange(treeState)
    }, [treeState]);

    const onTreeStateChange = (newState: IFolderTreeFolder[]) => {
        const updatedFolder: IFolderTreeFolder = {...data, children: newState}

        setTreeState(updatedFolder)
    };

    const onFolderAdd = (folderKey: string) => {
        var updatedChildren = [] as IFolderTreeFolder[]
        var newFolder = {folderKey: "new", id: undefined, jobTypeId: data.jobTypeId, name: "New Folder"} as IFolderTreeFolder
        if(data.children)
        {
            updatedChildren = data.children
    
            const currentNewFolderNumber = updatedChildren.filter((folder) => {
                return folder.name.startsWith('New Folder')
            }).length
            newFolder.folderKey += currentNewFolderNumber
            newFolder.name += ' ('+currentNewFolderNumber+')'
        }
        updatedChildren.push(newFolder)
        const updatedFolder: IFolderTreeFolder = {...data, children: updatedChildren}

        setTreeState(updatedFolder);
    }

    const onFolderChange = (folderKey: string, name: string, isOpen: boolean, specialHandling: string) => {
        const updatedFolder: IFolderTreeFolder = {...data, name: name, isOpen: isOpen, specialHandling: specialHandling}

        setTreeState(updatedFolder)
    }

    const onFolderDelete = (folderKey: string) => {
        alert("Unable to remove root folder")
    }
    
    return (
        <>
            <Folder key={data.folderKey} folder={data} onAdd={onFolderAdd} onChange={onFolderChange} onDelete={onFolderDelete} isRoot={true} >
                {data.children && <FolderTreeRecursive folderKey={data.folderKey} data={data.children} onChange={onTreeStateChange} />}
            </Folder>
        </>
    )
}

export default FolderTree