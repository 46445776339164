import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { LoadingSpinner } from "./LoadingSpinner";
import { jobService, taskService, userService } from "../_services";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";
import { ITask } from "../customTypings/Task";
import { TaskType } from "../_constants/TaskType";

type Props = {
    show: boolean;
    onClose: (added: boolean) => void;
    assignedId?: string;
    jobId?: string;
};

const AddNewTask: React.FC<Props> = ({ show, onClose, assignedId, jobId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allTasks, setAllTasks] = useState<IValueAndLabel[]>();
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        Promise.all([jobService.getAllListItems(), userService.getAllListItems()]).then((response) => {
            setAllTasks(response[0]);
            setAllIdentities(response[1]);
        });
        //.catch(rejected => {
        //  setAlertMessage("Could not retrieve list of folders and documents: " + rejected)
        //  setIsLoading(false);
        //});
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    job: jobId ? jobId : "",
                    assignee: assignedId ? assignedId : "",
                    assigneeTwo: "",
                    name: "",
                    dueDate: new Date(),
                    notes: "",
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const newTask: ITask = {
                        id: undefined,
                        name: values.name,
                        dueDate: values.dueDate,
                        notes: values.notes,
                        jobId: values.job,
                        taskType: TaskType.AdHoc,
                        creationDate: new Date(),
                        completedDate: undefined,
                        originalDueDate: values.dueDate,
                        assigneeIdentityId: values.assignee.length === 0 ? undefined : values.assignee,
                        secondAssigneeIdentityId: values.assigneeTwo.length === 0 ? undefined : values.assigneeTwo,
                        actionDate: undefined,
                    };
                    taskService.add(newTask).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("name", error.errors.Name);
                                setFieldError("job", error.errors.Job);
                                setFieldError("dueDate", error.errors.DueDate);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Ad-Hoc Task</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {!jobId && (
                                <Form.Group as={Row} className="mb-3" controlId="adhocTaskJob">
                                    <Form.Label column sm={3}>
                                        Job
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("job", value == null ? "" : value.value)}
                                            isClearable
                                            options={allTasks}
                                        />
                                        <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            {!assignedId && (
                                <Form.Group as={Row} className="mb-3" controlId="adhocTaskAssignee">
                                    <Form.Label column sm={3}>
                                        Assignee
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("assignee", value == null ? "" : value.value)}
                                            isClearable
                                            options={allIdentities}
                                        />
                                        <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            <Form.Group as={Row} className="mb-3" controlId="adhocTaskAssignee2">
                                <Form.Label column sm={3}>
                                    Assignee 2
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => setFieldValue("assigneeTwo", value == null ? "" : value.value)}
                                        isClearable
                                        options={allIdentities}
                                    />
                                    <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="adhocTaskName">
                                <Form.Label column sm={3}>
                                    Task Name
                                </Form.Label>
                                <Col sm={9}>
                                    <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="adhocTaskDueDate">
                                <Form.Label column sm={3}>
                                    Due Date
                                </Form.Label>
                                <Col sm={9}>
                                    <Field name="dueDate" type="date" className={"form-control" + (errors.dueDate && touched.dueDate ? " is-invalid" : "")} />
                                    <ErrorMessage name="dueDate" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="adhocTaskNotes">
                                <Form.Label column sm={3}>
                                    Notes
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} isValid={touched.notes && !errors.notes} />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating task..." /> : "Create task"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddNewTask };
