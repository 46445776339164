import { IIdentityAvatar } from "@/customTypings/IdentityAvatar";
import { ITask } from "@/customTypings/Task";
import { Avatar } from "../_components/Avatar";
import { JobCustomerDetails } from "../_components/JobCustomerDetails";
import { userService } from "../_services";
import { useEffect, useState } from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { AvatarSkeleton } from "../_components/AvatarSkeleton";
import { TaskType } from "../_constants/TaskType";
import dayjs from "dayjs";

type Props = {
    task: ITask;
};

const TaskDetails: React.FC<Props> = ({ task }) => {
    const [loadingAssigneeIdentity, setLoadingAssigneeIdentity] = useState(true);
    const [assigneeIdentity, setAssigneeIdentity] = useState<IIdentityAvatar>();
    const [secondAssigneeIdentity, setSecondAssigneeIdentity] = useState<IIdentityAvatar>();

    useEffect(() => {
        if (task.assigneeIdentityId) {
            userService.getAvatarById(task.assigneeIdentityId).then((identity) => {
                setAssigneeIdentity(identity);
                setLoadingAssigneeIdentity(false);
            });
        } else {
            setAssigneeIdentity(undefined);
            setLoadingAssigneeIdentity(false);
        }
        if (task.secondAssigneeIdentityId) {
            userService.getAvatarById(task.secondAssigneeIdentityId).then((identity) => {
                setSecondAssigneeIdentity(identity);
            });
        } else {
            setSecondAssigneeIdentity(undefined);
        }
    }, [task]);

    return (
        <>
            <Row>
                <Col md={3}>Due date</Col>
                <Col md={9}>{new Date(task.dueDate).toLocaleDateString()}</Col>
            </Row>
            {task.taskType.toString() === TaskType[TaskType.DNO] && (
                <>
                    <Row>
                        <Col md={3}>Request sent</Col>
                        <Col md={9}>{task.actionDate ? new Date(task.actionDate).toLocaleDateString() : "Not set yet"}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>DNO reference</Col>
                        <Col md={9}>{task.metadata?.find((item) => item.name === "Reference")?.value ?? "Not set yet"}</Col>
                    </Row>
                    <Row>
                        <Col md={3}>DNO expected date</Col>
                        <Col md={9}>
                            {task.metadata?.find((item) => item.name === "ExpectedDate")?.value
                                ? dayjs(task.metadata?.find((item) => item.name === "ExpectedDate")?.value ?? "")
                                      .toDate()
                                      .toLocaleDateString()
                                : "Not set yet"}
                        </Col>
                    </Row>
                </>
            )}
            <Row>
                <Col md={3}>Assigned to</Col>
                <Col md={9}>
                    <Stack gap={3}>
                        <div>
                            {assigneeIdentity && <Avatar identity={assigneeIdentity} />}
                            {!assigneeIdentity && !loadingAssigneeIdentity && <div>Unallocated</div>}
                            {!assigneeIdentity && loadingAssigneeIdentity && <AvatarSkeleton size={1.5} />}
                        </div>
                        <div>{secondAssigneeIdentity && <Avatar identity={secondAssigneeIdentity} />}</div>
                    </Stack>
                </Col>
            </Row>
            {(task.taskType.toString() === TaskType[TaskType.ChasePreSaleInvoice] ||
                task.taskType.toString() === TaskType[TaskType.RaisePreSaleInvoice] ||
                task.taskType.toString() === TaskType[TaskType.ChaseInvoice] ||
                task.taskType.toString() === TaskType[TaskType.RaiseInvoice]) &&
                task.metadata &&
                task.metadata.find((item) => item.name === "Amount") && (
                    <Row>
                        <Col md={3}>Amount</Col>
                        <Col md={9}>£{task.metadata.find((item) => item.name === "Amount")?.value}</Col>
                    </Row>
                )}
            <Row>
                <Col>
                    <JobCustomerDetails jobId={task.jobId} />
                </Col>
            </Row>
        </>
    );
};

export { TaskDetails };
