import { IJobField } from "@/customTypings/JobField";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { customerService, jobService, jobtypegroupService } from "../_services";
import { ErrorMessage, Field, Formik, useFormikContext, FormikValues } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { Button, Container, Col, Row, Stack, Form, Spinner, Image, InputGroup } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";
import { IValueAndLabel } from "../customTypings/ValueAndLabel";
import { ICustomer } from "@/customTypings/Customer";
import { ICustomerEntry } from "@/customTypings/CustomerEntry";
import { ICustomerContact } from "@/customTypings/CustomerContact";
import { IContact } from "@/customTypings/Contact";
import { IAddress } from "@/customTypings/Address";
import { IJobNew } from "@/customTypings/JobNew";
import { IJobFieldDefinition } from "@/customTypings/JobFieldDefinition";
import JobFieldSelection from "../_components/JobFieldSelection";
import { IJobTypeGroup } from "@/customTypings/JobTypeGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Documents } from "../_components/Documents";
import { JobStatus } from "../_constants/JobStatus";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { NextActionsModel } from "../_components/NextAcrionsModel";
import { TaskType } from "../_constants/TaskType";
import { CreateTask } from "../_components/Task/CreateTask";
import { ITaskFlow, TaskFlowNextAction } from "../_components/Task/TaskFlow";
import { EditJobScheduleCallModel } from "../_components/EditJobScheduleCallModel";
import { phoneRegExp } from "../_helpers/utils";

const NewEnquiryPage = () => {
    const allSectors: IValueAndLabel[] = [
        { value: "unknown", label: "Not Known" },
        { value: "domestic", label: "Domestic" },
        { value: "commercial", label: "Commercial" },
        { value: "public", label: "Public sector" },
    ];
    const [allCustomerEntries, setAllCustomerEntries] = useState<ICustomerEntry[]>();
    const [initJobFields, setInitJobFields] = useState<IJobField[]>();
    const [jobTypeGroups, setJobTypeGroups] = useState<IJobTypeGroup[]>();
    const [customerId, setCustomerId] = useState("");
    const [customerNumber, setCustomerNumber] = useState<string>();
    const [customerContactId, setCustomerContactId] = useState<string>();
    const [addressId, setAddressId] = useState<string>();
    const [contactId, setContactId] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [jobId, setJobId] = useState("");
    const currentUser = useAtom(userAtom);
    const [showNextActions, setShowNextActions] = useState(false);
    const [showNextActionsTasks, setShowNextActionsTasks] = useState(false);
    const [showNextActionsCallback, setShowNextActionsCallback] = useState(false);
    const [nextActionsTaskTypes, setNextActionsTaskTypes] = useState<TaskType[]>();
    const [actions, setActions] = useState<ITaskFlow[]>([]);

    useEffect(() => {
        setActions(TaskFlowNextAction());
        Promise.all([customerService.getAllEntries(), jobService.getJobFieldDefinitions(), jobtypegroupService.getAllEntities()])
            .then((response) => {
                setAllCustomerEntries(response[0]);

                var jobFieldArray: IJobField[] = [];
                response[1].map((jobFieldDefinition: IJobFieldDefinition) =>
                    jobFieldArray.push({
                        id: undefined,
                        jobId: undefined,
                        jobFieldDefinition: jobFieldDefinition,
                        value: jobFieldDefinition.selectValues ? jobFieldDefinition.selectValues.split(",")[0] : undefined,
                    })
                );
                setInitJobFields(jobFieldArray);

                setJobTypeGroups(response[2]);
                setLoading(false);
            })
            .catch((rejected) => {
                /*setAlertMessage("Could not retrieve list of folders and documents: " + rejected)*/
                setLoading(false);
            });
    }, []);

    const handleNextActionSelection = (action: string) => {
        setShowNextActions(false);
        if (action.length > 0) {
            const tasksToBeCreated = actions.find((item) => item.value === action);
            if (tasksToBeCreated?.closeJob) {
                jobService.disable(jobId);
            } else if (tasksToBeCreated?.scheduleCallback) {
                setShowNextActionsCallback(true);
            } else {
                setNextActionsTaskTypes(tasksToBeCreated?.tasks);
                setShowNextActionsTasks(true);
            }
        }
    };

    if (loading) {
        return (
            <div className="d-flex align-content-center justify-content-center flex-wrap vh-100">
                <Stack className="justify-content-center mb-5" gap={3}>
                    <Image src="/ReactCrmBoilerplateLogo.png" className="mx-auto" style={{ height: "5rem" }} />
                    <Spinner animation={"border"} variant="info" className="mx-auto" />
                </Stack>
            </div>
        );
    }

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Container fluid>
            <Helmet>
                <title>New Enquiry</title>
            </Helmet>
            <>
                <h1 className="my-3">New Enquiry</h1>
                <Formik
                    initialValues={{
                        existingId: "",
                        customerNumber: "New",
                        customerName: "",
                        contactName: "",
                        contactPhone: "",
                        contactEmail: "",
                        contactAddressLine1: "",
                        contactAddressLine2: "",
                        contactAddressLine3: "",
                        contactAddressTown: "",
                        contactAddressCounty: "",
                        contactAddressPostcode: "",
                        contactAddressWhat3Words: "",
                    }}
                    validationSchema={Yup.object().shape({
                        contactName: Yup.string().required("Field is required"),
                        contactPhone: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Field is required"),
                        contactEmail: Yup.string().required("Field is required"),
                        contactAddressLine1: Yup.string().required("Field is required"),
                        contactAddressTown: Yup.string().required("Field is required"),
                        contactAddressCounty: Yup.string().required("Field is required"),
                        contactAddressPostcode: Yup.string().required("Field is required"),
                        contactAddressWhat3Words: Yup.string().required("Field is required"),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        if (values.existingId !== "") {
                            setSubmitting(false);
                        } else {
                            var newAddress: IAddress | undefined;
                            const newCustomer: ICustomer = {
                                id: customerId === "" ? undefined : customerId,
                                isBusiness: values.customerName !== "",
                                isSchemeOrganisation: false,
                                name: values.customerName.length === 0 ? undefined : values.customerName,
                                customerNumber: customerNumber === "" ? undefined : customerNumber,
                            };
                            const newContact: IContact = {
                                id: contactId,
                                name: values.contactName,
                                emailAddress: values.contactEmail,
                                phoneNumber: values.contactPhone,
                            };
                            if (
                                values.contactAddressLine1 !== "" &&
                                values.contactAddressTown !== "" &&
                                values.contactAddressCounty !== "" &&
                                values.contactAddressPostcode !== "" &&
                                values.contactAddressWhat3Words !== ""
                            ) {
                                newAddress = {
                                    id: addressId,
                                    line1: values.contactAddressLine1,
                                    line2: values.contactAddressLine2 === "" ? undefined : values.contactAddressLine2,
                                    line3: values.contactAddressLine3 === "" ? undefined : values.contactAddressLine3,
                                    town: values.contactAddressTown,
                                    county: values.contactAddressCounty,
                                    postcode: values.contactAddressPostcode,
                                    what3words: values.contactAddressWhat3Words,
                                    latitude: undefined,
                                    longitude: undefined,
                                };
                            }
                            const newCustomerContact: ICustomerContact = {
                                id: customerContactId,
                                customer: newCustomer,
                                contact: newContact,
                                address: newAddress,
                                department: undefined,
                                isPrimaryContact: true,
                            };
                            if (customerId === "") {
                                customerService.addFull(newCustomerContact).then(
                                    (response) => {
                                        setSubmitting(false);
                                        if (response.status !== "Failure") {
                                            setCustomerId(response.id);
                                            customerService.getFull(response.id).then((customerEntry) => {
                                                setCustomerId(customerEntry.id == null ? "" : customerEntry.id);
                                                setCustomerNumber(customerEntry.customerNumber == null ? "" : customerEntry.customerNumber);
                                                setCustomerContactId(customerEntry.primaryContact.customerContactId == null ? "" : customerEntry.primaryContact.customerContactId);
                                                setAddressId(customerEntry.primaryContact.address.id == null ? "" : customerEntry.primaryContact.address.id);
                                                setContactId(customerEntry.primaryContact.id == null ? "" : customerEntry.primaryContact.id);
                                            });
                                        } else {
                                            setStatus(response.message);
                                        }
                                    },
                                    (error) => {
                                        setSubmitting(false);
                                        if (error.status === 400) {
                                            setFieldError("customerNumber", error.errors.customerNumber);
                                            setFieldError("customerName", error.errors.Name);
                                            setStatus(error.title);
                                        } else {
                                            setStatus(error);
                                        }
                                    }
                                );
                            } else {
                                customerService.editFull(newCustomerContact).then(
                                    (response) => {
                                        setSubmitting(false);
                                        if (response.status !== "Failure") {
                                            setCustomerId(response.id);
                                        } else {
                                            setStatus(response.message);
                                        }
                                    },
                                    (error) => {
                                        setSubmitting(false);
                                        if (error.status === 400) {
                                            setFieldError("customerNumber", error.errors.customerNumber);
                                            setFieldError("customerName", error.errors.Name);
                                            setStatus(error.title);
                                        } else {
                                            setStatus(error);
                                        }
                                    }
                                );
                            }
                        }
                    }}
                >
                    {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue, handleChange, resetForm }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Stack gap={3}>
                                <h2>Customer</h2>
                                <Form.Group as={Row} controlId="existingCustomerSelection">
                                    <Form.Label column sm={labelSpan}>
                                        Search
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Select
                                            placeholder="Select existing customer..."
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    customerService.getFull(value.value).then((customerEntry) => {
                                                        setCustomerId(customerEntry.id == null ? "" : customerEntry.id);
                                                        setFieldValue("existingId", customerEntry.id == null ? "" : customerEntry.id);
                                                        setFieldValue("customerNumber", customerEntry.customerNumber == null ? "" : customerEntry.customerNumber);
                                                        setFieldValue("customerName", customerEntry.businessName == null ? "" : customerEntry.businessName);
                                                        setFieldValue(
                                                            "contactName",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.name == null ? "" : customerEntry.primaryContact.name
                                                        );
                                                        setFieldValue(
                                                            "contactPhone",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.phoneNumber == null ? "" : customerEntry.primaryContact.phoneNumber
                                                        );
                                                        setFieldValue(
                                                            "contactEmail",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.emailAddress == null ? "" : customerEntry.primaryContact.emailAddress
                                                        );
                                                        setFieldValue(
                                                            "contactAddressLine1",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.address == null || customerEntry.primaryContact.address.line1 == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.line1
                                                        );
                                                        setFieldValue(
                                                            "contactAddressLine2",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.address == null || customerEntry.primaryContact.address.line2 == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.line2
                                                        );
                                                        setFieldValue(
                                                            "contactAddressLine3",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.address == null || customerEntry.primaryContact.address.line3 == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.line3
                                                        );
                                                        setFieldValue(
                                                            "contactAddressTown",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.address == null || customerEntry.primaryContact.address.town == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.town
                                                        );
                                                        setFieldValue(
                                                            "contactAddressCounty",
                                                            customerEntry.primaryContact == null || customerEntry.primaryContact.address == null || customerEntry.primaryContact.address.county == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.county
                                                        );
                                                        setFieldValue(
                                                            "contactAddressPostcode",
                                                            customerEntry.primaryContact == null ||
                                                                customerEntry.primaryContact.address == null ||
                                                                customerEntry.primaryContact.address.postcode == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.postcode
                                                        );
                                                        setFieldValue(
                                                            "contactAddressWhat3Words",
                                                            customerEntry.primaryContact == null ||
                                                                customerEntry.primaryContact.address == null ||
                                                                customerEntry.primaryContact.address.what3words == null
                                                                ? ""
                                                                : customerEntry.primaryContact.address.what3words
                                                        );
                                                    });
                                                } else {
                                                    setCustomerId("");
                                                    setFieldValue("existingId", "");
                                                    setFieldValue("customerNumber", "New");
                                                    setFieldValue("customerName", "");
                                                    setFieldValue("contactName", "");
                                                    setFieldValue("contactPhone", "");
                                                    setFieldValue("contactEmail", "");
                                                    setFieldValue("contactAddressLine1", "");
                                                    setFieldValue("contactAddressLine2", "");
                                                    setFieldValue("contactAddressLine3", "");
                                                    setFieldValue("contactAddressTown", "");
                                                    setFieldValue("contactAddressCounty", "");
                                                    setFieldValue("contactAddressPostcode", "");
                                                    setFieldValue("contactAddressWhat3Words", "");
                                                }
                                                resetForm();
                                            }}
                                            isClearable
                                            options={allCustomerEntries}
                                            getOptionLabel={(option) => `${option.name}: ${option.customerNumber}`}
                                        />
                                        <ErrorMessage name="existingId" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Row>
                                    <Form.Group as={Col} controlId="customerName">
                                        <Row>
                                            <Form.Label column sm={6}>
                                                Business Name
                                            </Form.Label>
                                            <Col sm={6}>
                                                <Field
                                                    name="customerName"
                                                    type="text"
                                                    disabled={values.existingId.length > 0}
                                                    className={"form-control" + (errors.customerName && touched.customerName ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="customerName" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="customerNumber">
                                        <Row>
                                            <Form.Label column sm={6}>
                                                Customer Number
                                            </Form.Label>
                                            <Col sm={6}>
                                                <Field name="customerNumber" type="text" disabled className={"form-control" + (errors.customerNumber && touched.customerNumber ? " is-invalid" : "")} />
                                                <ErrorMessage name="customerNumber" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Row>
                                <Form.Group as={Row} controlId="contactName">
                                    <Form.Label column sm={labelSpan}>
                                        Contact Name
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactName"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactName && touched.contactName ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactName" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactPhone">
                                    <Form.Label column sm={labelSpan}>
                                        Phone
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactPhone"
                                            type="tel"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactPhone && touched.contactPhone ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactPhone" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactEmail">
                                    <Form.Label column sm={labelSpan}>
                                        Email
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactEmail"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactEmail && touched.contactEmail ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactEmail" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressLine1">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 1
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressLine1"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressLine1 && touched.contactAddressLine1 ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressLine1" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressLine2">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 2
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressLine2"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressLine2 && touched.contactAddressLine2 ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressLine2" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressLine3">
                                    <Form.Label column sm={labelSpan}>
                                        Address Line 3
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressLine3"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressLine3 && touched.contactAddressLine3 ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressLine3" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressTown">
                                    <Form.Label column sm={labelSpan}>
                                        Town
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressTown"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressTown && touched.contactAddressTown ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressTown" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressCounty">
                                    <Form.Label column sm={labelSpan}>
                                        County
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Field
                                            name="contactAddressCounty"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressCounty && touched.contactAddressCounty ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressCounty" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressPostcode">
                                    <Form.Label column sm={labelSpan}>
                                        Postcode
                                    </Form.Label>
                                    <Col sm={labelSpan}>
                                        <Field
                                            name="contactAddressPostcode"
                                            type="text"
                                            disabled={values.existingId.length > 0}
                                            className={"form-control" + (errors.contactAddressPostcode && touched.contactAddressPostcode ? " is-invalid" : "")}
                                        />
                                        <ErrorMessage name="contactAddressPostcode" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="contactAddressWhat3Words">
                                    <Form.Label column sm={labelSpan}>
                                        What3Words
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text>{"///"}</InputGroup.Text>
                                            <Field
                                                name="contactAddressWhat3Words"
                                                type="text"
                                                disabled={values.existingId.length > 0}
                                                className={"form-control" + (errors.contactAddressWhat3Words && touched.contactAddressWhat3Words ? " is-invalid" : "")}
                                            />
                                            <Button href={"https://what3words.com/" + values.contactAddressWhat3Words} target="_blank">
                                                <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                                            </Button>
                                            <ErrorMessage
                                                name="contactAddressWhat3Words"
                                                component="div"
                                                className="invalid-feedback"
                                                render={(errorMessage) => <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="ms-auto">
                                            <Button variant="success" disabled={isSubmitting || values.existingId.length > 0} type="submit">
                                                {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                                            </Button>
                                        </div>
                                    </Stack>
                                </Form.Group>
                                {status && <div className={"alert alert-danger"}>{status}</div>}
                            </Stack>
                        </Form>
                    )}
                </Formik>
                <Formik
                    initialValues={{
                        sector: allSectors[0].value,
                        scheme: "",
                        isDiffrentAddress: false,
                        installationAddressLine1: "",
                        installationAddressLine2: "",
                        installationAddressLine3: "",
                        installationAddressTown: "",
                        installationAddressCounty: "",
                        installationAddressPostcode: "",
                        installationAddressWhat3Words: "",
                        isTenant: false,
                        tenantName: "",
                        tenantPhone: "",
                        tenantEmail: "",
                        types: [] as string[],
                        standardColumns: initJobFields as IJobField[],
                        extraColumns: [] as IJobField[],
                        notes: "",
                    }}
                    validationSchema={Yup.object().shape({
                        isDiffrentAddress: Yup.boolean(),
                        installationAddressLine1: Yup.string().when("isDiffrentAddress", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        installationAddressTown: Yup.string().when("isDiffrentAddress", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        installationAddressCounty: Yup.string().when("isDiffrentAddress", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        installationAddressPostcode: Yup.string().when("isDiffrentAddress", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        installationAddressWhat3Words: Yup.string().when("isDiffrentAddress", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        isTenant: Yup.boolean(),
                        tenantName: Yup.string().when("isTenant", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                        tenantPhone: Yup.string().when("isTenant", {
                            is: true,
                            then: Yup.string().matches(phoneRegExp, "Phone number is not valid").required("Field is required"),
                        }),
                        tenantEmail: Yup.string().when("isTenant", {
                            is: true,
                            then: Yup.string().required("Field is required"),
                        }),
                    })}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        console.log(values.types);
                        var newTenant: IContact | undefined;
                        var newAddress: IAddress | undefined;
                        if (values.isTenant) {
                            newTenant = {
                                name: values.tenantName,
                                emailAddress: values.tenantEmail,
                                phoneNumber: values.tenantPhone,
                            };
                        }
                        if (values.isDiffrentAddress) {
                            newAddress = {
                                line1: values.installationAddressLine1,
                                line2: values.installationAddressLine2 === "" ? undefined : values.installationAddressLine2,
                                line3: values.installationAddressLine3 === "" ? undefined : values.installationAddressLine3,
                                town: values.installationAddressTown,
                                county: values.installationAddressCounty,
                                postcode: values.installationAddressPostcode,
                                what3words: values.installationAddressWhat3Words,
                                latitude: undefined,
                                longitude: undefined,
                            };
                        }
                        var jobFieldArray: IJobField[] = [...values.standardColumns, ...values.extraColumns];
                        const newJob: IJobNew = {
                            JobNumber: undefined,
                            Sector: values.sector,
                            scheme: values.scheme,
                            PropertyOwnerId: customerId,
                            PropertyOwnerPreferredContactId: undefined,
                            SchemeOrganizationId: undefined,
                            SchemeOrganisationPreferredContactId: undefined,
                            Scheme: undefined,
                            Occupier: newTenant,
                            Address: newAddress,
                            EnquiryDate: new Date(),
                            InstallationDate: undefined,
                            IsJobTypesLocked: false,
                            IsActive: true,
                            JobFields: jobFieldArray,
                            JobTypeIds: values.types,
                            Status: JobStatus.pendingCallback,
                            IdentityId: currentUser ? currentUser.identity.id : undefined,
                            notes: values.notes,
                        };
                        jobService.addFull(newJob).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    setJobId(response.entity.id);
                                } else {
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setSubmitting(false);
                                if (error.status === 400) {
                                    setFieldError("newPassword", error.errors.newPassword);
                                    setFieldError("confirmPassword", error.errors.ConfirmPassword);
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                            }
                        );
                    }}
                >
                    {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue, handleChange }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Stack gap={3} className="mt-5">
                                <Form.Group as={Row} controlId="sector">
                                    <Form.Label column sm={labelSpan}>
                                        Job Sector
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Select
                                            defaultValue={allSectors[0]}
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("sector", value == null ? "" : value.value)}
                                            options={allSectors}
                                        />
                                        <ErrorMessage name="sector" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.sector === "public" && (
                                    <Form.Group as={Row} controlId="scheme">
                                        <Form.Label column sm={labelSpan}>
                                            Job Scheme
                                        </Form.Label>
                                        <Col sm={fieldSpan}>
                                            <Field
                                                name="scheme"
                                                type="text"
                                                disabled={values.sector !== "public"}
                                                className={"form-control" + (errors.scheme && touched.scheme ? " is-invalid" : "")}
                                            />
                                            <ErrorMessage name="scheme" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                )}
                                <h2>Installation address</h2>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={labelSpan} htmlFor="isDiffrentAddress">
                                        Is installation at different address?
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Form.Check
                                            type="switch"
                                            name="isDiffrentAddress"
                                            className={"mt-1 form-check" + (errors.isDiffrentAddress && touched.isDiffrentAddress ? " is-invalid" : "")}
                                            onChange={(e) => setFieldValue("isDiffrentAddress", e.target.checked == null ? false : e.target.checked)}
                                        />
                                        <ErrorMessage name="isDiffrentAddress" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.isDiffrentAddress && (
                                    <>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine1">
                                                Address 1
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine1"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine1 && touched.installationAddressLine1 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine1" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine2">
                                                Address 2
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine2"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine2 && touched.installationAddressLine2 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine2" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressLine3">
                                                Address 3
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressLine3"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressLine3 && touched.installationAddressLine3 ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressLine3" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressTown">
                                                Town
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressTown"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressTown && touched.installationAddressTown ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressTown" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressCounty">
                                                County
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="installationAddressCounty"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressCounty && touched.installationAddressCounty ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressCounty" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressPostcode">
                                                Postcode
                                            </Form.Label>
                                            <Col sm={labelSpan}>
                                                <Field
                                                    name="installationAddressPostcode"
                                                    type="text"
                                                    disabled={!values.isDiffrentAddress}
                                                    className={"form-control" + (errors.installationAddressPostcode && touched.installationAddressPostcode ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="installationAddressPostcode" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="installationAddressWhat3Words">
                                                What3Words
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <InputGroup hasValidation>
                                                    <InputGroup.Text>{"///"}</InputGroup.Text>
                                                    <Field
                                                        name="installationAddressWhat3Words"
                                                        type="text"
                                                        disabled={!values.isDiffrentAddress}
                                                        className={"form-control" + (errors.installationAddressWhat3Words && touched.installationAddressWhat3Words ? " is-invalid" : "")}
                                                    />
                                                    <Button href={"https://what3words.com/" + values.installationAddressWhat3Words} target="_blank">
                                                        <FontAwesomeIcon icon={faExternalLinkAlt} /> View
                                                    </Button>
                                                    <ErrorMessage
                                                        name="installationAddressWhat3Words"
                                                        component="div"
                                                        className="invalid-feedback"
                                                        render={(errorMessage) => <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                <Form.Group as={Row}>
                                    <Form.Label column sm={labelSpan} htmlFor="isTenant">
                                        Is there a tenant?
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <Form.Check
                                            type="switch"
                                            name="isTenant"
                                            className={"mt-1 form-check" + (errors.isTenant && touched.isTenant ? " is-invalid" : "")}
                                            onChange={(e) => setFieldValue("isTenant", e.target.checked == null ? false : e.target.checked)}
                                        />
                                        <ErrorMessage name="isTenant" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.isTenant && (
                                    <>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="tenantName">
                                                Tenant Name
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantName"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantName && touched.tenantName ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantName" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="tenantPhone">
                                                Tenant Phone
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantPhone"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantPhone && touched.tenantPhone ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantPhone" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={labelSpan} htmlFor="tenantEmail">
                                                Tenant Email
                                            </Form.Label>
                                            <Col sm={fieldSpan}>
                                                <Field
                                                    name="tenantEmail"
                                                    type="text"
                                                    disabled={!values.isTenant}
                                                    className={"form-control" + (errors.tenantEmail && touched.tenantEmail ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="tenantEmail" component="div" className="invalid-feedback" />
                                            </Col>
                                        </Form.Group>
                                    </>
                                )}
                                <h2>Job Details</h2>
                                <Form.Group as={Row}>
                                    <Form.Label column sm={labelSpan} htmlFor="types">
                                        Type of enquiry
                                    </Form.Label>
                                    <Col sm={fieldSpan}>
                                        <div role="group" aria-labelledby="checkbox-group">
                                            {jobTypeGroups &&
                                                jobTypeGroups.map((jobTypeGroup) => (
                                                    <div key={jobTypeGroup.id} className="mt-2 mb-3">
                                                        <h6 key={jobTypeGroup.id} id="checkbox-group">
                                                            {jobTypeGroup.name} enquiry
                                                        </h6>
                                                        {jobTypeGroup.jobTypes &&
                                                            jobTypeGroup.jobTypes.map((jobType) => (
                                                                <label key={jobType.id} className="d-block">
                                                                    <Field type="checkbox" name="types" value={jobType.id} className="me-2" />
                                                                    {jobType.name}
                                                                </label>
                                                            ))}
                                                    </div>
                                                ))}
                                        </div>
                                    </Col>
                                </Form.Group>
                                {values.standardColumns.map((jobField, index) => (
                                    <JobFieldSelection
                                        key={jobField.jobFieldDefinition?.id}
                                        index={index}
                                        jobFieldDefinition={jobField.jobFieldDefinition}
                                        jobFields={values.standardColumns}
                                        setFieldValue={setFieldValue}
                                        fieldValueName="standardColumns"
                                    />
                                ))}
                                <hr />
                                {values.extraColumns.map((jobField, index) => (
                                    <JobFieldSelection
                                        key={jobField.jobFieldDefinition?.id}
                                        index={index}
                                        jobFieldDefinition={jobField.jobFieldDefinition}
                                        jobFields={values.extraColumns}
                                        setFieldValue={setFieldValue}
                                        fieldValueName="extraColumns"
                                    />
                                ))}

                                <h2>Photos and Documents</h2>
                                <Documents jobId={jobId} />

                                <h2>Notes</h2>
                                <Form.Group controlId="jobNotes">
                                    <Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} />
                                </Form.Group>
                                <Form.Group>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="ms-auto">
                                            <Button variant="success" disabled={isSubmitting} type="submit">
                                                {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                                            </Button>
                                        </div>
                                        <Button variant="secondary" disabled={!(jobId && jobId.length)} onClick={() => setShowNextActions(true)}>
                                            Next Actions
                                        </Button>
                                    </Stack>
                                </Form.Group>
                                {status && <div className={"alert alert-danger"}>{status}</div>}
                            </Stack>
                            <FormObserver />
                        </Form>
                    )}
                </Formik>
                <NextActionsModel show={showNextActions} onClose={handleNextActionSelection} actions={actions} />
                <CreateTask show={showNextActionsTasks} onClose={(added) => setShowNextActionsTasks(false)} jobId={jobId} tasktypes={nextActionsTaskTypes} jobStatus={JobStatus.enquiry} />
                <EditJobScheduleCallModel show={showNextActionsCallback} onClose={() => setShowNextActionsCallback(false)} jobId={jobId} />
            </>
        </Container>
    );
};

const FormObserver: React.FC = () => {
    const formik = useFormikContext();
    const values = formik.values as FormikValues;
    useEffect(() => {
        jobService.getExtraJobFieldDefinitions(values.types).then((jobFieldDefinitions) => {
            var jobFieldArray: IJobField[] = [];
            jobFieldDefinitions.map((jobFieldDefinition: IJobFieldDefinition) =>
                jobFieldArray.push({
                    id: undefined,
                    jobId: undefined,
                    jobFieldDefinition: jobFieldDefinition,
                    value: jobFieldDefinition.selectValues ? jobFieldDefinition.selectValues.split(",")[0] : undefined,
                })
            );
            formik.setFieldValue("extraColumns", jobFieldArray);
        });
    }, [values.types]);
    return null;
};

export { NewEnquiryPage };
