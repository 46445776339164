import { IJob } from "@/customTypings/Job";
import { JobCustomerDetails } from "../_components/JobCustomerDetails";
import { Row, Col, Stack, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { jobService, taskService } from "../_services";
import { JobRelatedJobs } from "./JobRelatedJob";
import { Formik } from "formik";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { ITaskNotes } from "../customTypings/TaskNotes";

type Props = {
    job: IJob;
};

const JobDetails: React.FC<Props> = ({ job }) => {
    const [relatedJobIds, setRelatedJobIds] = useState<string[]>();
    const [relatedTaskNotes, setRelatedTaskNotes] = useState<ITaskNotes[]>();

    useEffect(() => {
        jobService.getRelatedJobIds(job.id).then((jobIds) => {
            setRelatedJobIds(jobIds);
        });
        taskService.getTaskNotesForJob(job.id).then((TaskNotes) => {
            setRelatedTaskNotes(TaskNotes);
        });
    }, [job]);

    return (
        <Row>
            <Col md={8}>
                <h2>Details</h2>
                <JobCustomerDetails jobId={job.id} />
                <h2 className="mt-3">Notes</h2>
                <Formik
                    initialValues={{
                        notes: job.notes,
                    }}
                    onSubmit={(
                        values,
                        { setStatus, setSubmitting, setFieldError }
                    ) => {
                        setStatus();
                        const updatedJob: IJob = job;
                        updatedJob.notes = values.notes;
                        jobService.edit(updatedJob).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                } else {
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setSubmitting(false);
                                if (error.status === 400) {
                                    setFieldError("notes", error.errors.notes);
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                            }
                        );
                    }}
                >
                    {({
                        values,
                        errors,
                        status,
                        touched,
                        isSubmitting,
                        handleSubmit,
                        handleChange,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="jobNotes"
                            >
                                <Form.Label column sm={3}>
                                    Job Notes
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control
                                        as="textarea"
                                        name="notes"
                                        value={values.notes ?? ""}
                                        onChange={handleChange}
                                        isValid={touched.notes && !errors.notes}
                                    />
                                </Col>
                            </Form.Group>
                            <Stack
                                direction="horizontal"
                                gap={3}
                                className="mb-3"
                            >
                                <div className="ms-auto">
                                    <Button
                                        variant="success"
                                        disabled={
                                            isSubmitting ||
                                            values.notes === job.notes
                                        }
                                        type="submit"
                                    >
                                        {isSubmitting ? (
                                            <LoadingSpinner text="Saving notes..." />
                                        ) : (
                                            "Save notes"
                                        )}
                                    </Button>
                                </div>
                            </Stack>
                            <Stack gap={3}>
                                {relatedTaskNotes?.map((tasknotes) => {
                                    if (tasknotes.notes !== null) {
                                        return (
                                            <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId={tasknotes.id}
                                                key={tasknotes.id}
                                            >
                                                <Form.Label column sm={3}>
                                                    {tasknotes.name} Notes
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control
                                                        key={tasknotes.id}
                                                        as="textarea"
                                                        name="notes"
                                                        readOnly
                                                        value={tasknotes.notes}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        );
                                    }
                                })}
                            </Stack>
                            {status && (
                                <div className={"alert alert-danger"}>
                                    {status}
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </Col>
            <Col md={4}>
                <>
                    <h2>Related Jobs</h2>
                    <Stack gap={3}>
                        {relatedJobIds && relatedJobIds.length !== 0 ? (
                            relatedJobIds.map((jobId) => {
                                return (
                                    <JobRelatedJobs key={jobId} jobId={jobId} />
                                );
                            })
                        ) : (
                            <div className="border p-2">
                                No related jobs found.
                            </div>
                        )}
                    </Stack>
                </>
            </Col>
        </Row>
    );
};

export { JobDetails };
