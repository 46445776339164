import {
    IColumn,
    ColumnRender,
    ColumnRenderAlignment,
    IIdentity,
} from "./Declarations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faBan } from "@fortawesome/free-solid-svg-icons";
import Image from "react-bootstrap/Image";
import dayjs from "dayjs";
import React from "react";
import { Stack } from "react-bootstrap";

type Props = {
    item: any;
    column: IColumn;
    avatarUrl?: string;
};

const ValueRenderer: React.FC<Props> = ({ item, column, avatarUrl }) => {
    let value = item[column.member];

    if (column.renderAs === ColumnRender.checkCross) {
        return (
            <td className="text-center">
                <FontAwesomeIcon icon={value ? faCheck : faTimes} />
            </td>
        );
    } else if (column.renderAs === ColumnRender.deleted) {
        return (
            <td className="text-center">
                {value === true && (
                    <FontAwesomeIcon icon={faBan} className="text-danger" />
                )}
            </td>
        );
    } else if (column.renderAs === ColumnRender.customRenderer) {
        if (typeof column.customRenderer === "function")
            return column.customRenderer(item);
        return <td></td>;
    } else if (column.renderAs === ColumnRender.identity) {
        const identities = Array.isArray(value)
            ? value
            : value === null
            ? []
            : [].concat(value);
        return (
            <td className="text-left">
                {identities.length === 0 ? (
                    "Unallocated"
                ) : (
                    <Stack gap={3}>
                        {identities.map((identity: IIdentity) => (
                            <React.Fragment key={identity.id}>
                                <div>
                                    <Image
                                        roundedCircle
                                        src={`${avatarUrl}/${identity.id}/${identity.avatarRevision}`}
                                        className="d-inline-block me-2"
                                        style={{ height: "1.5em" }}
                                    />
                                    {identity.displayName}
                                </div>
                            </React.Fragment>
                        ))}
                    </Stack>
                )}
            </td>
        );
    } else if (column.renderAs === ColumnRender.color) {
        return (
            <td>
                <div
                    style={{
                        border: "solid 1px black",
                        backgroundColor: value,
                        height: "1em",
                    }}
                ></div>
            </td>
        );
    } else {
        const align = ColumnRenderAlignment(column.renderAs);

        if (value === null && column.default !== undefined) {
            value = column.default;
        } else if (column.renderAs === ColumnRender.decimal) {
            if (value) value = parseFloat(value).toFixed(2);
        } else if (column.renderAs === ColumnRender.yesNo) {
            value = value ? "Yes" : "No";
        } else if (column.renderAs === ColumnRender.date) {
            if (value) value = dayjs(value).format("DD MMM YYYY");
        } else if (column.renderAs === ColumnRender.time) {
            if (value) value = dayjs(value).format("HH:mm");
        } else if (column.renderAs === ColumnRender.dateTime) {
            if (value) value = dayjs(value).format("DD MMM YYYY HH:mm");
        }
        return (
            <td className={`${align} px-2`}>
                {column.prefix}
                {value}
                {column.suffix}
            </td>
        );
    }
};

export { ValueRenderer };
