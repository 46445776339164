import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddNewTask } from "../_components/AddNewTask";
import { useState } from "react";
import { SummaryCritical, SummaryOK, SummaryOverdue, TaskComplete, TaskDueToday, TaskOpen, TaskOverdue, WaitTime } from "../_components/Badges";
import { useLocation, useNavigate } from "react-router-dom";
import { TaskType } from "../_constants/TaskType";
import { TaskDetailsModel } from "../_components/TaskDetailsModel";
import { TaskStatus } from "../_constants/TaskStatus";

type Props = {
    jobId?: string;
    assignedId?: string;
    isCompleted?: boolean;
    taskTypes?: TaskType[];
};

const TaskListPage: React.FC<Props> = ({ jobId, assignedId, taskTypes, isCompleted }) => {
    var extraPersistanceKey: string = "";
    taskTypes?.map((taskType) => {
        extraPersistanceKey += taskType;
    });

    const navigate = useNavigate();
    let location = useLocation();
    const [showAddTask, setShowAddTask] = useState(false);
    const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [editTaskId, setEditTaskId] = useState<string>();
    const [refreshNumber, setRefeshNumber] = useState(0);
    const columns: IColumn[] = [];
    const filters: IFilter[] = [];
    const headActions: IPagedListAction[] = [];
    var staticFilterValues: Record<string, any> | undefined = undefined;
    const persistanceKey: string =
        "TasksList" + (jobId ? "-Job" : "") + (assignedId ? "-User" : "") + (isCompleted != undefined ? (isCompleted ? "-Completed" : "-Uncompleted") : "") + "-" + extraPersistanceKey;

    const handleAddTask = (added: boolean) => {
        if (added) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowAddTask(false);
    };
    const handleOpenAddTask = () => {
        setShowAddTask(true);
    };
    const handleOpenTaskDetails = (id: string) => {
        setEditTaskId(id);
        setShowTaskDetails(true);
    };

    if (!jobId) {
        columns.push({
            member: "jobNumber",
            renderAs: ColumnRender.text,
            title: "Job Number",
            width: 100,
        } as IColumn);
        columns.push({
            member: "customerNumber",
            renderAs: ColumnRender.text,
            title: "Customer Number",
            width: 100,
        } as IColumn);
        columns.push({
            member: "customerName",
            renderAs: ColumnRender.text,
            title: "Customer",
            width: 100,
        } as IColumn);
    }
    if (taskTypes?.some((item) => item === TaskType.RaisePreSaleInvoice || item === TaskType.ChasePreSaleInvoice || item === TaskType.RaiseInvoice || item === TaskType.ChaseInvoice)) {
        columns.push({
            member: "amount",
            renderAs: ColumnRender.decimal,
            title: "Amount",
            width: 200,
            prefix: "£",
        } as IColumn);
    }
    columns.push({
        member: "name",
        renderAs: ColumnRender.text,
        title: "Task",
        width: 300,
    } as IColumn);
    columns.push({
        member: "taskStatus",
        renderAs: ColumnRender.customRenderer,
        title: "Task Status",
        width: 150,
        customRenderer: (item) => {
            switch (item.taskStatus.toString()) {
                case TaskStatus[TaskStatus.Complete]:
                    return (
                        <td>
                            <TaskComplete />
                        </td>
                    );
                case TaskStatus[TaskStatus.Overdue]:
                    return (
                        <td>
                            <TaskOverdue />
                        </td>
                    );
                case TaskStatus[TaskStatus.DueToday]:
                    return (
                        <td>
                            <TaskDueToday />
                        </td>
                    );
                default:
                    return (
                        <td>
                            <TaskOpen />
                        </td>
                    );
            }
        },
    } as IColumn);
    columns.push({
        member: "dueDate",
        renderAs: ColumnRender.date,
        title: "Due By",
        width: 100,
    } as IColumn);
    if (isCompleted != undefined && isCompleted) {
        columns.push({
            member: "completedDate",
            renderAs: ColumnRender.date,
            title: "Completed Date",
            width: 150,
        } as IColumn);
    }
    if (taskTypes?.some((item) => item === TaskType.DNO)) {
        columns.push({
            member: "actionDate",
            renderAs: ColumnRender.date,
            title: "Request Sent",
            width: 100,
        } as IColumn);
        columns.push({
            member: "actionDateStatus",
            renderAs: ColumnRender.customRenderer,
            title: "Age",
            width: 50,
            customRenderer: (item) => {
                if (!item.actionDate) {
                    return <td></td>;
                }
                var today = new Date();
                var actionDate = new Date(item.actionDate);

                // To calculate the time difference of two dates
                var Difference_In_Time = today.getTime() - actionDate.getTime();

                // To calculate the no. of days between two dates
                var Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
                return (
                    <td>
                        <WaitTime days={Difference_In_Days} />
                    </td>
                );
            },
        } as IColumn);
    }
    if (!assignedId) {
        columns.push({
            member: "assignee",
            renderAs: ColumnRender.identity,
            title: "Assigned To",
            width: 300,
        } as IColumn);
    }
    if (!jobId) {
        columns.push({
            member: "jobNextDueDate",
            renderAs: ColumnRender.customRenderer,
            title: "Overall Job Status",
            width: 100,
            customRenderer: (item) => {
                var today = new Date();
                var taskDate = new Date(item.jobNextDueDate);
                return (
                    <td>
                        {item.jobNextDueDate && taskDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0) ? (
                            <SummaryOK />
                        ) : taskDate.setHours(0, 0, 0, 0) > new Date(today.setDate(today.getDate() - 7)).setHours(0, 0, 0, 0) ? (
                            <SummaryOverdue />
                        ) : (
                            <SummaryCritical />
                        )}
                    </td>
                );
            },
        } as IColumn);
    }

    if (!jobId) {
        filters.push({
            title: "Job Number",
            member: "jobNumber",
            type: FilterType.text,
        } as IFilter);
        filters.push({
            title: "Customer Number",
            member: "customerNumber",
            type: FilterType.text,
        } as IFilter);
        filters.push({
            title: "Customer",
            member: "customerName",
            type: FilterType.text,
        } as IFilter);
    }
    if (taskTypes?.some((item) => item === TaskType.RaisePreSaleInvoice || item === TaskType.ChasePreSaleInvoice || item === TaskType.RaiseInvoice || item === TaskType.ChaseInvoice)) {
        filters.push({
            title: "Minimum Amount",
            member: "minAmount",
            type: FilterType.integer,
        } as IFilter);
        filters.push({
            title: "Maximum Amount",
            member: "maxAmount",
            type: FilterType.integer,
        } as IFilter);
    }
    filters.push({
        title: "Task",
        member: "name",
        type: FilterType.text,
    } as IFilter);
    filters.push({
        title: "Due by from date",
        member: "fromDueDate",
        type: FilterType.date,
    } as IFilter);
    filters.push({
        title: "Due by to date",
        member: "toDueDate",
        type: FilterType.date,
    } as IFilter);
    if (!jobId) {
        filters.push({
            title: "Overall job status from date",
            member: "fromJobNextDueDate",
            type: FilterType.date,
        } as IFilter);
        filters.push({
            title: "Overall job status to date",
            member: "toJobNextDueDate",
            type: FilterType.date,
        } as IFilter);
    }
    if (taskTypes?.some((item) => item === TaskType.DNO)) {
        filters.push({
            title: "Request sent from date",
            member: "fromActionDate",
            type: FilterType.date,
        } as IFilter);
        filters.push({
            title: "Request sent to date",
            member: "toActionDate",
            type: FilterType.date,
        } as IFilter);
    }
    if (isCompleted != undefined && isCompleted) {
        filters.push({
            title: "Completed from date",
            member: "fromCompletedDate",
            type: FilterType.date,
        } as IFilter);
        filters.push({
            title: "Completed to date",
            member: "toCompletedDate",
            type: FilterType.date,
        } as IFilter);
    }

    if (assignedId && jobId) {
        staticFilterValues = {
            assigneeIdentityId: assignedId,
            jobId: jobId,
            taskTypes: taskTypes,
            isCompleted: isCompleted,
        } as Record<string, any>;
    } else if (assignedId) {
        staticFilterValues = {
            assigneeIdentityId: assignedId,
            taskTypes: taskTypes,
            isCompleted: isCompleted,
        } as Record<string, any>;
    } else if (jobId) {
        staticFilterValues = {
            jobId: jobId,
            taskTypes: taskTypes,
            isCompleted: isCompleted,
        } as Record<string, any>;
    } else {
        staticFilterValues = {
            taskTypes: taskTypes,
            isCompleted: isCompleted,
        } as Record<string, any>;
    }

    if (isCompleted == undefined || !isCompleted) {
        headActions.push({
            icon: faPlus,
            text: "Add Entry",
            onClick: handleOpenAddTask,
        } as IPagedListAction);
    }

    return (
        <>
            <PagedList
                columns={columns}
                itemActions={[
                    {
                        text: "Detail",
                        onClick: (item) => {
                            var today = new Date();
                            var taskCompletionDate = new Date(item.completedDate);
                            if (item.completedDate && taskCompletionDate < today) {
                                handleOpenTaskDetails(item.id);
                            } else {
                                navigate(`/tasks/${item.id}`, {
                                    state: { from: location },
                                });
                            }
                        },
                        primary: true,
                    } as IPagedListAction,
                ]}
                headActions={headActions}
                staticFilterValues={staticFilterValues}
                filters={filters}
                defaultSortBy="dueDate"
                getUrl="api/task/all"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey={persistanceKey}
                refreshNumber={refreshNumber}
            />
            <AddNewTask show={showAddTask} onClose={handleAddTask} assignedId={assignedId} jobId={jobId} />
            <TaskDetailsModel show={showTaskDetails} onClose={setShowTaskDetails} taskId={editTaskId} />
        </>
    );
};

export { TaskListPage };
