import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from 'react-helmet';
import { EventCalendar } from "../_components/EventCalendar/EventCalendar";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";

const MyCalendarPage = () => {
    const currentUser = useAtom(userAtom)
    const filters = [(currentUser ? currentUser?.identity.id : "")]

    return (
        <Container fluid>
            <Helmet>
                <title>My Calendar</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">My Calendar</h1>
                </Col>
            </Row>
            <EventCalendar identityIds={filters} newEventAllowed={true} updateEventAllowed={true} />
        </Container>
    )
}

export { MyCalendarPage }; 