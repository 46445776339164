import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { JobType, SummaryCritical, SummaryOK, SummaryOverdue } from "../_components/Badges";

const EnquiryListPage = () => {
    const navigate = useNavigate();

    return (
        <Container fluid>
            <Helmet>
                <title>Enquiries</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Enquiries</h1>
                    <PagedList
                        columns={[
                            {
                                member: "customerNumber",
                                renderAs: ColumnRender.text,
                                title: "Customer Number",
                                width: 100,
                            } as IColumn,
                            {
                                member: "jobTypes",
                                renderAs: ColumnRender.customRenderer,
                                title: "Type",
                                width: 200,
                                customRenderer: (item) => {
                                    return (
                                        <td>
                                            <Stack gap={2}>{item.jobTypes && item.jobTypes.map((type: string) => type && <JobType key={type} text={type} />)}</Stack>
                                        </td>
                                    );
                                },
                                canSort: false,
                            } as IColumn,
                            {
                                member: "customerName",
                                renderAs: ColumnRender.text,
                                title: "Customer",
                                width: 100,
                            } as IColumn,
                            {
                                member: "address",
                                renderAs: ColumnRender.text,
                                title: "Address",
                                width: 400,
                            } as IColumn,
                            {
                                member: "taskDueDate",
                                renderAs: ColumnRender.customRenderer,
                                title: "Task Summary",
                                width: 100,
                                customRenderer: (item) => {
                                    var today = new Date();
                                    var taskDate = new Date(item.taskDueDate);
                                    return (
                                        <td>
                                            {item.taskDueDate && taskDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0) ? (
                                                <SummaryOK />
                                            ) : taskDate.setHours(0, 0, 0, 0) > new Date(today.setDate(today.getDate() - 7)).setHours(0, 0, 0, 0) ? (
                                                <SummaryOverdue />
                                            ) : (
                                                <SummaryCritical />
                                            )}
                                        </td>
                                    );
                                },
                            } as IColumn,
                            {
                                member: "isActive",
                                renderAs: ColumnRender.checkCross,
                                title: "Active",
                                width: 100,
                            } as IColumn,
                        ]}
                        itemActions={[
                            {
                                text: "Detail",
                                onClick: (item) => {
                                    navigate(`/enquiries/${item.id}`);
                                },
                                primary: true,
                            } as IPagedListAction,
                        ]}
                        headActions={[
                            {
                                icon: faPlus,
                                text: "New Enquiry",
                                onClick: () => {
                                    navigate(`/newenquiry`);
                                },
                            } as IPagedListAction,
                        ]}
                        filters={[
                            {
                                title: "Customer number",
                                member: "customerNumber",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Customer name",
                                member: "customerName",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Address",
                                member: "address",
                                type: FilterType.text,
                            } as IFilter,
                            {
                                title: "Task due date",
                                member: "taskDueDate",
                                type: FilterType.date,
                            } as IFilter,
                            {
                                title: "Active",
                                member: "isActive",
                                type: FilterType.select,
                                options: { Yes: true, No: false, All: null },
                                defaultValue: true,
                            } as IFilter,
                        ]}
                        defaultSortBy="taskDueDate"
                        getUrl="api/job/allenquiries"
                        avatarUrl="api/avatar/get"
                        rowFormatter={(item: any) => {
                            return null;
                        }}
                        persistanceKey="EnquiryListPage"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export { EnquiryListPage };
