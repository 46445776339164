import { useParams } from "react-router-dom";

import { customerService } from "../_services";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { ICustomerFull } from "@/customTypings/CustomerFull";
import { JobListDetailsListPage } from "../JobListDetails/JobListDetailsListPage";
import { IContactFull } from "@/customTypings/ContactFull";
import { CustomerContactDetails } from "./CustomerContactDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { EditCustomerModel } from "../_components/EditCustomerModel";
import { AddCustomerContactModel } from "../_components/AddCustomerContactModel";

const CustomerDashboard = () => {
    let params = useParams();

    const [customer, setCustomer] = useState<ICustomerFull>();
    const [customerContacts, setCustomerContacts] = useState<IContactFull[]>();
    const [showEditCustomer, setShowEditCustomer] = useState(false);
    const [showAddContact, setShowAddContact] = useState(false);

    useEffect(() => {
        getCustomer();
    }, [params.id]);

    useEffect(() => {
        if (customer && params.id) {
            customerService.getAllContacts(params.id).then((customerContacts) => {
                setCustomerContacts(customerContacts);
            });
        }
    }, [customer]);

    function getCustomer() {
        if (params.id) {
            customerService.getFull(params.id).then((customer) => {
                setCustomer(customer);
            });
        }
    }

    return (
        <Container fluid>
            <Helmet>
                <title>Customer Dashboard</title>
            </Helmet>
            {customer && (
                <>
                    <h1 className="my-3">
                        {customer.name ? `${customer.name}` : "N/A"} <span className="text-primary">{customer.customerNumber ? `Number ${customer.customerNumber}` : "N/A"}</span>{" "}
                        <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="onclick-hover"
                            onClick={() => {
                                setShowEditCustomer(true);
                            }}
                        />
                    </h1>

                    <Stack direction="horizontal" gap={3}>
                        <h3 className="my-3">Contacts</h3>
                        <Button variant="secondary" className="ms-auto" onClick={() => setShowAddContact(true)}>
                            <FontAwesomeIcon fixedWidth={true} icon={faPlus} className="me-2" />
                            Add contact
                        </Button>
                    </Stack>
                    <Row xs={1} md={1} lg={2} xl={3} className="g-3">
                        {customerContacts?.map((contact) => (
                            <Col key={contact.customerContactId}>
                                <CustomerContactDetails
                                    key={contact.customerContactId}
                                    customerId={customer.id ?? ""}
                                    contact={contact}
                                    isBusiness={customer.isBusiness}
                                    onUpdate={() => getCustomer()}
                                />
                            </Col>
                        ))}
                    </Row>
                    <h3 className="my-3">Jobs</h3>
                    <JobListDetailsListPage customerId={customer.id} pageSize={3} />
                    <EditCustomerModel
                        show={showEditCustomer}
                        customerId={customer.id ?? ""}
                        onClose={() => {
                            getCustomer();
                            setShowEditCustomer(false);
                        }}
                    />
                    <AddCustomerContactModel
                        show={showAddContact}
                        customerId={customer.id ?? ""}
                        onClose={() => {
                            getCustomer();
                            setShowAddContact(false);
                        }}
                    />
                </>
            )}
        </Container>
    );
};

export { CustomerDashboard };
