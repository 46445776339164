import React, { useState } from "react";
import { authenticationService } from "../_services";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";

type Props = {
    show: boolean;
    errorMessage: string;
    onClose: (loggedOut: boolean) => void;
};

const LogoutModel: React.FC<Props> = ({ show, errorMessage, onClose }) => {
    const [alertText, setAlertText] = useState<string>();
    const [loading, setLoading] = useState(false);

    const handleClose = () => onClose(false);

    function logout() {
        setAlertText(undefined);
        setLoading(true);
        authenticationService.logout().then(
            () => {
                onClose(true);
            },
            (error) => {
                setAlertText(error);
                setLoading(false);
            }
        );
    }

    return (
        <Modal centered show={show} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Sign Out?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={3}>
                    {errorMessage}

                    {alertText && (
                        <Alert variant={"danger"} className="mt-3">
                            {alertText}
                        </Alert>
                    )}
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    disabled={loading}
                >
                    Close
                </Button>
                <Button variant="primary" onClick={logout} disabled={loading}>
                    {loading ? (
                        <LoadingSpinner text="Signing out..." />
                    ) : (
                        "Sign Out"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { LogoutModel };
