import { JobCommunicationListPage } from "../JobCommunication/JobCommunicationListPage";

type Props = {
    jobId?: string;
};

const JobCommunication: React.FC<Props> = ({ jobId }) => {
    return <JobCommunicationListPage jobId={jobId} />;
};

export { JobCommunication };
