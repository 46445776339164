import { IEmailTemplate } from '@/customTypings/EmailTemplate';
import { authHeader, handleResponse } from '../_helpers';

export const emailTemplateService = {
    add,
    remove,
    restore,
    getPlaceholders,
    get,
    edit,
    getAll,
    getAllActive,
};

function add(emailTemplate: IEmailTemplate) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(emailTemplate)
    };
    return fetch(`api/emailtemplate/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function remove(emailTemplateId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/emailtemplate/delete?id=${emailTemplateId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function restore(emailTemplateId: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/emailtemplate/restore?id=${emailTemplateId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getPlaceholders() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/emailtemplate/getplaceholders`, requestOptions).then(handleResponse);
}

function get(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/emailtemplate/get?id=${id}`, requestOptions).then(handleResponse);
}

function edit(emailTemplate: IEmailTemplate){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(emailTemplate)
    };
    return fetch(`api/emailtemplate/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getAll() {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/emailtemplate/getall`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getAllActive(proposalId: string) {
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/emailtemplate/getallactive?proposalId=${proposalId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}