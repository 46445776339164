import React, { useEffect, useState } from "react";
import { IIdentityAvatar } from "@/customTypings/IdentityAvatar";
import { Avatar } from "./Avatar";
import { userService } from "../_services";
import { AvatarSkeleton } from "./AvatarSkeleton";

type Props = {
    userId: string;
};

const User: React.FC<Props> = ({ userId }) => {
    const [user, setUser] = useState<IIdentityAvatar>();

    useEffect(() => {
        userService
            .getAvatarById(userId)
            .then((identityAvatar: IIdentityAvatar) => {
                setUser(identityAvatar);
            });
    }, [userId]);

    return <div>{user ? <Avatar identity={user} /> : <AvatarSkeleton />}</div>;
};

export { User };
