import { useParams } from "react-router-dom";

import { IJob } from "@/customTypings/Job";
import { jobService } from "../_services";
import { useEffect, useState } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { JobDetails } from "./JobDetails";
import { Documents } from "../_components/Documents";
import { JobTasks } from "./JobTasks";
import { JobTasksStatus } from "../_constants/JobTasksStatus";
import { SummaryCritical, SummaryNoTasks, SummaryOK, SummaryOverdue } from "../_components/Badges";
import { JobCommunication } from "./JobCommunication";

const JobDashboard = () => {
    let params = useParams();

    const [job, setJob] = useState<IJob>();

    useEffect(() => {
        if (params.id) {
            jobService.get(params.id).then((job) => {
                setJob(job);
            });
        }
    }, [params.id]);

    return (
        <Container fluid>
            <Helmet>
                <title>Job Dashboard</title>
            </Helmet>
            {job && (
                <>
                    <h1 className="my-3">
                        Job Dashboard <span className="text-primary">{job?.jobNumber ? `Number ${job?.jobNumber}` : "Enquiry"}</span>{" "}
                        {job.tasksStatus ? (
                            job.tasksStatus.toString() === JobTasksStatus[JobTasksStatus.Critical] ? (
                                <SummaryCritical />
                            ) : job.tasksStatus.toString() === JobTasksStatus[JobTasksStatus.Overdue] ? (
                                <SummaryOverdue />
                            ) : job.tasksStatus.toString() === JobTasksStatus[JobTasksStatus.Ok] ? (
                                <SummaryOK />
                            ) : (
                                <SummaryNoTasks />
                            )
                        ) : (
                            <SummaryNoTasks />
                        )}
                    </h1>
                    <Tabs defaultActiveKey="details" className="mb-3" mountOnEnter>
                        <Tab eventKey="details" title="Details">
                            <JobDetails job={job} />
                        </Tab>
                        <Tab eventKey="communication" title="Communication History">
                            <JobCommunication jobId={job?.id} />
                        </Tab>
                        <Tab eventKey="documents" title="Documents">
                            <Documents jobId={job?.id} />
                        </Tab>
                        <Tab eventKey="tasks" title="Tasks">
                            <JobTasks jobId={job?.id} isCompleted={false} />
                        </Tab>
                        <Tab eventKey="completetasks" title="Complete Tasks">
                            <JobTasks jobId={job?.id} isCompleted={true} />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Container>
    );
};

export { JobDashboard };
