import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useSearchParams, useNavigate } from "react-router-dom";

import { Role } from "./_helpers";
import PrivateRoute from "./_components/PrivateRoute";
import { HeaderBar } from "./_components";
import { NavigationBar } from "./_components";
import CookieConsent from "react-cookie-consent";

import { AdminPage } from "./AdminPage/AdminPage";
import { SignInPage } from "./SignInPage/SignInPage";
import { AcceptInvitationPage } from "./AcceptInvitationPage";
import { InvitationsPage } from "./InvitationsPage/InvitationsPage";
import { UsersPage } from "./UsersPage/UsersPage";
import { UserPage } from "./UserPage/UserPage";
import { ResetPasswordPage } from "./ResetPasswordPage/ResetPasswordPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage/ForgotPasswordPage";
import { OrganisationsPage } from "./OrganisationsPage/OrganisationsPage";
import { JobsPage } from "./JobsPage/JobsPage";
import { AccountsPage } from "./AccountsPage/AccountsPage";
import { DocumentLibraryPage } from "./DocumentLibraryPage/DocumentLibraryPage";
import { SettingsPage } from "./SettingsPage/SettingsPage";
import { updateUserStateAtomToLocalStorage, userAtom } from "./_atoms/userAtom";
import AccessDeniedPage from "./AccessDeniedPage/AccessDeniedPage";
import { useAtom } from "@dbeining/react-atom";
import { accessDeniedAtom } from "./_atoms/accessDeniedAtom";
import { TwoFASetupPage } from "./TwoFASetupPage/TwoFASetupPage";
import { Container, Spinner, Stack, Image, Col, Row } from "react-bootstrap";
import { SessionsPage } from "./SessionsPage/SessionsPage";
import { CustomerListPage } from "./Customer/CustomerListPage";
import { ComponentDemoPage } from "./ComponentDemo/ComponentDemoPage";
import { authenticationService } from "./_services/authentication.service";
import "./_styles/App.css";
import { NewEnquiryPage } from "./NewEnquiryPage/NewEnquiryPage";
import { EnquiryListPage } from "./Enquiry/EnquiryListPage";
import { EnquiryPage } from "./EnquiryPage/EnquiryPage";
import { JobDashboard } from "./JobDashboard/JobDashboard";
import { MyTasksPage } from "./MyTasksPage/MyTasksPage";
import { AllTasksPage } from "./AllTasksPage/AllTasksPage";
import UnderConstructionPage from "./UnderConstructionPage/UnderConstructionPage";
import { MyPendingCallbacksPage } from "./MyPendingCallbacksPage/MyPendingCallbacksPage";
import { AllPendingCallbacksPage } from "./AllPendingCallbacks/AllPendingCallbacksPage";
import { CalendarPage } from "./CalendarPage/CalendarPage";
import { MyCalendarPage } from "./MyCalendarPage/MyCalendarPage";
import { TaskPage } from "./TaskPage/TaskPage";
import JobFolderSettingsPage from "./JobFolderSettingsPage/JobFolderSettingsPage";
import ChecklistSettingsPage from "./ChecklistSettingsPage/ChecklistSettingsPage";
import { DesignPage } from "./DesignPage/DesignPage";
import { DNOPage } from "./DNOPage/DNOPage";
import { InstallationPage } from "./InstallationPage/InstallationPage";
import { CustomerDashboard } from "./CustomerDashboard/CustomerDashboard";
import { TimesheetPage } from "./TimesheetPage/TimesheetPage";
import { MyTimesheet } from "./MyTimesheet/MyTimesheet";
import { useViewport } from "./_contexts/ViewportContext";
import { EmailTemplateListPage } from "./EmailTemplate/EmailTemplateListPage";
import EmailTemplateEditPage from "./EmailTemplate/EmailTemplateEditPage";
import EmailTemplateAddPage from "./EmailTemplate/EmailTemplateAddPage";

const App = () => {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const id: string = searchParams.get("id") || "";
    const currentActionAccessDenied = useAtom(accessDeniedAtom);
    const [loadingUser, setLoadingUser] = useState(true);
    const currentUser = useAtom(userAtom);

    const { width } = useViewport();
    const breakpoint = 768;

    useEffect(() => {
        updateUserStateAtomToLocalStorage();

        authenticationService.checkAuthorised().then(() => {
            setLoadingUser(false);
        });
    }, []);

    useEffect(() => {
        if (currentActionAccessDenied) {
            navigate("/accessdenied", { replace: true });
        }
    }, [currentActionAccessDenied]);

    if (loadingUser) {
        return (
            <div className="d-flex align-content-center justify-content-center flex-wrap vh-100">
                <Stack className="justify-content-center mb-5" gap={3}>
                    <Image src="/ReactCrmBoilerplateLogo.png" className="mx-auto" style={{ height: "5rem" }} />
                    <Spinner animation={"border"} variant="info" className="mx-auto" />
                </Stack>
            </div>
        );
    }

    const contentMaxWidth = currentUser && width >= breakpoint ? "calc(100vw - 15rem)" : "100vw";

    return (
        <>
            <div className="d-flex flex-nowrap" style={{ minHeight: "100vh" }}>
                {currentUser && width >= breakpoint && (
                    <div className="d-flex flex-column" style={{ width: "15rem" }}>
                        <NavigationBar />
                    </div>
                )}
                <div className="flex-column flex-grow-1" style={{ maxWidth: contentMaxWidth }}>
                    <HeaderBar />
                    <Container fluid className="py-3">
                        <Routes>
                            <Route path="/" element={<Navigate to="/mytasks" />} />
                            <Route
                                path="/newenquiry"
                                element={
                                    <PrivateRoute roles={[Role.JobEntry]}>
                                        <NewEnquiryPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mytasks"
                                element={
                                    <PrivateRoute>
                                        <MyTasksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mypendingcallbacks"
                                element={
                                    <PrivateRoute>
                                        <MyPendingCallbacksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mycalendar"
                                element={
                                    <PrivateRoute>
                                        <MyCalendarPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/mytimesheet"
                                element={
                                    <PrivateRoute>
                                        <MyTimesheet />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/pendingcallbacks"
                                element={
                                    <PrivateRoute>
                                        <AllPendingCallbacksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/pendingcallbacks/:jobId"
                                element={
                                    <PrivateRoute>
                                        <EnquiryPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/enquiries"
                                element={
                                    <PrivateRoute>
                                        <EnquiryListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/enquiries/:id"
                                element={
                                    <PrivateRoute>
                                        <JobDashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/design"
                                element={
                                    <PrivateRoute roles={[Role.Design, Role.DesignManager]}>
                                        <DesignPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/dno"
                                element={
                                    <PrivateRoute>
                                        <DNOPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/installation"
                                element={
                                    <PrivateRoute>
                                        <InstallationPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/timesheet"
                                element={
                                    <PrivateRoute>
                                        <TimesheetPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/accounts"
                                element={
                                    <PrivateRoute roles={[Role.Accounts]}>
                                        <AccountsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/customers"
                                element={
                                    <PrivateRoute>
                                        <CustomerListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/customers/:id"
                                element={
                                    <PrivateRoute>
                                        <CustomerDashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/jobs"
                                element={
                                    <PrivateRoute roles={[Role.JobEntry]}>
                                        <JobsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/jobs/:id"
                                element={
                                    <PrivateRoute>
                                        <JobDashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tasks"
                                element={
                                    <PrivateRoute>
                                        <AllTasksPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tasks/:id"
                                element={
                                    <PrivateRoute>
                                        <TaskPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/calendar"
                                element={
                                    <PrivateRoute>
                                        <CalendarPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/documentlibrary" element={<DocumentLibraryPage />} />
                            <Route
                                path="/export"
                                element={
                                    <PrivateRoute>
                                        <UnderConstructionPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/settings"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.UserAdministrator]}>
                                        <SettingsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/admin"
                                element={
                                    <PrivateRoute roles={[Role.Admin, Role.SystemAdmin]}>
                                        <AdminPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users"
                                element={
                                    <PrivateRoute roles={[Role.UserAdministrator]}>
                                        <UsersPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/users/:userId"
                                element={
                                    <PrivateRoute roles={[Role.UserAdministrator]}>
                                        <UserPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/JobFolders"
                                element={
                                    <PrivateRoute>
                                        <JobFolderSettingsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/Checklists"
                                element={
                                    <PrivateRoute>
                                        <ChecklistSettingsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateListPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates/add"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateAddPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/EmailTemplates/:id"
                                element={
                                    <PrivateRoute>
                                        <EmailTemplateEditPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/invitations"
                                element={
                                    <PrivateRoute>
                                        <InvitationsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/organisations"
                                element={
                                    <PrivateRoute>
                                        <OrganisationsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/twofactorSetup"
                                element={
                                    <PrivateRoute>
                                        <TwoFASetupPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/sessions"
                                element={
                                    <PrivateRoute>
                                        <SessionsPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route path="/signin" element={<SignInPage />} />
                            <Route path="/acceptinvitation" element={<AcceptInvitationPage id={id} />} />
                            <Route path="/resetpassword" element={<ResetPasswordPage id={id} />} />
                            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                            <Route path="/accessdenied" element={<AccessDeniedPage />} />
                            <Route path="/componentdemo" element={<ComponentDemoPage />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Container>
                </div>
            </div>
            <CookieConsent location="bottom" buttonText="Accept" cookieName="reactCrmBoilerplateCookieConsent" style={{ zIndex: 2000 }}>
                <h3>This website uses cookies</h3>
                Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.
            </CookieConsent>
        </>
    );
};

export { App };
