import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Alert, Button, Modal, Stack, Form, Col, Row, Placeholder } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { timesheetService } from "../_services/timesheet.service";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { jobService, userService } from "../_services";
import { allTimesheetTypes, TimesheetType } from "../_constants/TimesheetType";
import { ITimesheet } from "@/customTypings/Timesheet";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    timesheetId: string;
};

const EditTimesheetModel: React.FC<Props> = ({ show, onClose, timesheetId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [currentTimesheet, setCurrentTimesheet] = useState<ITimesheet>();
    const [loading, setLoading] = useState(true);
    const [allJobs, setAllJobs] = useState<IValueAndLabel[]>();
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        if (show) {
            setLoading(true);
            Promise.all([timesheetService.get(timesheetId), jobService.getAllListItems(), userService.getAllListItems()]).then((response) => {
                setCurrentTimesheet(response[0]);
                setAllJobs(response[1]);
                setAllIdentities(response[2]);
                setLoading(false);
            });
        }
    }, [timesheetId, show]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            {loading || !currentTimesheet || !allJobs || !allIdentities ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Timesheet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={labelSpan}>User</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Type</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Job</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Start Date</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>End Date</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={3} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={2} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                        selectedIdentityId: currentTimesheet?.identityId ?? "",
                        selectedJobId: currentTimesheet?.jobId ?? "",
                        type: currentTimesheet?.type ?? TimesheetType.Travel,
                        showSelectedJobId: (currentTimesheet?.type ?? TimesheetType.Travel).toString() == TimesheetType[TimesheetType.Work],
                        startDate: currentTimesheet?.startDate ?? undefined,
                        endDate: currentTimesheet?.endDate ?? undefined,
                    }}
                    onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                        setStatus();
                        setSubmitting(false);
                        const updatedTimesheet: ITimesheet = {
                            id: currentTimesheet?.id ?? "",
                            identityId: values.selectedIdentityId,
                            jobId: values.showSelectedJobId ? values.selectedJobId : undefined,
                            type: values.type,
                            startDate: values.startDate?.toLocaleString() === "" || values.startDate === undefined ? undefined : values.startDate,
                            endDate: values.endDate?.toLocaleString() === "" || values.endDate === undefined ? undefined : values.endDate,
                        };
                        timesheetService.edit(updatedTimesheet).then(
                            (response) => {
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                    setFieldError("name", error.errors.Name);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Timesheet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group as={Row} className="mb-3" controlId="identityInput">
                                    <Form.Label column sm={3}>
                                        User
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("assignee", value == null ? "" : value.value)}
                                            defaultValue={allIdentities.find((obj) => {
                                                return obj.value === values.selectedIdentityId;
                                            })}
                                            options={allIdentities}
                                        />
                                        <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="typeInput">
                                    <Form.Label column sm={3}>
                                        Type
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className={"stateManagedSelect" + (errors.type && touched.type ? " is-invalid" : "")}
                                            onChange={(value) => {
                                                setFieldValue("type", value == null ? "" : value.value);
                                                setFieldValue("showSelectedJobId", TimesheetType[value.value] == TimesheetType[TimesheetType.Work]);
                                            }}
                                            defaultValue={allTimesheetTypes.find((obj) => {
                                                return obj.value.localeCompare(TimesheetType[values.type]) === 0;
                                            })}
                                            options={allTimesheetTypes.sort((a, b) => a.label.localeCompare(b.label))}
                                        />
                                        <ErrorMessage name="type" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {values.showSelectedJobId && (
                                    <Form.Group as={Row} className="mb-3" controlId="jobInput">
                                        <Form.Label column sm={3}>
                                            Job
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Select
                                                styles={ReactSelectBootstrapStyle}
                                                className={"stateManagedSelect" + (errors.selectedJobId && touched.selectedJobId ? " is-invalid" : "")}
                                                onChange={(value) => setFieldValue("selectedJobId", value == null ? "" : value.value)}
                                                defaultValue={allJobs.find((obj) => {
                                                    return obj.value === values.selectedJobId;
                                                })}
                                                isClearable
                                                options={allJobs}
                                            />
                                            <ErrorMessage name="selectedJobId" component="div" className="invalid-feedback" />
                                        </Col>
                                    </Form.Group>
                                )}
                                <Form.Group as={Row} className="mb-3" controlId="startDateInput">
                                    <Form.Label column sm={3}>
                                        Start Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field name="startDate" type="datetime-local" className={"form-control" + (errors.startDate && touched.startDate ? " is-invalid" : "")} />
                                        <ErrorMessage name="startDate" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="endDateInput">
                                    <Form.Label column sm={3}>
                                        End Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field name="endDate" type="datetime-local" className={"form-control" + (errors.endDate && touched.endDate ? " is-invalid" : "")} />
                                        <ErrorMessage name="endDate" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                                {status && (
                                    <Alert variant={alertVariant} className="mt-3">
                                        {status}
                                    </Alert>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                        {isSubmitting ? <LoadingSpinner text="Updating timesheet..." /> : "Update timesheet"}
                                    </Button>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { EditTimesheetModel };
