import { IFolderDefinitionFolder } from '@/customTypings/FolderDefinitionFolder';
import { authHeader, handleResponse } from '../_helpers';

export const folderDefinitionService = {
    getByType,
    getFull,
    addFull,
    add
};

function getByType(type: string) {
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/folderdefinition/getbytype/${type}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getFull(id: string) {
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/folderdefinition/getfull/${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addFull(folderTreeFolder: IFolderDefinitionFolder) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(folderTreeFolder)
    };
    return fetch(`api/folderdefinition/addfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function add(folderTreeFolder: IFolderDefinitionFolder) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(folderTreeFolder)
    };
    return fetch(`api/folderdefinition/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}