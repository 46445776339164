import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { jobCommunicationService } from "../_services";
import ReactQuill from "react-quill";
import { IJobCommunication } from "@/customTypings/jobCommunication";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import { IJobCommunicationRecipient } from "@/customTypings/JobCommunicationRecipient";

type Props = {
    show: boolean;
    onClose: () => void;
    jobCommunicationId: string | undefined;
};

const JobCommunicationDetailsModel: React.FC<Props> = ({ show, onClose, jobCommunicationId }) => {
    const [jobCommunication, setJobCommunication] = useState<IJobCommunication>();
    const [jobCommunicationDocuments, setJobCommunicationDocuments] = useState<string>();
    const [jobCommunicationRecipients, setJobCommunicationRecipients] = useState<string>();
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        onClose();
        setJobCommunication(undefined);
        setJobCommunicationDocuments(undefined);
    };

    useEffect(() => {
        if (show && jobCommunicationId) {
            setLoading(true);
            Promise.all([
                jobCommunicationService.get(jobCommunicationId),
                jobCommunicationService.getAttachmentNames(jobCommunicationId),
                jobCommunicationService.getRecipients(jobCommunicationId),
            ]).then((response) => {
                setJobCommunication(response[0]);
                var docs = "";
                response[1].map((doc: IValueAndLabel) => {
                    if (docs.length > 0) {
                        docs += ", " + doc.label;
                    } else {
                        docs += doc.label;
                    }
                });
                setJobCommunicationDocuments(docs);
                var recipients = "";
                response[2].results.map((recipient: IJobCommunicationRecipient) => {
                    if (recipients.length > 0) {
                        recipients += ", " + recipient.to;
                    } else {
                        recipients += recipient.to;
                    }
                });
                setJobCommunicationRecipients(recipients);
                setLoading(false);
            });
        } else {
            setJobCommunication(undefined);
            setJobCommunicationDocuments(undefined);
            setJobCommunicationRecipients(undefined);
        }
    }, [show, jobCommunicationId]);

    return !loading && jobCommunication ? (
        <Modal centered show={show} keyboard={false} onHide={() => handleClose()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                        Sent by
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control value={jobCommunication.from} readOnly />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                        Sent To
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control value={jobCommunicationRecipients} readOnly />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                        Subject
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control readOnly value={jobCommunication.subject} />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                        Attachments
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control value={jobCommunicationDocuments} readOnly />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={3}>
                        body
                    </Form.Label>
                    <Col sm={9}>
                        <ReactQuill value={jobCommunication.body} readOnly modules={{ toolbar: false }} />
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    ) : (
        <></>
    );
};

export { JobCommunicationDetailsModel };
