import { IJob } from "@/customTypings/Job";
import { JobCustomerDetails } from "../_components/JobCustomerDetails";
import { useEffect, useState } from "react";
import { jobService } from "../_services";
import Skeleton from "react-loading-skeleton";
import { JobComplete, JobCancelled, JobOpen } from "../_components/Badges";
import { JobStatus } from "../_constants/JobStatus";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";

type Props = {
    jobId: string;
};

const JobRelatedJobs: React.FC<Props> = ({ jobId }) => {
    const [job, setJob] = useState<IJob>();

    useEffect(() => {
        jobService.get(jobId).then((jobResult) => setJob(jobResult));
    }, [jobId]);

    return (
        <Card className="bg-light shadow">
            <Card.Body>
                {job ? (
                    <>
                        <h5 className="my-3">
                            <NavLink to={"/jobs/" + job.id}>
                                <span className="text-primary">
                                    {job.jobNumber
                                        ? `Job Number ${job.jobNumber}`
                                        : "Enquiry"}
                                </span>
                            </NavLink>{" "}
                            {job.status.toString() ===
                            JobStatus[JobStatus.complete] ? (
                                <JobComplete />
                            ) : job.isActive === false ? (
                                <JobCancelled />
                            ) : (
                                <JobOpen />
                            )}
                        </h5>
                        <JobCustomerDetails jobId={job.id} />
                    </>
                ) : (
                    <Skeleton />
                )}
            </Card.Body>
        </Card>
    );
};

export { JobRelatedJobs };
