export const Role = {
    SystemAdmin: 'System Administrator',
    Admin: 'Administrator',
    JobEntry: 'Job entry',
    Surveyor: 'Surveyor',
    Design: 'Design',
    DesignManager: 'Design Manager',
    DNO: 'DNO',
    ProjectManager: 'Project Manager',
    LeadEngineer: 'Lead Engineer',
    SiteEngineer: 'Site Engineer',
    Accounts: 'Accounts',
    UserAdministrator: 'User administrator'
}