import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimesCircle, faDotCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const badgeCss = { padding: "0.27em 0.75em 0.23em 0.75em", borderRadius: "1em" }
const faCss = { marginRight: "0.25em", marginLeft: "-0.25em" }


// Task summary badges

const SummaryCritical = () => {
  return (
    <span style={badgeCss} className="bg-danger text-white">
      <FontAwesomeIcon icon={faExclamationTriangle} style={faCss} /> Critical
    </span>
  );
}

const SummaryOverdue = () => {
  return (
    <span style={badgeCss} className="bg-warning">
      Overdue
    </span>
  );
}

const SummaryNoTasks = () => {
  return (
    <span style={badgeCss} className="bg-light border">
      No tasks
    </span>
  );
}

const SummaryOK = () => {
  return (
    <span style={badgeCss} className="bg-success text-white">
      OK
    </span>
  );
}

// Job status badges

const JobOpen = () => {
  return (
    <span style={badgeCss} className="bg-primary text-white">
      <FontAwesomeIcon icon={faDotCircle} style={faCss} /> Open
    </span>
  );
}

const JobCancelled = () => {
  return (
    <span style={badgeCss} className="bg-light border">
      <FontAwesomeIcon icon={faTimesCircle} style={faCss} className="text-danger" /> Cancelled
    </span>
  );
}

const JobComplete = () => {
  return (
    <span style={badgeCss} className="bg-success text-white">
      <FontAwesomeIcon icon={faCheckCircle} style={faCss} /> Complete
    </span>
  );
}


// Task status badges

const TaskOverdue = () => {
  return (
    <span style={badgeCss} className="bg-danger text-white">
      <FontAwesomeIcon icon={faExclamationTriangle} style={faCss} /> Overdue
    </span>
  );
}

const TaskDueToday = () => {
  return (
    <span style={badgeCss} className="bg-success text-white">
      Due today
    </span>
  );
}

const TaskOpen = () => {
  return (
    <span style={badgeCss} className="bg-light border">
        Open
    </span>
  );
}

const TaskComplete = () => {
  return (
    <span style={badgeCss} className="bg-success text-white">
      <FontAwesomeIcon icon={faCheckCircle} style={faCss} /> Complete
    </span>
  );
}



// Job Types

type JobTypeBadgeProps = {
  text : string
}

const JobType: React.FC<JobTypeBadgeProps> = ({ text }) => {
  return (
    <span style={badgeCss} className="bg-light border">
      {text}
    </span>
  );
}



// Wait Time

type WaitTimeBadgeProps = {
  days : number
}

const WaitTime: React.FC<WaitTimeBadgeProps> = ({ days }) => {
  var colour = "bg-success border text-white"
  if(days >= 5){
    colour = "bg-danger border text-white"
  } else if(days >= 3){
    colour = "bg-warning border"
  }
  return (
    <span style={badgeCss} className={colour}>
      {days.toString()}d
    </span>
  );
}

export { SummaryCritical, SummaryOverdue, SummaryNoTasks, SummaryOK };
export { JobOpen, JobCancelled, JobComplete };
export { TaskOverdue, TaskDueToday, TaskOpen, TaskComplete };
export { JobType };
export { WaitTime };