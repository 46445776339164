import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { JobCancelled, JobComplete, JobOpen, JobType, SummaryCritical, SummaryNoTasks, SummaryOK, SummaryOverdue } from "../_components/Badges";
import { JobCompletionStatus } from "../_constants/JobCompletionStatus";
import { JobTasksStatus } from "../_constants/JobTasksStatus";
import { JobStatus } from "../_constants/JobStatus";

type Props = {
    customerId?: string;
    pageSize?: number;
};

const JobListDetailsListPage: React.FC<Props> = ({ customerId, pageSize = 25 }) => {
    const navigate = useNavigate();
    const columns: IColumn[] = [];
    const filters: IFilter[] = [];
    var staticFilterValues: Record<string, any> | undefined = undefined;
    const persistanceKey: string = "JobListDetailsListPage" + (customerId ? "-Customer" : "");

    columns.push({
        member: "jobNumber",
        renderAs: ColumnRender.text,
        title: "Job Number",
        width: 120,
        default: "Enquiry",
    } as IColumn);
    if (!customerId) {
        columns.push({
            member: "customerNumber",
            renderAs: ColumnRender.text,
            title: "Customer Number",
            width: 100,
        } as IColumn);
    }
    columns.push({
        member: "JobCompletionStatus",
        renderAs: ColumnRender.customRenderer,
        title: "Status",
        width: 150,
        customRenderer: (item) => {
            switch (item.jobCompletionStatus.toString()) {
                case JobCompletionStatus[JobCompletionStatus.Complete]:
                    return (
                        <td>
                            <JobComplete />
                        </td>
                    );
                case JobCompletionStatus[JobCompletionStatus.Cancelled]:
                    return (
                        <td>
                            <JobCancelled />
                        </td>
                    );
                default:
                    return (
                        <td>
                            <JobOpen />
                        </td>
                    );
            }
        },
    } as IColumn);
    columns.push({
        member: "jobTypes",
        renderAs: ColumnRender.customRenderer,
        title: "Type",
        width: 200,
        customRenderer: (item) => {
            return (
                <td>
                    <Stack gap={2}>{item.jobTypes && item.jobTypes.map((type: string) => type && <JobType key={type} text={type} />)}</Stack>
                </td>
            );
        },
        canSort: false,
    } as IColumn);
    if (!customerId) {
        columns.push({
            member: "customerName",
            renderAs: ColumnRender.text,
            title: "Customer",
            width: 100,
        } as IColumn);
    }
    columns.push({
        member: "address",
        renderAs: ColumnRender.text,
        title: "Address",
        width: 400,
    } as IColumn);
    columns.push({
        member: "tasksStatus",
        renderAs: ColumnRender.customRenderer,
        title: "Tasks Status",
        width: 150,
        customRenderer: (item) => {
            switch (item.tasksStatus.toString()) {
                case JobTasksStatus[JobTasksStatus.Critical]:
                    return (
                        <td>
                            <SummaryCritical />
                        </td>
                    );
                case JobTasksStatus[JobTasksStatus.Overdue]:
                    return (
                        <td>
                            <SummaryOverdue />
                        </td>
                    );
                case JobTasksStatus[JobTasksStatus.NoTasks]:
                    return (
                        <td>
                            <SummaryNoTasks />
                        </td>
                    );
                default:
                    return (
                        <td>
                            <SummaryOK />
                        </td>
                    );
            }
        },
    } as IColumn);

    filters.push({
        title: "Job number",
        member: "jobNumber",
        type: FilterType.text,
    } as IFilter);
    if (!customerId) {
        filters.push({
            title: "Customer number",
            member: "customerNumber",
            type: FilterType.text,
        } as IFilter);
        filters.push({
            title: "Customer name",
            member: "customerName",
            type: FilterType.text,
        } as IFilter);
    }
    filters.push({
        title: "Address",
        member: "address",
        type: FilterType.text,
    } as IFilter);

    if (customerId) {
        staticFilterValues = {
            customerId: customerId,
            statuses: [JobStatus.job, JobStatus.complete],
        } as Record<string, any>;
    } else {
        staticFilterValues = {
            statuses: [JobStatus.job, JobStatus.complete],
        } as Record<string, any>;
    }

    return (
        <>
            <PagedList
                columns={columns}
                itemActions={[
                    {
                        text: "Detail",
                        onClick: (item) => {
                            navigate(`/jobs/${item.id}`);
                        },
                        primary: true,
                    } as IPagedListAction,
                ]}
                headActions={[
                    {
                        icon: faPlus,
                        text: "New Enquiry",
                        onClick: () => {
                            navigate(`/newenquiry`);
                        },
                    } as IPagedListAction,
                ]}
                staticFilterValues={staticFilterValues}
                filters={filters}
                defaultSortBy="jobNumber"
                getUrl="api/job/alljoblistdetails"
                avatarUrl="api/avatar/get"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey={persistanceKey}
                pageSize={pageSize}
            />
        </>
    );
};

export { JobListDetailsListPage };
