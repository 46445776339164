import { authHeader, handleResponse } from '../_helpers';

export const userService = {
    getAllListItems,
    getById,
    getAvatarById,
    getColor,
    add,
    updateRoles,
    updateDisplayName,
    updateColor,
    endAllSessionsForUserWithId,
    disableAndEndAllSessionsForUserWithId,
    enableUserWithId,
    resetLockedOutUserWithId,
    updateTwoFAIsRequired,
    resetTwoFactorSecretKey
};

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/users/alllistitems`, requestOptions).then(handleResponse);
}

function getById(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/users/${id}`, requestOptions).then(handleResponse);
}

function getAvatarById(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/users/getAvatarById/${id}`, requestOptions).then(handleResponse);
}

function getColor(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/users/getColor/${id}`, requestOptions).then(handleResponse);
}

function add(email: string, roleIds: string[], organisationId: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ email, roleIds, organisationId })
    };
    return fetch(`api/users/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });;
}

function updateRoles(id: string, roleIds: string[]){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ id, roleIds })
    };
    return fetch(`api/users/updateroles`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updateDisplayName(id: string, displayName: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/updatedisplayname?id=${id}&displayname=${displayName}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updateColor(id: string, color: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return fetch(`api/users/updatecolor?id=${id}&color=${encodeURIComponent(color)}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function endAllSessionsForUserWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/endallsessionsforuserwithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function disableAndEndAllSessionsForUserWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/disableandendallsessionsforuserwithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function enableUserWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/enableuserwithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function resetLockedOutUserWithId(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/resetlockedoutuserwithid?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function updateTwoFAIsRequired(id: string, isRequired: boolean){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/updatetwofaisrequired?id=${id}&isRequired=${isRequired}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function resetTwoFactorSecretKey(id: string){
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`api/users/resettwofactorsecretkey?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}