import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from "react-helmet";
import { TaskListPage } from "../Task/TaskListPage";
import { TaskType } from "../_constants/TaskType";

const InstallationPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Installations</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Installations</h1>
                    <TaskListPage
                        taskTypes={[
                            TaskType.SetInstallationDate,
                            TaskType.ContractAgreement,
                            TaskType.PostSaleSurvey,
                            TaskType.EquipmentReminder,
                            TaskType.CheckSiteEquipmentOnSitePickedUp,
                            TaskType.Checklists,
                            TaskType.AcceptanceByCustomer,

                            TaskType.EquipmentReservation,
                            TaskType.MCSCertificate,
                            TaskType.NapitCertificate,
                            TaskType.CompletePaperclip,
                            TaskType.HandoverPackSent,
                            TaskType.Review,
                        ]}
                        isCompleted={false}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export { InstallationPage };
