import { IValueAndLabel } from '../../customTypings/ValueAndLabel';
import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Select from 'react-select'
import { userService } from '../../_services';
import { ReactSelectBootstrapStyle } from '../../_styles/ReactSelectBootstrapStyle'
import { ITask } from '../../customTypings/Task';
import { TaskType } from '../../_constants/TaskType';
import dayjs from "dayjs";
import { ITaskMetadata } from '@/customTypings/TaskMetadata';

type Props = {
    onUpdate: (tasks: ITask[]) => void
    assignedId?: string
    jobId?: string
    amount?: number
    }

const CreateChaseInvoice: React.FC<Props> = ({ onUpdate, assignedId, jobId, amount }) => {
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>()
    const [assignee, setAssignee] = useState<string | undefined>()
    const [assignee2, setAssignee2] = useState<string | undefined>()
    const [dueDate, setDueDate] = useState<Date | undefined>()
    const [selectedAmount, setSelectedAmount] = useState<number | undefined>(amount)

    useEffect(() => {
        userService.getAllListItems().then(identities => setAllIdentities(identities))
    }, []);

    useEffect(() => {
        const newMetadata: ITaskMetadata[] = [{
            id: undefined,
            name: "Amount",
            value: selectedAmount?.toString()
        }]
        const newTask: ITask = {
            id: undefined,
            jobId: jobId,
            taskType: TaskType.ChaseInvoice,
            name: "Chase Invoice",
            creationDate: new Date(),
            completedDate: undefined,
            dueDate: dueDate || new Date(),
            originalDueDate: dueDate,
            assigneeIdentityId: assignee,
            secondAssigneeIdentityId: assignee2,
            notes: undefined,
            metadata: newMetadata,
            actionDate: undefined
        }

        onUpdate([newTask])
    }, [assignee, assignee2, dueDate]);

    return (
        <>
            {!assignedId &&
            <Form.Group as={Row} className="mb-3" controlId="chaseInvoiceAssignee1">
                <Form.Label column sm={3}>Assignee</Form.Label>
                <Col sm={9}>
                    <Select
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                        onChange={value=> {
                            setAssignee(value == null ? '' : value.value)
                        }}
                        isClearable
                        options={allIdentities}
                    />
                </Col>
            </Form.Group>}
            <Form.Group as={Row} className="mb-3" controlId="chaseInvoiceAssignee2">
                <Form.Label column sm={3}>Assignee 2</Form.Label>
                <Col sm={9}>
                    <Select
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                        onChange={value=> {
                            setAssignee2(value == null ? '' : value.value)
                        }}
                        isClearable
                        options={allIdentities}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="chaseInvoiceDueDate">
                <Form.Label column sm={3}>Due Date</Form.Label>
                <Col sm={9}>
                    <Form.Control
                    type="date"
                    name="dueDate"
                    defaultValue={dayjs(new Date()).format('YYYY-MM-DD')}
                    onChange={(event) => {
                            setDueDate(new Date(event.target.value))
                    }}
                    />
                </Col>
            </Form.Group>
            {!amount && <Form.Group as={Row} className="mb-3" controlId="chaseInvoiceAmount">
                <Form.Label column sm={3}>Invoice Amount</Form.Label>
                <Col sm={9}>
                    <InputGroup className="mb-3">
                    <InputGroup.Text>£</InputGroup.Text>
                        <Form.Control
                        type="number"
                        name="amount"
                        value={amount}
                        onChange={(e) => setSelectedAmount(parseInt(e.target.value, 10))}
                        />
                    <InputGroup.Text>.00</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Form.Group>}
        </>
    )
}

export { CreateChaseInvoice };