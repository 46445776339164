import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Alert, Button, Form, Modal, Col, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { taskService } from "../../_services";
import { ITask } from "../../customTypings/Task";
import dayjs from "dayjs";
import { ITaskMetadata } from "../../customTypings/TaskMetadata";
import { clone } from "../../_helpers/utils";
import { TaskType } from "../../_constants/TaskType";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    task: ITask;
};

const AddDNOMetadataModal: React.FC<Props> = ({ show, onClose, task }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => onClose(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    reference:
                        task.metadata?.find((item) => item.name === "Reference")
                            ?.value ?? "",
                    expectedDate: dayjs(
                        task.metadata?.find(
                            (item) => item.name === "ExpectedDate"
                        )?.value ?? ""
                    ).format("YYYY-MM-DD"),
                }}
                validationSchema={Yup.object().shape({
                    reference: Yup.string().required("Reference is required"),
                    expectedDate: Yup.string().required(
                        "Expected Date is required"
                    ),
                })}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError }
                ) => {
                    setStatus();
                    setSubmitting(false);

                    const updatedTask: ITask = clone(task);
                    var updatedMetadata = updatedTask.metadata?.map((item) => {
                        if (item.name === "Reference") {
                            item.value = values.reference;
                        } else if (item.name === "ExpectedDate") {
                            item.value = values.expectedDate;
                        }
                        return item;
                    });
                    if (
                        !task.metadata?.some(
                            (item) => item.name === "Reference"
                        )
                    ) {
                        const newMetadata: ITaskMetadata = {
                            id: undefined,
                            name: "Reference",
                            value: values.reference,
                        };
                        if (updatedMetadata) {
                            updatedMetadata = [...updatedMetadata, newMetadata];
                        } else {
                            updatedMetadata = [newMetadata];
                        }
                    }
                    if (
                        !task.metadata?.some(
                            (item) => item.name === "ExpectedDate"
                        )
                    ) {
                        const newMetadata: ITaskMetadata = {
                            id: undefined,
                            name: "ExpectedDate",
                            value: values.expectedDate,
                        };
                        if (updatedMetadata) {
                            updatedMetadata = [...updatedMetadata, newMetadata];
                        } else {
                            updatedMetadata = [newMetadata];
                        }
                    }
                    updatedTask.metadata = updatedMetadata;
                    updatedTask.actionDate = new Date();

                    const newChaseDNOTask: ITask = {
                        id: undefined,
                        jobId: updatedTask.jobId,
                        taskType: TaskType.ChaseDNO,
                        name: "Chase DNO",
                        creationDate: new Date(),
                        completedDate: undefined,
                        dueDate: new Date(values.expectedDate),
                        originalDueDate: new Date(values.expectedDate),
                        assigneeIdentityId: updatedTask.assigneeIdentityId,
                        secondAssigneeIdentityId:
                            updatedTask.secondAssigneeIdentityId,
                        notes: undefined,
                        actionDate: undefined,
                        metadata: updatedMetadata,
                    };

                    taskService.edit(updatedTask).then(
                        (response) => {
                            taskService.add(newChaseDNOTask).then(
                                (response) => {
                                    setSubmitting(false);
                                    if (response.status !== "Failure") {
                                        onClose(true);
                                    } else {
                                        setAlertVariant("danger");
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    setAlertVariant("danger");
                                    if (error.status === 400) {
                                        setStatus(error.title);
                                        setFieldError(
                                            "reference",
                                            error.errors.Reference
                                        );
                                        setFieldError(
                                            "expectedDate",
                                            error.errors.ExpectedDate
                                        );
                                    } else {
                                        setStatus(error);
                                    }
                                    setSubmitting(false);
                                }
                            );
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError(
                                    "reference",
                                    error.errors.Reference
                                );
                                setFieldError(
                                    "expectedDate",
                                    error.errors.ExpectedDate
                                );
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({
                    errors,
                    status,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    values,
                    handleChange,
                    setFieldValue,
                }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Mark request sent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="reference"
                            >
                                <Form.Label column sm={3}>
                                    DNO reference
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="reference"
                                        type="text"
                                        className={
                                            "form-control" +
                                            (errors.reference &&
                                            touched.reference
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3"
                                controlId="expectedDate"
                            >
                                <Form.Label column sm={3}>
                                    Expected Date
                                </Form.Label>
                                <Col sm={9}>
                                    <Field
                                        name="expectedDate"
                                        type="date"
                                        className={
                                            "form-control" +
                                            (errors.expectedDate &&
                                            touched.expectedDate
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="expectedDate"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Updating task..." />
                                    ) : (
                                        "Update task"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddDNOMetadataModal };
