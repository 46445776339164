import React, { Dispatch, SetStateAction } from "react";

// Local storage hook
// Stores data in local storage with key specified in storageKey
export const useLocalStorage = <Type,>(storageKey: string | undefined, fallbackState: Type | null): [Type | null, Dispatch<SetStateAction<Type | null>>] => {
    const getLocalStorage = (): Type | null => {
        if(storageKey) {
            const json = localStorage.getItem(storageKey);
            if(json !== null) {
                return JSON.parse(json) as Type;
            }
        }
        return fallbackState;
    };
    
    const [value, setValue] = React.useState(
        getLocalStorage
    );
  
    React.useEffect(() => {
        if(storageKey) {
            localStorage.setItem(storageKey, JSON.stringify(value));
        }
    }, [value, storageKey]);
  
    return [value, setValue];
};