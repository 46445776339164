import React, { useEffect, useState } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';

import { organisationService, rolesService, userService } from '../_services';
import { Alert, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { LoadingSpinner } from "./LoadingSpinner";
import { IOrganisation } from '../customTypings/Organisation';
import { IRole } from '../customTypings/role';

type AddNewUserProps = {
    show: boolean;
    onClose: ()=> void;
    onAdded?: ()=> void;
    }

interface IActionResponse {
    status: string;
    message: string;
    entity?: any
}

const AddNewUser: React.FC<AddNewUserProps> = ({ show, onClose, onAdded }) => {
    const empty: string[] = [];
    const [alertVariant, setAlertVariant] = useState('danger');
    const [organisations, setOrganisations] = useState<IOrganisation[]>();
    const [allRoles, setAllRoles] = useState<IRole[]>()

    useEffect(() => {
        organisationService.getAll().then(organisations => setOrganisations(organisations))
        rolesService.getAllForGroup(0).then(roles => setAllRoles(roles))
    }, []);

    return (
        <Modal
            centered 
            show={show}
            keyboard={false}
            onHide={onClose}
        >
            <Formik
                initialValues={{
                    email: '',
                    roles: empty,
                    organisation: ''
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError })  => {
                    setStatus();
                    userService.add(values.email, values.roles, values.organisation)
                        .then(
                            (response:IActionResponse) => {
                                setSubmitting(false);
                                if(response.status === "Success")
                                {
                                    setAlertVariant('success');
                                    setStatus("Invitation sent successfully.");
                                    setTimeout(()=> {
                                        if(typeof(onAdded) === "function")
                                            onAdded();
                                        onClose();
                                    }, 3000)
                                }
                                else
                                {
                                    setAlertVariant('danger');
                                    setStatus(response.message);
                                }                              
                            },
                            error => {
                                setAlertVariant('danger')
                                if(error.status === 400){
                                    setStatus(error.title);
                                    setFieldError('email', error.errors.Email)
                                    setFieldError('roles', error.errors.Roles)
                                    setFieldError('organisation', error.errors.Organisation)
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Invite New User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row} className="mb-3" controlId="newUserEmail">
                                <Form.Label column sm={3}>Email</Form.Label>
                                <Col sm={9}>
                                    <Field name="email" type="email" autoFocus className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
						    <Form.Group as={Row} className="mb-3" controlId="roleCheckboxs">
                                <Form.Label column sm={3}>Roles</Form.Label>
                                <Col sm={9} className="pt-2">
                                    {allRoles && allRoles.map(role =>
                                        <label key={role.id} className="d-block">
                                            <Field type="checkbox" name="roles" value={role.id} className="me-2" />
                                            {role.name}
                                        </label>
                                    )}
                                </Col>
						    </Form.Group>
						    <Form.Group as={Row} className="mb-3" controlId="organisationRadios">
                                <Form.Label column sm={3}>Organisation</Form.Label>
                                <Col sm={9} className="pt-2">
                                    {organisations && organisations.map(organisation =>
                                        <label key={organisation.id}>
                                            <Field type="radio" name="organisation" value={organisation.id} className="me-2" />
                                            {organisation.displayName}
                                        </label>
                                    )}
                                </Col>
						    </Form.Group>
                            {status &&
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                  variant="primary"
                                  disabled={isSubmitting}
                                  type="submit"
                                  className="me-2" 
                                >
                                    {isSubmitting 
                                        ?  <LoadingSpinner text="Sending invitation..."/>
                                        : "Invite User"}
                                </Button>
                                <Button variant="secondary" onClick={onClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export { AddNewUser };