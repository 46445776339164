import { ITimesheet } from '@/customTypings/Timesheet';
import { authHeader, handleResponse } from '../_helpers';

export const timesheetService = {
    get,
    getActive,
    add,
    complete,
    edit
};

function get(id: string) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/timesheet/get/${id}`, requestOptions).then(handleResponse);
}

function getActive() {
    const requestOptions = { method: 'GET', headers: authHeader() };
    return fetch(`api/timesheet/getactive`, requestOptions).then(handleResponse);
}

function add(timesheet: ITimesheet){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(timesheet)
    };
    return fetch(`api/timesheet/add`, requestOptions).then(handleResponse);
}

function complete(id: string){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/timesheet/complete?id=${id}`, requestOptions).then(handleResponse);
}

function edit(timesheet: ITimesheet){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(timesheet)
    };
    return fetch(`api/timesheet/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}