import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Dropdown,
    Container,
    FormGroup,
    Row,
    Form,
    Col,
    Button,
} from "react-bootstrap";
import { IFilter, FilterType, IFilterValue } from "./Declarations";
import { useEffect, useState, useRef, MutableRefObject } from "react";
import { clone } from "../../_helpers/utils";

type Props = {
    filterValues: IFilterValue[];
    onSave: (values: IFilterValue[]) => void;
    onReset: () => void;
};

const FilterDropdown: React.FC<Props> = ({ filterValues, onSave, onReset }) => {
    const [filters, setFilters] = useState(clone<IFilterValue[]>(filterValues));
    const [show, setShow] = useState(false);
    const inputElement = useRef<HTMLInputElement>(null);
    const selectElement = useRef<HTMLSelectElement>(null);
    const clickRef = useRef<any>();

    useEffect(() => {
        setFilters(filterValues);
    }, [filterValues]);

    useEffect(() => {
        if (show) {
            if (inputElement.current) {
                inputElement.current.focus();
            }
            if (selectElement.current) {
                selectElement.current.focus();
            }
        }
    }, [show]);

    const useClickOutside = (
        ref: MutableRefObject<any>,
        callback: Function
    ) => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };
        useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => {
                document.removeEventListener("click", handleClick);
            };
        });
    };

    useClickOutside(clickRef, () => {
        if (show) {
            setShow(false);
        }
    });

    const coreceValue = (value: any, filter: IFilter) => {
        switch (filter.type) {
            case FilterType.boolean:
                return /true|1/i.test(value);
            case FilterType.select:
                const key = Object.keys(filter.options).filter((key) => {
                    return (
                        (filter.options[key]?.toString() ?? "null") === value
                    );
                })[0];
                return filter.options[key];
            case FilterType.date:
            case FilterType.integer:
                if (value === "") return null;
                return value;
            default:
                return value;
        }
    };

    const handleSave = () => {
        setShow(false);
        onSave(clone<IFilterValue[]>(filters));
    };

    const handleReset = () => {
        setShow(false);
        onReset();
    };

    const handleOnChange = (member: string, value: any) => {
        const updatedFilters = clone<IFilterValue[]>(filters).map((item) => {
            if (item.filter.member === member)
                item.value = coreceValue(value, item.filter);
            return item;
        });
        setFilters(updatedFilters);
    };

    return (
        <Dropdown autoClose="outside" show={show} ref={clickRef}>
            <Dropdown.Toggle
                variant="secondary"
                id="filter-dropdown"
                onClick={() => setShow(!show)}
            >
                <FontAwesomeIcon icon={faSlidersH} className="pe-2" /> Filter
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="shadow"
                style={{ zIndex: 1100, width: "30em" }}
            >
                <Container fluid>
                    {filters.map((item, index) => (
                        <FormGroup
                            as={Row}
                            key={item.filter.member}
                            className="my-1"
                        >
                            <Form.Label column sm={5}>
                                {item.filter.title}
                            </Form.Label>
                            <Col sm={7}>
                                {item.filter.type !== FilterType.select &&
                                    item.filter.type !== FilterType.boolean &&
                                    item.filter.type !== FilterType.date &&
                                    item.filter.type !== FilterType.integer && (
                                        <Form.Control
                                            type="text"
                                            value={item.value ?? ""}
                                            ref={
                                                index === 0
                                                    ? inputElement
                                                    : null
                                            }
                                            onChange={(event) =>
                                                handleOnChange(
                                                    item.filter.member,
                                                    event.target.value
                                                )
                                            }
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter")
                                                    handleSave();
                                            }}
                                        />
                                    )}
                                {item.filter.type === FilterType.integer && (
                                    <Form.Control
                                        type="number"
                                        value={item.value ?? ""}
                                        ref={index === 0 ? inputElement : null}
                                        onChange={(event) =>
                                            handleOnChange(
                                                item.filter.member,
                                                event.target.value
                                            )
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter")
                                                handleSave();
                                        }}
                                    />
                                )}
                                {item.filter.type === FilterType.date && (
                                    <Form.Control
                                        type="date"
                                        value={item.value ?? ""}
                                        ref={index === 0 ? inputElement : null}
                                        onChange={(event) =>
                                            handleOnChange(
                                                item.filter.member,
                                                event.target.value
                                            )
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter")
                                                handleSave();
                                        }}
                                    />
                                )}
                                {item.filter.type === FilterType.select && (
                                    <Form.Select
                                        value={item.value ?? "null"}
                                        ref={index === 0 ? selectElement : null}
                                        onChange={(event) =>
                                            handleOnChange(
                                                item.filter.member,
                                                event.target.value
                                            )
                                        }
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter")
                                                handleSave();
                                        }}
                                    >
                                        {Object.keys(item.filter.options).map(
                                            (key) => (
                                                <option
                                                    key={
                                                        item.filter.options[key]
                                                    }
                                                    value={
                                                        item.filter.options[
                                                            key
                                                        ] ?? "null"
                                                    }
                                                >
                                                    {key}
                                                </option>
                                            )
                                        )}
                                    </Form.Select>
                                )}
                                {item.filter.type === FilterType.boolean && (
                                    <Form.Switch
                                        className="my-2"
                                        checked={item.value}
                                        ref={index === 0 ? inputElement : null}
                                        onChange={(event) =>
                                            handleOnChange(
                                                item.filter.member,
                                                event.target.checked
                                            )
                                        }
                                        label={item.value ? "Yes" : "No"}
                                    />
                                )}
                            </Col>
                        </FormGroup>
                    ))}
                    <Dropdown.Divider />
                    <Row>
                        <Col>
                            <Button
                                variant="outline-dark"
                                onClick={() => handleReset()}
                            >
                                Clear Filter
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant="primary"
                                className="me-2"
                                onClick={() => handleSave()}
                            >
                                Apply filter
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setShow(false)}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export { FilterDropdown };
