import { IValueAndLabel } from '../../customTypings/ValueAndLabel';
import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select'
import { userService } from '../../_services';
import { ReactSelectBootstrapStyle } from '../../_styles/ReactSelectBootstrapStyle'
import { ITask } from '../../customTypings/Task';
import { TaskType } from '../../_constants/TaskType';
import dayjs from "dayjs";

type Props = {
    onUpdate: (tasks: ITask[]) => void
    assignedId?: string
    jobId?: string
    }

const CreateChaseDNO: React.FC<Props> = ({ onUpdate, assignedId, jobId }) => {
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>()
    const [assignee, setAssignee] = useState<string | undefined>()
    const [assignee2, setAssignee2] = useState<string | undefined>()
    const [dueDate, setDueDate] = useState<Date | undefined>()

    useEffect(() => {
        userService.getAllListItems().then(identities => setAllIdentities(identities))
    }, []);

    useEffect(() => {
        const newTask: ITask = {
            id: undefined,
            jobId: jobId,
            taskType: TaskType.ChaseDNO,
            name: "Chase DNO",
            creationDate: new Date(),
            completedDate: undefined,
            dueDate: dueDate || new Date(),
            originalDueDate: dueDate,
            assigneeIdentityId: assignee,
            secondAssigneeIdentityId: assignee2,
            notes: undefined,
            actionDate: undefined
        }

        onUpdate([newTask])
    }, [assignee, assignee2, dueDate]);

    return (
        <>
            {!assignedId &&
            <Form.Group as={Row} className="mb-3" controlId="chaseDNOAssignee1">
                <Form.Label column sm={3}>Assignee</Form.Label>
                <Col sm={9}>
                    <Select
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                        onChange={value=> {
                            setAssignee(value == null ? '' : value.value)
                        }}
                        isClearable
                        options={allIdentities}
                    />
                </Col>
            </Form.Group>}
            <Form.Group as={Row} className="mb-3" controlId="chaseDNOAssignee2">
                <Form.Label column sm={3}>Assignee 2</Form.Label>
                <Col sm={9}>
                    <Select
                        styles={ReactSelectBootstrapStyle}
                        className="stateManagedSelect"
                        onChange={value=> {
                            setAssignee2(value == null ? '' : value.value)
                        }}
                        isClearable
                        options={allIdentities}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="chaseDNODueDate">
                <Form.Label column sm={3}>Due Date</Form.Label>
                <Col sm={9}>
                    <Form.Control
                    type="date"
                    name="dueDate"
                    defaultValue={dayjs(new Date()).format('YYYY-MM-DD')}
                    onChange={(event) => {
                            setDueDate(new Date(event.target.value))
                    }}
                    />
                </Col>
            </Form.Group>
        </>
    )
}

export { CreateChaseDNO };