import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Helmet } from 'react-helmet';
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { TaskListPage } from "../Task/TaskListPage";

const MyTasksPage = () => {
    const currentUser = useAtom(userAtom)
    return (
        <Container fluid>
            <Helmet>
                <title>My Tasks</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">My Tasks</h1>
                    <TaskListPage 
                        assignedId={(currentUser ? currentUser.identity.id : undefined)}
                        isCompleted={false}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export { MyTasksPage }; 
