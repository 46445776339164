import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faColumns, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/esm/Dropdown";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";
import { authHeader, handleResponse } from "../../_helpers";
import { LoadingOverlay } from "../LoadingOverlay";
import { Alert, Card } from "react-bootstrap";
import { ValueRenderer } from "./ValueRenderer";
import { IColumn, IPagedListAction, IPagedViewResponse, ColumnRenderAlignment, SortOrder, IFilter, IFilterValue, IRequestValues, ColumnRender, RowFormatterFunction } from "./Declarations";
import { SelectColumnModal, IColumnOption } from "./SelectColumnModal";
import { FilterDropdown } from "./FilterDropdown";
import { StickyScrollBar } from "./StickyScrollBar";
import { useLocalStorage } from "./LocalStorage";
import { clone } from "../../_helpers/utils";
import "./PagedList.css";

// import ColumnResizer from "react-table-column-resizer";

type Props = {
    columns: IColumn[];
    headActions?: IPagedListAction[];
    itemActions?: IPagedListAction[];
    filters?: IFilter[];
    staticFilterValues?: Record<string, any>;
    defaultSortBy: string;
    defaultSortOrder?: SortOrder;
    pageSize?: number;
    getUrl: string;
    avatarUrl?: string;
    keyColumn?: string;
    rowFormatter: RowFormatterFunction;
    defaultRowClassName?: string;
    persistanceKey?: string;
    refreshNumber?: number;
    exportUrl?: string;
    exportIcon?: IconDefinition;
};

const PagedList: React.FC<Props> = ({
    columns,
    headActions = [] as IPagedListAction[],
    itemActions = [] as IPagedListAction[],
    filters = [] as IFilter[],
    staticFilterValues = [] as Record<string, any>,
    defaultSortBy,
    defaultSortOrder = SortOrder.ascending,
    pageSize = 25,
    getUrl,
    avatarUrl,
    keyColumn = "id",
    rowFormatter,
    defaultRowClassName = "bg-body",
    persistanceKey,
    refreshNumber,
    exportUrl,
    exportIcon,
}) => {
    const [results, setResults] = useState([]);
    const [pages, setPages] = useState<number[]>([]);
    const [filterValues, setFilterValues] = useState<IFilterValue[]>(
        filters.map((filter) => {
            return { filter: filter, value: filter.defaultValue ?? null } as IFilterValue;
        })
    );
    const [lastRequestValues, setLastRequestValues] = useState<IRequestValues | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [first, setFirst] = useState(0);
    const [last, setLast] = useState(0);
    const [total, setTotal] = useState(0);
    const [lastPage, setLastPage] = useState(1);
    const [sortBy, setSortBy] = useState(defaultSortBy);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [hasResults, setHasResults] = useState(false);
    const [allColumns, setAllColumns] = useState(columns);
    const [visibleColumns, setVisibleColumns] = useState<IColumn[]>(columns.filter((column) => column.defaultVisible !== false));
    const [tableWidth, setTableWidth] = useState<number>(visibleColumns.reduce((sum, column) => (sum += column.width), 0));
    const [isBusy, setIsBusy] = useState(false);
    const [hasLoadError, setHasLoadError] = useState(false);
    const [loadErrorMessage, setLoadErrorMessage] = useState<string | null>();
    const [showSelectColumnsModal, setShowSelectColumnsModal] = useState(false);
    const [columnOptions, setColumnOptions] = useState<IColumnOption[]>([]);

    const primaryActions = itemActions.filter((action) => action.primary === true);
    const secondaryActions = itemActions.filter((action) => action.primary !== true);

    const pagedListTableContainer = useRef<HTMLDivElement | null>(null);

    const [storedFilters, setStoredFilters] = useLocalStorage<IFilterValue[]>(persistanceKey + "-filters", null);
    const [storedColumns, setStoredColumns] = useLocalStorage<IColumnOption[]>(persistanceKey + "-columns", null);

    const [currentRefreshNumber, setCurrentRefeshNumber] = useState(refreshNumber);

    const toFindDuplicateColumns = (columns: IColumn[]): string[] => {
        const allElements = columns.map((c) => c.member) as string[];
        const uniqueElements = [] as string[];
        allElements.forEach((item, index) => {
            if (allElements.indexOf(item) !== index) uniqueElements.push(item);
        });
        return uniqueElements;
    };

    useEffect(() => {
        const duplicateColumns = toFindDuplicateColumns(columns);
        if (duplicateColumns.length) {
            alert(`Duplicate column members detected: ${duplicateColumns}`);
        }

        // Loads filters and column options from local storage on mount of component
        if (persistanceKey) {
            if (storedFilters !== null) {
                const updatedFilters = clone<IFilterValue[]>(filterValues).map((item) => {
                    const s = storedFilters.find((f: IFilterValue) => f.filter.member === item.filter.member);
                    if (s !== null && s !== undefined) {
                        item.value = s.value;
                    }
                    return item;
                });
                setFilterValues(updatedFilters);
            }

            if (storedColumns !== null) {
                const updatedColumns = sanitizeColumnOptions(storedColumns);
                updateColumns(updatedColumns);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function filterChanged(last: Record<string, any>, current: Record<string, any>): boolean {
            for (const key in current) {
                if (last[key] !== current[key]) {
                    return true;
                }
            }
            return false;
        }

        function requestParamsChanged(last: IRequestValues, current: IRequestValues): boolean {
            return last.offset !== current.offset || last.count !== current.count || last.sortBy !== current.sortBy || last.sortOrder !== current.sortOrder;
        }

        function getResults(): void {
            const filterData: Record<string, any> = {};

            for (const key in staticFilterValues) {
                filterData[key] = staticFilterValues[key];
            }

            filterValues.forEach((item) => {
                filterData[item.filter.member] = item.value;
            });

            const filterHasChanged = lastRequestValues === null || filterChanged(lastRequestValues.filter, filterData);
            const requestOffset = filterHasChanged ? 0 : offset;

            const data: IRequestValues = {
                filter: filterData,
                offset: requestOffset,
                count: pageSize,
                sortBy: sortBy,
                sortOrder: sortOrder,
            };

            if (!hasResults || filterHasChanged || lastRequestValues === null || requestParamsChanged(lastRequestValues, data) || refreshNumber != currentRefreshNumber) {
                const requestOptions = { method: "POST", headers: authHeader(), body: JSON.stringify(data) };
                setIsBusy(true);
                fetch(getUrl, requestOptions)
                    .then(handleResponse)
                    .then((response: IPagedViewResponse) => {
                        setOffset(response.offset);
                        setTotal(response.total);
                        setFirst(response.offset + 1);
                        setLast(Math.min(response.offset + response.count, response.total));
                        setResults(response.results);
                        buildPages(response.offset, response.total);
                        setLastRequestValues(data);
                        setHasResults(true);
                        setStoredFilters(filterValues);
                        setIsBusy(false);
                        setHasLoadError(false);
                        setLoadErrorMessage(null);
                    })
                    .catch((rejected) => {
                        setResults([]);
                        setLastRequestValues(data);
                        setHasResults(true);
                        setStoredFilters(filterValues);
                        setIsBusy(false);
                        setHasLoadError(true);
                        setLoadErrorMessage(rejected);
                    });
                setCurrentRefeshNumber(refreshNumber);
            }
        }

        function buildPages(offset: number, total: number): void {
            const currentPage = Math.floor(offset / pageSize) + 1;
            const pages = [] as number[];
            const maxPage = Math.floor((total - 1) / pageSize) + 1;
            const first = Math.max(currentPage - 5, 1);
            const last = Math.min(currentPage + 5, maxPage);

            if (first > 1) pages.push(-1);
            for (var i = first; i <= last; i++) {
                pages.push(i);
            }
            if (last < maxPage) pages.push(-2);

            setPages(pages);
            setLastPage(maxPage);
        }

        getResults();
    }, [offset, sortBy, sortOrder, filterValues, staticFilterValues, hasResults, lastRequestValues, pageSize, getUrl, setStoredFilters, refreshNumber]);

    useEffect(() => {
        setOffset(pageSize * (currentPage - 1));
    }, [currentPage, pageSize]);

    useEffect(() => {
        setTableWidth(visibleColumns.reduce((sum, column) => (sum += column.width), 0));
    }, [visibleColumns]);

    function handleSortByClicked(member: string): void {
        if (member === sortBy) setSortOrder(sortOrder === SortOrder.ascending ? SortOrder.descending : SortOrder.ascending);
        else {
            setSortBy(member);
            setSortOrder(SortOrder.ascending);
        }
    }

    function columnsToColumnOptions(columns: IColumn[]): IColumnOption[] {
        const options = columns.map((column) => {
            return {
                title: column.title,
                member: column.member,
                isVisible: visibleColumns.filter((c) => c.member === column.member).length !== 0,
            } as IColumnOption;
        });
        return options;
    }

    function handleSelectColumnsClicked(): void {
        const options = columnsToColumnOptions(allColumns);
        setColumnOptions(options);
        setShowSelectColumnsModal(true);
    }

    function handleFilterValueChanged(filterValues: IFilterValue[]): void {
        setFilterValues(filterValues);
    }

    function handleFilterReset(): void {
        const filterValues = filters.map((filter) => {
            return { filter: filter, value: filter.defaultValue ?? null } as IFilterValue;
        });
        setFilterValues(filterValues);
    }

    function handleScroll(value: number) {
        if (pagedListTableContainer?.current) {
            pagedListTableContainer.current.scrollLeft = value;
        }
    }

    function sanitizeColumnOptions(columnOptions: IColumnOption[]): IColumnOption[] {
        // Although all columns passed in to columnOptions should match those in columns,
        // they can be loaded by the persistence code so they could potentially
        // have been changed since the last run of the app (due to code updates), so we will
        // guard against that.

        // First get all columns in columnOptions and return matches in columns
        const columnsReordered = [] as IColumnOption[];
        columnOptions.forEach((column) => {
            const col = columns.find((c) => c.member === column.member);
            const added = columnsReordered.find((c) => c.member === column.member);
            if (col && !added) {
                columnsReordered.push({
                    isVisible: column.isVisible,
                    member: col.member,
                    title: col.title,
                } as IColumnOption);
            }
        });

        // Now add any left over columns that aren't now in columnsReordered
        if (columnsReordered.length !== columns.length) {
            columns.forEach((column) => {
                const col = columnsReordered.find((c) => c.member === column.member);
                if (col === undefined) {
                    columnsReordered.push({
                        isVisible: true,
                        member: column.member,
                        title: column.title,
                    } as IColumnOption);
                }
            });
        }
        return columnsReordered;
    }

    function updateColumns(columnOptions: IColumnOption[]): void {
        const columnsReordered = columnOptions.map((column) => {
            // Assume column options match columns in items - they should, as
            // columnOptions is derived from column elsewhere
            return allColumns.filter((c) => c.member === column.member)[0];
        });

        const visibleOptions = columnOptions.filter((column) => {
            return column.isVisible;
        });

        const visible = columnsReordered.filter((column) => {
            return visibleOptions.filter((c) => c.member === column.member).length !== 0;
        });

        setAllColumns(columnsReordered);
        setVisibleColumns(visible);
        setStoredColumns(columnOptions);
    }

    function handleSelectColumnsUpdated(columnOptions: IColumnOption[]): void {
        updateColumns(columnOptions);
    }

    function handleSelectColumnsReset(): void {
        const columnOptions = columnsToColumnOptions(columns);
        setColumnOptions(columnOptions);
        setStoredColumns(columnOptions);
        setAllColumns(columns);
        setVisibleColumns(columns.filter((column) => column.defaultVisible !== false));
    }

    function getRowClassName(item: any): string {
        let className = defaultRowClassName;
        if (typeof rowFormatter === "function") className = rowFormatter(item) || className;
        return className;
    }

    function downloadExport() {
        const filterData: Record<string, any> = {};

        for (const key in staticFilterValues) {
            filterData[key] = staticFilterValues[key];
        }

        filterValues.forEach((item) => {
            filterData[item.filter.member] = item.value;
        });

        const data: IRequestValues = {
            filter: filterData,
            offset: 0,
            count: pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
        };

        const requestOptions = { method: "POST", headers: authHeader(), body: JSON.stringify(data) };
        if (exportUrl) {
            fetch(exportUrl, requestOptions)
                .then((response) => {
                    if (response.ok) {
                        const filename = response.headers.get("content-disposition")?.split('"')[1];
                        response.blob().then((blob) => {
                            const href = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = href;
                            link.download = filename ?? "";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        });
                    } else {
                        return Promise.reject({ Error: "Something Went Wrong" });
                    }
                })
                .catch((err) => {
                    return Promise.reject({ Error: "Something Went Wrong", err });
                });
        }
    }

    return (
        <div>
            <Row>
                {filterValues.length !== 0 && (
                    <Col md="auto">
                        <FilterDropdown filterValues={filterValues} onSave={handleFilterValueChanged} onReset={handleFilterReset} />
                    </Col>
                )}
                <Col className="my-auto">
                    <h6>
                        {total === 0 && <span>No results to show</span>}
                        {total !== 0 && (
                            <span>
                                Showing results {first} - {last} of {total}
                            </span>
                        )}
                    </h6>
                </Col>
                <Col md="auto">
                    {exportUrl && (
                        <Button variant="secondary" className="ms-2" key={"ExportUrl"} onClick={() => downloadExport()}>
                            {exportIcon && <FontAwesomeIcon fixedWidth={true} icon={exportIcon} className="me-2" />}
                            Export
                        </Button>
                    )}
                    {headActions.map((action) => (
                        <Button variant="secondary" className="ms-2" key={action.text} onClick={() => action.onClick(null)}>
                            {action.icon && <FontAwesomeIcon fixedWidth={true} icon={action.icon} className="me-2" />}
                            {action.text}
                        </Button>
                    ))}
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <LoadingOverlay active={isBusy}>
                        <Card className="shadow">
                            <StickyScrollBar onScroll={handleScroll.bind(this)} scrollWidth={tableWidth} scrollLeft={pagedListTableContainer.current?.scrollLeft}>
                                <div className="paged-list-table-container" ref={pagedListTableContainer}>
                                    <Table hover size="sm" className="my-0" style={{ width: tableWidth }}>
                                        <thead className="bg-body border-bottom">
                                            <tr>
                                                {visibleColumns.map((column, index) => (
                                                    <React.Fragment key={`${column.member}-${index}`}>
                                                        <th className={`p-0 ${ColumnRenderAlignment(column.renderAs)}`} style={{ width: `${column.width}px` }}>
                                                            {column.canSort !== false && (
                                                                <Button
                                                                    variant="outline-info"
                                                                    className={`w-100 border-0 rounded-0 m-0 p-2 text-dark ${ColumnRenderAlignment(column.renderAs)}`}
                                                                    onClick={() => handleSortByClicked(column.member)}
                                                                    title={column.renderAs === ColumnRender.deleted ? column.title : undefined}
                                                                >
                                                                    {column.renderAs !== ColumnRender.deleted ? column.title : "\u00a0"}
                                                                    {sortBy === column.member && sortOrder === SortOrder.ascending && <FontAwesomeIcon className="ps-2 text-primary" icon={faSortUp} />}
                                                                    {sortBy === column.member && sortOrder === SortOrder.descending && (
                                                                        <FontAwesomeIcon className="ps-2 text-primary" icon={faSortDown} />
                                                                    )}
                                                                </Button>
                                                            )}
                                                            {column.canSort === false && (
                                                                <span className="p-2 d-inline-block" title={column.renderAs === ColumnRender.deleted ? column.title : undefined}>
                                                                    {column.renderAs !== ColumnRender.deleted ? column.title : "\u00a0"}
                                                                </span>
                                                            )}
                                                        </th>
                                                        {/* <ColumnResizer className="columnResizer" minWidth={30} /> */}
                                                    </React.Fragment>
                                                ))}
                                                <th className="text-end p-0" colSpan={2}>
                                                    <Button
                                                        variant="outline-info"
                                                        className="position-absolute border-0 rounded-0 m-0 px-1 text-dark"
                                                        style={{ top: 0, right: 0, bottom: 0 }}
                                                        onClick={() => handleSelectColumnsClicked()}
                                                        title="Select columns"
                                                    >
                                                        <FontAwesomeIcon icon={faColumns} className="px-2" style={{ height: "1em" }} />
                                                    </Button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!hasLoadError &&
                                                results.map((item) => (
                                                    <tr key={item[keyColumn]} className={getRowClassName(item)}>
                                                        {visibleColumns.map((column, index) => (
                                                            <React.Fragment key={`${column.member}-${index}`}>
                                                                <ValueRenderer item={item} column={column} avatarUrl={avatarUrl} />
                                                                {/* <td></td> */}
                                                            </React.Fragment>
                                                        ))}
                                                        <td className={`text-end ps-2 primary-action-column ${getRowClassName(item)}`}>
                                                            {primaryActions.map((action) => (
                                                                <Button key={action.text} onClick={() => action.onClick(item)}>
                                                                    {action.icon && <FontAwesomeIcon fixedWidth={true} icon={action.icon} className="me-2" />}
                                                                    {action.text}
                                                                </Button>
                                                            ))}
                                                        </td>
                                                        {secondaryActions.length !== 0 && (
                                                            <td
                                                                className={`text-end ${primaryActions.length ? "ps-0" : "ps-2"} secondary-action-column ${getRowClassName(item)}`}
                                                                style={{ width: "3em" }}
                                                            >
                                                                <Dropdown align="end" style={{ position: "static" }}>
                                                                    <Dropdown.Toggle variant="outline-secondary" id={`item-dropdown-${item[keyColumn]}`}></Dropdown.Toggle>
                                                                    {ReactDOM.createPortal(
                                                                        <Dropdown.Menu className="shadow">
                                                                            {secondaryActions.map(
                                                                                (action) =>
                                                                                    (!action.availableMember || item[action.availableMember] === (!action.availableFliped ?? true)) && (
                                                                                        <React.Fragment key={action.text}>
                                                                                            {action.startGroup === true && <Dropdown.Divider />}
                                                                                            <Dropdown.Item onClick={() => action.onClick(item)}>
                                                                                                {action.icon && <FontAwesomeIcon fixedWidth={true} icon={action.icon} className="me-2" />}
                                                                                                {action.text}
                                                                                            </Dropdown.Item>
                                                                                        </React.Fragment>
                                                                                    )
                                                                            )}
                                                                        </Dropdown.Menu>,
                                                                        document.body
                                                                    )}
                                                                </Dropdown>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            {!hasLoadError && hasResults && total === 0 && (
                                                <tr>
                                                    <td colSpan={visibleColumns.length + 2} className="text-center">
                                                        No results to show
                                                    </td>
                                                </tr>
                                            )}
                                            {!hasLoadError && !hasResults && (
                                                <tr>
                                                    <td colSpan={visibleColumns.length + 2} style={{ height: "6em" }}></td>
                                                </tr>
                                            )}
                                            {hasLoadError && (
                                                <tr>
                                                    <td colSpan={visibleColumns.length + 2} className="text-center">
                                                        <Alert variant="danger">
                                                            <span className="fw-bold">Could not load results.</span> {`${loadErrorMessage}`}
                                                        </Alert>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </StickyScrollBar>
                        </Card>
                    </LoadingOverlay>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <Pagination>
                        <Pagination.First disabled={currentPage <= 1} onClick={() => setCurrentPage(1)} />
                        <Pagination.Prev disabled={currentPage <= 1} onClick={() => setCurrentPage(currentPage - 1)} />
                        {pages.map((page) => (
                            <React.Fragment key={page}>
                                {page < 0 && <Pagination.Ellipsis disabled />}
                                {page > 0 && (
                                    <Pagination.Item active={page === currentPage} onClick={() => setCurrentPage(page)}>
                                        {page}
                                    </Pagination.Item>
                                )}
                            </React.Fragment>
                        ))}
                        <Pagination.Next disabled={currentPage >= lastPage} onClick={() => setCurrentPage(currentPage + 1)} />
                        <Pagination.Last disabled={currentPage >= lastPage} onClick={() => setCurrentPage(lastPage)} />
                    </Pagination>
                </Col>
            </Row>
            <SelectColumnModal
                columnOptions={columnOptions}
                show={showSelectColumnsModal}
                onSave={handleSelectColumnsUpdated}
                onReset={handleSelectColumnsReset}
                onClose={() => setShowSelectColumnsModal(false)}
            />
        </div>
    );
};

export { PagedList };
