import { Scrollbars } from "react-custom-scrollbars-2";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { userAtom } from "../_atoms/userAtom";
import { useAtom } from "@dbeining/react-atom";
import { Role } from "../_helpers";
import { IRole } from "../customTypings/role";

export const NavigationBar = () => {
    const currentUser = useAtom(userAtom);
    return (
        <Navbar bg="light" expand={false} expanded={true} sticky="top" id="side-nav">
            <Navbar.Brand className="p-0" to="/" as={NavLink}>
                <img alt="React Crm Boilerplate" src="/ReactCrmBoilerplateLogo.png" style={{ height: "5rem" }} className="d-inline-block align-top m-3" />
            </Navbar.Brand>
            <Navbar.Collapse>
                <Scrollbars autoHide id="navbar-nav-scroll">
                    <Nav className="px-3 pb-5">
                        {currentUser && currentUser.identity.roles.some((role: IRole) => [Role.JobEntry].indexOf(role.name) >= 0) && (
                            <Nav.Link as={NavLink} to="/newenquiry">
                                New Enquiry
                            </Nav.Link>
                        )}
                        <NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="/mytasks">
                            My Tasks
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/mypendingcallbacks">
                            My Pending Callbacks
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/mycalendar">
                            My Calendar
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/mytimesheet">
                            My Timesheet
                        </Nav.Link>
                        <NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="/pendingcallbacks">
                            Pending Callbacks
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/enquiries">
                            Enquiries
                        </Nav.Link>
                        {currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Design, Role.DesignManager].indexOf(role.name) >= 0) && (
                            <Nav.Link as={NavLink} to="/design">
                                Design
                            </Nav.Link>
                        )}
                        <Nav.Link as={NavLink} to="/dno">
                            DNO
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/installation">
                            Installation
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/timesheet">
                            Timesheet
                        </Nav.Link>
                        {currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Accounts].indexOf(role.name) >= 0) && (
                            <Nav.Link as={NavLink} to="/accounts">
                                Accounts
                            </Nav.Link>
                        )}
                        <NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="/customers">
                            Customers
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/jobs">
                            All Jobs
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/tasks">
                            All Tasks
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/calendar">
                            Company Calendar
                        </Nav.Link>
                        <NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="/documentlibrary">
                            Document Library
                        </Nav.Link>
                        {/*<NavDropdown.Divider />
                        <Nav.Link as={NavLink} to="/export">
                            Export
                        </Nav.Link>*/}
                        <NavDropdown.Divider />
                        {currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin, Role.UserAdministrator, Role.SystemAdmin].indexOf(role.name) >= 0) && (
                            <Nav.Link as={NavLink} to="/settings">
                                Settings
                            </Nav.Link>
                        )}
                    </Nav>
                </Scrollbars>
            </Navbar.Collapse>
        </Navbar>
    );
};
