import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { organisationService } from "../_services";
import { Alert, Button, Modal, Stack } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";

type Props = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<any>>;
};

const AddNewOrganisation: React.FC<Props> = ({ show, setShow }) => {
    const [alertVariant, setAlertVariant] = useState("danger");

    const handleClose = () => setShow(false);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    displayName: "",
                }}
                onSubmit={(
                    values,
                    { setStatus, setSubmitting, setFieldError }
                ) => {
                    setStatus();
                    organisationService.create(values.displayName).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                handleClose();
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError(
                                    "displayName",
                                    error.errors.DisplayName
                                );
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting }) => (
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New Organisation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Stack gap={3}>
                                <div className="form-group">
                                    <label htmlFor="displayName">
                                        Display Name
                                    </label>
                                    <Field
                                        name="displayName"
                                        type="text"
                                        autoFocus
                                        className={
                                            "form-control" +
                                            (errors.displayName &&
                                            touched.displayName
                                                ? " is-invalid"
                                                : "")
                                        }
                                    />
                                    <ErrorMessage
                                        name="displayName"
                                        component="div"
                                        className="invalid-feedback"
                                    />
                                </div>
                                {status && (
                                    <Alert
                                        variant={alertVariant}
                                        className="mt-3"
                                    >
                                        {status}
                                    </Alert>
                                )}
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button
                                    variant="primary"
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="me-2"
                                >
                                    {isSubmitting ? (
                                        <LoadingSpinner text="Adding..." />
                                    ) : (
                                        "Add"
                                    )}
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddNewOrganisation };
