import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType, SortOrder } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus, faPen, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { TimesheetType } from "../_constants/TimesheetType";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { IRole } from "@/customTypings/role";
import { Role } from "../_helpers";
import { useState } from "react";
import { EditTimesheetModel } from "../_components/EditTimesheetModel";

const TimesheetListPage = () => {
    const currentUser = useAtom(userAtom);
    const [showEdit, setShowEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const itemActions: IPagedListAction[] = [];

    const [refreshNumber, setRefeshNumber] = useState(0);

    const handleEdit = (updated: boolean) => {
        if (updated) {
            setRefeshNumber(refreshNumber + 1);
        }
        setShowEdit(false);
    };

    if (currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)) {
        itemActions.push({
            icon: faPen,
            text: "Edit",
            onClick: (item) => {
                setEditId(item.id);
                setShowEdit(true);
            },
            primary: true,
        } as IPagedListAction);
    }

    return (
        <>
            <PagedList
                columns={[
                    {
                        member: "identity",
                        renderAs: ColumnRender.identity,
                        title: "User",
                        width: 300,
                    } as IColumn,
                    {
                        member: "jobNumber",
                        renderAs: ColumnRender.text,
                        title: "Job Number",
                        width: 100,
                    } as IColumn,
                    {
                        member: "type",
                        renderAs: ColumnRender.text,
                        title: "Type",
                        width: 100,
                    } as IColumn,
                    {
                        member: "startDate",
                        renderAs: ColumnRender.dateTime,
                        title: "Start date",
                        width: 200,
                    } as IColumn,
                    {
                        member: "endDate",
                        renderAs: ColumnRender.dateTime,
                        title: "End date",
                        width: 200,
                    } as IColumn,
                    {
                        member: "time",
                        renderAs: ColumnRender.text,
                        title: "Time",
                        width: 100,
                    } as IColumn,
                ]}
                itemActions={itemActions}
                filters={[
                    {
                        title: "Assignee",
                        member: "displayName",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Job Number",
                        member: "jobNumber",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Type",
                        member: "type",
                        type: FilterType.select,
                        options: { All: null, Work: TimesheetType.Work, Travel: TimesheetType.Travel, Other: TimesheetType.Other },
                        defaultValue: null,
                    } as IFilter,
                    {
                        title: "Start from date",
                        member: "fromStartDate",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "Start to date",
                        member: "toStartDate",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "End from date",
                        member: "fromEndDate",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "End to date",
                        member: "toEndDate",
                        type: FilterType.date,
                    } as IFilter,
                    {
                        title: "Minimum Time",
                        member: "minTime",
                        type: FilterType.integer,
                    } as IFilter,
                    {
                        title: "Maximum Time",
                        member: "maxTime",
                        type: FilterType.integer,
                    } as IFilter,
                ]}
                defaultSortBy="startDate"
                defaultSortOrder={SortOrder.descending}
                getUrl="api/timesheet/all"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey="TimesheetListPage"
                exportUrl={currentUser?.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0) ? "api/timesheet/download" : undefined}
                exportIcon={faFileExport}
                refreshNumber={refreshNumber}
            />
            <EditTimesheetModel show={showEdit} onClose={handleEdit} timesheetId={editId} />
        </>
    );
};

export { TimesheetListPage };
