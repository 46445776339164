import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType, SortOrder } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { JobType, WaitTime } from "../_components/Badges";

type Props = {
    identityId?: string;
};

const PendingCallbackListPage: React.FC<Props> = ({ identityId }) => {
    const navigate = useNavigate();
    const columns: IColumn[] = [];
    const filters: IFilter[] = [];
    var staticFilterValues: Record<string, any> | undefined = undefined;
    const persistanceKey: string = identityId ? "MyPendingCallbacksList" : "AllPendingCallbacksList";

    columns.push({
        member: "customerNumber",
        renderAs: ColumnRender.text,
        title: "Customer Number",
        width: 100,
    } as IColumn);
    columns.push({
        member: "jobTypes",
        renderAs: ColumnRender.customRenderer,
        title: "Type",
        width: 200,
        customRenderer: (item) => {
            return (
                <td>
                    <Stack gap={2}>{item.jobTypes && item.jobTypes.map((type: string) => type && <JobType key={type} text={type} />)}</Stack>
                </td>
            );
        },
        canSort: false,
    } as IColumn);
    columns.push({
        member: "customerName",
        renderAs: ColumnRender.text,
        title: "Customer",
        width: 200,
    } as IColumn);
    columns.push({
        member: "postcode",
        renderAs: ColumnRender.text,
        title: "Postcode",
        width: 200,
    } as IColumn);
    columns.push({
        member: "approxDistance",
        renderAs: ColumnRender.text,
        title: "Approx. Distance",
        width: 200,
        canSort: false,
    } as IColumn);
    if (!identityId) {
        columns.push({
            member: "identity",
            renderAs: ColumnRender.identity,
            title: "Assigned To",
            width: 300,
        } as IColumn);
    }
    columns.push({
        member: "waitTime",
        renderAs: ColumnRender.customRenderer,
        title: "Wait Time",
        width: 200,
        customRenderer: (item) => {
            var currentWaitTime = item.waitTime;
            if (currentWaitTime >= 0) {
                return (
                    <td>
                        <WaitTime days={currentWaitTime} />
                    </td>
                );
            } else {
                return <td>Call arranged</td>;
            }
        },
    } as IColumn);
    columns.push({
        member: "scheduledCall",
        renderAs: ColumnRender.date,
        title: "Scheduled Call",
        width: 150,
    } as IColumn);
    columns.push({
        member: "isActive",
        renderAs: ColumnRender.checkCross,
        title: "Active",
        width: 100,
    } as IColumn);

    filters.push({
        title: "Customer number",
        member: "customerNumber",
        type: FilterType.text,
    } as IFilter);
    filters.push({
        title: "Customer name",
        member: "customerName",
        type: FilterType.text,
    } as IFilter);
    filters.push({
        title: "Enquiry from date",
        member: "fromEnquiryDate",
        type: FilterType.date,
    } as IFilter);
    filters.push({
        title: "Enquiry to date",
        member: "toEnquiryDate",
        type: FilterType.date,
    } as IFilter);
    filters.push({
        title: "Scheduled call from date",
        member: "fromScheduledCall",
        type: FilterType.date,
    } as IFilter);
    filters.push({
        title: "Scheduled call to date",
        member: "toScheduledCall",
        type: FilterType.date,
    } as IFilter);
    filters.push({
        title: "Active",
        member: "isActive",
        type: FilterType.select,
        options: { Yes: true, No: false, All: null },
        defaultValue: true,
    } as IFilter);
    if (identityId) {
        staticFilterValues = {
            identityId: identityId,
        } as Record<string, any>;
    }

    return (
        <>
            <PagedList
                columns={columns}
                itemActions={[
                    {
                        text: "Detail",
                        onClick: (item) => {
                            navigate(`/pendingcallbacks/${item.id}`);
                        },
                        primary: true,
                    } as IPagedListAction,
                ]}
                headActions={[
                    {
                        icon: faPlus,
                        text: "New Enquiry",
                        onClick: () => {
                            navigate(`/newenquiry`);
                        },
                    } as IPagedListAction,
                ]}
                staticFilterValues={staticFilterValues}
                filters={filters}
                defaultSortBy="waitTime"
                defaultSortOrder={SortOrder.descending}
                getUrl="api/job/allpendingcallbacks"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey={persistanceKey}
            />
        </>
    );
};

export { PendingCallbackListPage };
