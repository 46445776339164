import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { JobListDetailsListPage } from "../JobListDetails/JobListDetailsListPage";

const JobsPage = () => {
    return (
        <Container fluid>
            <Helmet>
                <title>Jobs</title>
            </Helmet>
            <Row>
                <Col>
                    <h1 className="my-3">Jobs</h1>
                    <JobListDetailsListPage />
                </Col>
            </Row>
        </Container>
    );
};

export { JobsPage };
