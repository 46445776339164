import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { proposalService } from "../../_services";
import { IProposal } from "../../customTypings/Proposal";
import { folderDefinitionService } from "../../_services/folderdefinition.service";
import { IFolderDefinitionFolder } from "@/customTypings/FolderDefinitionFolder";
import { ReactSelectBootstrapStyle } from "../../_styles/ReactSelectBootstrapStyle";
import Select from "react-select";

type Props = {
    show: boolean;
    onClose: (addedId: string | undefined) => void;
    jobId: string;
};

const AddProposalModal: React.FC<Props> = ({ show, onClose, jobId }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allFolderTemplates, setAllFolderTemplates] = useState<IFolderDefinitionFolder[]>([]);

    const handleClose = () => onClose(undefined);

    useEffect(() => {
        folderDefinitionService.getByType("proposal").then((folderTemplates: IFolderDefinitionFolder[]) => {
            const folderTemplatesValueAdded = folderTemplates.map((p) => {
                p.value = p.id;
                return p;
            });
            setAllFolderTemplates(folderTemplatesValueAdded);
        });
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    cost: 0,
                    isDNOPermissionRequired: false,
                    templateFolder: allFolderTemplates[0]?.id === undefined ? "" : allFolderTemplates[0]?.id,
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    var newProposal: IProposal = {
                        id: undefined,
                        name: "NAME",
                        cost: values.cost,
                        folderId: values.templateFolder,
                        isDNOPermissionRequired: values.isDNOPermissionRequired,
                        jobId: jobId,
                        status: "historic",
                    };
                    proposalService.addWithTemplateFolders(newProposal).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(response.id);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("cost", error.errors.Cost);
                                setFieldError("isDNOPermissionRequired", error.errors.IsDNOPermissionRequired);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New Proposal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3" controlId="addCheckbox">
                                <Form.Check
                                    type="checkbox"
                                    name="isDNOPermissionRequired"
                                    className={"mt-1 form-check" + (errors.isDNOPermissionRequired && touched.isDNOPermissionRequired ? " is-invalid" : "")}
                                    defaultChecked={values.isDNOPermissionRequired.valueOf()}
                                    onChange={(e) => {
                                        setFieldValue("isDNOPermissionRequired", e.target.checked == null ? false : e.target.checked);
                                    }}
                                    label="DNO Permission Required?"
                                />
                                <ErrorMessage name="isAllDay" component="div" className="invalid-feedback" />
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="costInput">
                                <Form.Label column sm={3}>
                                    Cost
                                </Form.Label>
                                <Col sm={9}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>£</InputGroup.Text>
                                        <Field name="cost" type="number" className={"form-control" + (errors.cost && touched.cost ? " is-invalid" : "")} />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                    </InputGroup>
                                    <ErrorMessage name="cost" component="div" className="invalid-feedback" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="templateFolderInput">
                                <Form.Label column sm={3}>
                                    Template Folder
                                </Form.Label>
                                <Col sm={9}>
                                    <Select
                                        defaultValue={allFolderTemplates[0]}
                                        placeholder="Select template folder..."
                                        styles={ReactSelectBootstrapStyle}
                                        className="stateManagedSelect"
                                        onChange={(value) => {
                                            if (value !== null) {
                                                setFieldValue("templateFolder", value.value == null ? "" : value.value);
                                            } else {
                                                setFieldValue("templateFolder", "");
                                            }
                                        }}
                                        isClearable
                                        options={allFolderTemplates}
                                        getOptionLabel={(option) => `${option["name"]}`}
                                    />
                                </Col>
                            </Form.Group>
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Creating proposal..." /> : "Create proposal"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { AddProposalModal };
