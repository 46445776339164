import { IJobField } from '@/customTypings/JobField'
import { IJobFieldDefinition } from '@/customTypings/JobFieldDefinition'
import { ErrorMessage } from 'formik'
import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { clone } from "../_helpers/utils";

type Props = {
    index: number
    jobFieldDefinition: IJobFieldDefinition | undefined
    jobFields: IJobField[]
    setFieldValue: any
    fieldValueName: string
    defaultValue?: string
    }

const JobFieldSelection: React.FC<Props> = (props) => {
    const selectValues: string[] = (props.jobFieldDefinition && props.jobFieldDefinition.selectValues ? props.jobFieldDefinition.selectValues.split(',') : [])

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <>
            {!props.jobFieldDefinition ?
                <p>Error</p> :
            <Form.Group as={Row} >
                <Form.Label column sm={labelSpan} htmlFor={props.jobFieldDefinition.name}>{props.jobFieldDefinition.name}</Form.Label>
                <Col sm={fieldSpan}>
                    <InputGroup>
                        {props.jobFieldDefinition.prefix && <InputGroup.Text>{props.jobFieldDefinition.prefix}</InputGroup.Text>}
                        {props.jobFieldDefinition.selectValues && selectValues ? 
                            <Form.Select aria-label="Default select example"
                            onChange={e =>
                                {
                                    var copy = clone<IJobField[]>(props.jobFields as IJobField[]);
                                    copy[props.index].value = e.target.value;
                                    props.setFieldValue(props.fieldValueName, copy)
                                }
                            }
                            defaultValue={props.defaultValue}>
                                {selectValues.map((value, i) =>
                                    <option key={i}>{value}</option>
                                )}
                            </Form.Select>
                        :
                            <Form.Control 
                                type="text" 
                                onChange={e =>
                                    {
                                        var copy = clone<IJobField[]>(props.jobFields as IJobField[]);
                                        copy[props.index].value = e.target.value;
                                        props.setFieldValue(props.fieldValueName, copy)
                                    }
                                }
                                defaultValue={props.defaultValue} />
                        }
                        {props.jobFieldDefinition.suffix && <InputGroup.Text>{props.jobFieldDefinition.suffix}</InputGroup.Text>}
                    </InputGroup>
                    <ErrorMessage name={props.jobFieldDefinition.name} component="div" className="invalid-feedback" />
                </Col>
            </Form.Group>
            }
        </>
    )
}

export default JobFieldSelection