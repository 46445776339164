import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { forgotPasswordService } from "../_services";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Image, Stack, Card } from "react-bootstrap";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { useAtom } from "@dbeining/react-atom";
import { userAtom } from "../_atoms/userAtom";
import { Helmet } from "react-helmet";

const ForgotPasswordPage = () => {
    let navigate = useNavigate();
    const currentUser = useAtom(userAtom);
    const [email, setEmail] = useState("");
    const [resetSent, setResetSent] = useState(false);

    useEffect(() => {
        // redirect to home if already logged in
        if (currentUser) {
            navigate("/", { state: { message: "Ok" } });
        }
    }, []);

    return (
        <Container fluid>
            <Helmet>
                <title>Forgot your password?</title>
            </Helmet>

            <div
                className="position-fixed top-0 start-0 vw-100 vh-100"
                style={{
                    backgroundImage: "url(SignInBackground.jpg)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    zIndex: "-1",
                }}
            ></div>
            <Row className="h-100">
                <Col lg={{ span: 5, offset: 1 }} md={{ span: 6 }} className="my-auto pb-5">
                    <Card>
                        <Card.Body>
                            <Image src="/ReactCrmBoilerplateLogo.png" style={{ height: "5rem" }} />
                            {!resetSent ? (
                                <>
                                    <h1 className="my-3">Forgot your password?</h1>
                                    <p>Enter your email address you use to sign in and we send you an email with instructions to reset your account.</p>
                                    <Formik
                                        initialValues={{
                                            email: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            email: Yup.string().required("email is required"),
                                        })}
                                        onSubmit={({ email }, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            forgotPasswordService.create(email).then(
                                                (response) => {
                                                    if (response.status !== "Failure") {
                                                        setEmail(email);
                                                        setResetSent(true);
                                                    } else {
                                                        setSubmitting(false);
                                                        setStatus(response.message);
                                                    }
                                                },
                                                (error) => {
                                                    setSubmitting(false);
                                                    setStatus(error);
                                                }
                                            );
                                        }}
                                    >
                                        {({ errors, status, touched, isSubmitting, handleSubmit }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Stack gap={3}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="email">Email address</Form.Label>
                                                        <Field name="email" type="text" className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")} />
                                                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Stack direction="horizontal" gap={3}>
                                                            <div className="ms-auto">
                                                                <Button variant="success" disabled={isSubmitting} type="submit">
                                                                    {isSubmitting ? <LoadingSpinner text="Loading..." /> : "Next"}
                                                                </Button>
                                                            </div>
                                                        </Stack>
                                                    </Form.Group>
                                                    {status && <div className={"alert alert-danger"}>{status}</div>}
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </>
                            ) : (
                                <>
                                    <h1 className="my-3">Reset instructions sent</h1>
                                    <p>Instructions on how to reset your password have been sent to {email}. Check your email and click the link that we've sent you.</p>
                                    <p>If you don't recieve the password reset message within a few minutes, please check your spam folder or other email filtering tools.</p>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="ms-auto">
                                            <Button
                                                variant="success"
                                                onClick={() =>
                                                    navigate("/", {
                                                        replace: true,
                                                    })
                                                }
                                            >
                                                Back to sign in
                                            </Button>
                                        </div>
                                    </Stack>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export { ForgotPasswordPage };
