import Container from "react-bootstrap/esm/Container";
import { IColumn, ColumnRender, IPagedListAction, IFilter, FilterType } from "../_components/PagedList/Declarations";
import { PagedList } from "../_components/PagedList/PagedList";
import { faPlus, faPen, faTrash, faTrashArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { IEmailTemplate } from "@/customTypings/EmailTemplate";
import { emailTemplateService } from "../_services";
import { useState } from "react";
import { removeHTMLTagsString } from "../_helpers/utils";

const EmailTemplateListPage = () => {
    const navigate = useNavigate();
    const [refreshNumber, setRefeshNumber] = useState(0);

    const handleDeleted = (item: IEmailTemplate) => {
        emailTemplateService.remove(item.id ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    setRefeshNumber(refreshNumber + 1);
                } else {
                    console.log(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    console.log(error.title);
                } else {
                    console.log(error);
                }
            }
        );
    };

    const handleRestore = (item: IEmailTemplate) => {
        emailTemplateService.restore(item.id ?? "").then(
            (response) => {
                if (response.status !== "Failure") {
                    setRefeshNumber(refreshNumber + 1);
                } else {
                    console.log(response.message);
                }
            },
            (error) => {
                if (error.status === 400) {
                    console.log(error.title);
                } else {
                    console.log(error);
                }
            }
        );
    };

    return (
        <Container fluid>
            <Helmet>
                <title>Email Templates</title>
            </Helmet>

            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/settings" }}>
                    Settings
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Email Templates</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className="my-3">Email Templates</h1>
            <PagedList
                columns={[
                    {
                        member: "name",
                        renderAs: ColumnRender.text,
                        title: "Name",
                        width: 200,
                    } as IColumn,
                    {
                        member: "subject",
                        renderAs: ColumnRender.text,
                        title: "Subject",
                        width: 400,
                    } as IColumn,
                    {
                        member: "body",
                        renderAs: ColumnRender.customRenderer,
                        title: "Body",
                        width: 500,
                        customRenderer: (item: IEmailTemplate) => {
                            return (
                                <td>
                                    <p className="truncate-to-450">{removeHTMLTagsString(item.body)}</p>
                                </td>
                            );
                        },
                    } as IColumn,
                    {
                        member: "isDeleted",
                        renderAs: ColumnRender.deleted,
                        title: "Deleted",
                        width: 100,
                    } as IColumn,
                ]}
                itemActions={[
                    {
                        icon: faPen,
                        text: "Edit",
                        onClick: (item) => {
                            navigate(`/EmailTemplates/` + item.id);
                        },
                    } as IPagedListAction,
                    {
                        icon: faTrash,
                        text: "Delete",
                        onClick: (item) => {
                            handleDeleted(item);
                        },
                        availableMember: "isDeleted",
                        availableFliped: true,
                    } as IPagedListAction,
                    {
                        icon: faTrashArrowUp,
                        text: "Restore",
                        onClick: (item) => {
                            handleRestore(item);
                        },
                        availableMember: "isDeleted",
                    } as IPagedListAction,
                ]}
                headActions={[
                    {
                        icon: faPlus,
                        text: "Add Entry",
                        onClick: () => {
                            navigate(`/EmailTemplates/add`);
                        },
                    } as IPagedListAction,
                ]}
                filters={[
                    {
                        title: "Name",
                        member: "name",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Subject",
                        member: "subject",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Body",
                        member: "body",
                        type: FilterType.text,
                    } as IFilter,
                    {
                        title: "Deleted",
                        member: "isDeleted",
                        type: FilterType.select,
                        options: { Yes: true, No: false, All: null },
                        defaultValue: false,
                    } as IFilter,
                ]}
                defaultSortBy="Name"
                getUrl="api/emailtemplate/all"
                avatarUrl="api/avatar"
                rowFormatter={(item: any) => {
                    return null;
                }}
                persistanceKey="EmailTemplateListPage"
                refreshNumber={refreshNumber}
            />
        </Container>
    );
};

export { EmailTemplateListPage };
