import React, { useState, useEffect } from "react";

import { Alert, Breadcrumb, Button, Col, Row, Stack, Image, ButtonGroup, ToggleButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faExclamationTriangle, faFolderPlus, faGripHorizontal, faInfoCircle, faList, faPlus, faRefresh, faTrash } from "@fortawesome/free-solid-svg-icons";
import { documentService } from "../_services";
import { IFolder } from "@/customTypings/Folder";
import { IDocument } from "@/customTypings/Document";
import { AddFolder } from "./AddFolder";
import { LoadingSpinner } from "./LoadingSpinner";
import { useFileUpload, FileUpload } from "use-file-upload";
import dayjs from "dayjs";
import { Avatar } from "./Avatar";
import { IRole } from "@/customTypings/role";
import { userAtom } from "../_atoms/userAtom";
import { useAtom } from "@dbeining/react-atom";
import { Role } from "../_helpers";

type Props = {
    baseFolder?: boolean;
    jobId?: string;
    defaultFolder?: string;
};

interface PathFolder {
    text: string;
    absolutePath: string;
}

const Documents: React.FC<Props> = ({ baseFolder = false, jobId, defaultFolder = "/" }) => {
    const currentUser = useAtom(userAtom);
    const [currentPath, setCurrentPath] = useState(defaultFolder);
    const [currentFolder, setCurrentFolder] = useState<IFolder>();
    const [disabled, setDisabled] = useState(!(jobId && jobId.length));
    const [folders, setFolders] = useState<IFolder[]>([]);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [alertMessage, setAlertMessage] = useState("");
    const [hasParentFolder, setHasParentFolder] = useState(false);
    const [showAddFolder, setShowAddFolder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [files, selectFiles] = useFileUpload();
    const [pathFolders, setPathFolders] = useState<PathFolder[]>([] as PathFolder[]);
    const [selectedView, setSelectedView] = useState("tile");
    const [removeActionActive, setRemoveActionActive] = useState(false);

    useEffect(() => {
        setDisabled(!(((jobId && jobId.length) || baseFolder) && currentFolder));
    }, [baseFolder, jobId, currentFolder]);

    useEffect(() => {
        if (baseFolder || jobId) {
            setAlertMessage("");

            const baseFolder = { text: "Root" + defaultFolder, absolutePath: defaultFolder } as PathFolder;

            const remainingPath = currentPath.substring(defaultFolder.length);
            const pathComponents = remainingPath === "/" ? [""] : remainingPath.split("/");
            let acc = defaultFolder;
            let pathFolders = [] as PathFolder[];
            pathFolders.push(baseFolder);

            const remainingFolders = pathComponents
                .filter((p) => p.length > 0)
                .map((p) => {
                    acc += (acc.endsWith("/") ? "" : "/") + p;
                    if (p.length === 0) p = "Root";
                    return { text: p, absolutePath: acc } as PathFolder;
                });

            pathFolders = pathFolders.concat(remainingFolders);
            setPathFolders(pathFolders);

            documentService
                .getFolderByPath(jobId, currentPath)
                .then((response: IFolder) => {
                    setCurrentFolder(response);
                })
                .catch((rejected) => {
                    setAlertMessage("Could not retrieve folder: " + rejected);
                });
        }
    }, [baseFolder, jobId, currentPath]);

    const getFoldersAndDocuments = (jobId?: string, currentFolderId?: string) => {
        setIsLoading(true);

        Promise.all([documentService.getFolders(jobId, currentFolderId), documentService.getDocuments(currentFolderId)])
            .then((response) => {
                setFolders((response[0].results as IFolder[]) || []);

                const documents = response[1].results.map((d: IDocument) => {
                    const dateAdded = dayjs(d.dateAdded);
                    d.dateAdded = dateAdded.format("DD/MM/YYYY HH:mm");
                    return d;
                });

                setDocuments((documents as IDocument[]) || []);
                setIsLoading(false);
            })
            .catch((rejected) => {
                setAlertMessage("Could not retrieve list of folders and documents: " + rejected);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if ((baseFolder || jobId) && currentFolder) {
            setAlertMessage("");
            setHasParentFolder((currentFolder.parentFolderId?.length ?? 0) !== 0);
            getFoldersAndDocuments(jobId, currentFolder.id);
        }
    }, [baseFolder, jobId, currentFolder]);

    const handleSelectFolder = (folder: IFolder) => {
        if (currentPath.endsWith("/")) setCurrentPath(currentPath + folder.name);
        else setCurrentPath(currentPath + "/" + folder.name);
    };

    const onUpFolder = () => {
        if (currentPath !== "/" && currentPath.length !== 0) {
            let newPath = currentPath.substring(0, currentPath.lastIndexOf("/"));
            if (!newPath.startsWith("/")) newPath = "/" + newPath;
            setCurrentPath(newPath);
        }
    };

    const handleFolderAdded = (added: boolean) => {
        setShowAddFolder(false);
        refresh();
    };

    const handleFileSelect = () => {
        if (currentFolder && (baseFolder || jobId)) {
            selectFiles({ accept: "", multiple: true }, (response) => {
                const files = (response as FileUpload[]).map((f) => f.file);

                documentService
                    .uploadFile(currentFolder?.id, files)
                    .then(() => {
                        refresh();
                    })
                    .catch((rejected) => {
                        setAlertMessage("Could not upload file: " + rejected);
                    });
            });
        }
    };

    const handleDownloadDocument = (document: IDocument) => {
        window.open("/api/document/download/" + document.id, "_blank", "noopener,noreferrer");
    };

    const handleRemoveDocument = (document: IDocument) => {
        documentService
            .removeDocument(document.id)
            .then(() => {
                refresh();
                setRemoveActionActive(false);
            })
            .catch((rejected) => {
                setAlertMessage("Could not remove file: " + rejected);
            });
    };

    const handleRemoveFolder = (folder: IFolder) => {
        documentService
            .removeFolder(folder.id)
            .then(() => {
                refresh();
                setRemoveActionActive(false);
            })
            .catch((rejected) => {
                setAlertMessage("Could not remove folder: " + rejected);
            });
    };

    const refresh = () => {
        if ((baseFolder || jobId) && currentFolder) {
            getFoldersAndDocuments(jobId, currentFolder.id);
        }
    };

    const tileIconStyle = { width: "100%", height: "7rem" };
    const tileButtonStyle = { width: "8rem" };
    const listIconStyle = { width: "2rem", height: "2rem" };
    const addButtonStyle = { width: "8rem", height: "8rem" };

    return (
        <>
            <Stack direction="vertical" gap={3}>
                {disabled && (
                    <Alert variant="info" className="mb-0">
                        <FontAwesomeIcon className="me-2" icon={faInfoCircle} size="lg" /> Please save before adding documents and photos.
                    </Alert>
                )}
                {alertMessage && (
                    <Alert variant="danger" className="mb-0">
                        <FontAwesomeIcon className="me-2" icon={faExclamationTriangle} size="lg" /> {alertMessage}
                    </Alert>
                )}

                <Row>
                    <Col xs="auto">
                        <Stack direction="horizontal" gap={2}>
                            <ButtonGroup>
                                <ToggleButton
                                    id={`radio-tile`}
                                    type="radio"
                                    variant="outline-success"
                                    name="view"
                                    value="tile"
                                    checked={selectedView === "tile"}
                                    onChange={(e) => setSelectedView("tile")}
                                    title="Tile view"
                                >
                                    <FontAwesomeIcon icon={faGripHorizontal} />
                                </ToggleButton>
                                <ToggleButton
                                    id={`radio-list`}
                                    type="radio"
                                    variant="outline-success"
                                    name="view"
                                    value="list"
                                    checked={selectedView === "list"}
                                    onChange={(e) => setSelectedView("list")}
                                    title="List view"
                                >
                                    <FontAwesomeIcon icon={faList} />
                                </ToggleButton>
                            </ButtonGroup>
                            <Button
                                variant="secondary"
                                disabled={disabled || (currentFolder?.isRestricted && !(currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)))}
                                onClick={() => setShowAddFolder(true)}
                            >
                                <FontAwesomeIcon className="me-2" icon={faFolderPlus} />
                                New Folder
                            </Button>
                            <Button variant="secondary" disabled={disabled || !hasParentFolder || currentPath === defaultFolder} onClick={() => onUpFolder()}>
                                <FontAwesomeIcon className="me-2" icon={faArrowUp} />
                                Up Folder
                            </Button>
                            <Button variant="secondary" disabled={disabled} onClick={() => refresh()}>
                                {isLoading ? <LoadingSpinner /> : <FontAwesomeIcon icon={faRefresh} />}
                            </Button>
                            <span className="ps-2">Current folder:</span>
                        </Stack>
                    </Col>
                    <Col className="ps-0">
                        <div className="border bg-light px-2" style={{ lineHeight: "2.4em" }}>
                            <Breadcrumb className="path">
                                {pathFolders &&
                                    pathFolders.map((p) => (
                                        <Breadcrumb.Item
                                            key={p.absolutePath}
                                            onClick={() => {
                                                setCurrentPath(p.absolutePath);
                                            }}
                                        >
                                            {p.text}
                                        </Breadcrumb.Item>
                                    ))}
                            </Breadcrumb>
                        </div>
                    </Col>
                </Row>

                {/* Tile view */}

                {selectedView === "tile" && (
                    <Row>
                        {folders &&
                            folders.map((folder) => (
                                <div className="w-auto d-inline-block pe-0 mb-2" key={folder.id}>
                                    <Button
                                        variant={removeActionActive ? "outline-danger" : "outline-primary"}
                                        disabled={disabled}
                                        style={tileButtonStyle}
                                        className="border-0 lh-1"
                                        onClick={() => (removeActionActive ? handleRemoveFolder(folder) : handleSelectFolder(folder))}
                                    >
                                        {removeActionActive && <FontAwesomeIcon icon={faTrash} />}
                                        <Image src={`/api/thumbnail/a43ab016-c96c-4911-86cd-b3a9687d89c2`} style={tileIconStyle} className="w-100 image-contain mb-2" />
                                        <span className="text-center w-100">{folder.name}</span>
                                    </Button>
                                </div>
                            ))}
                        {documents &&
                            documents.map((document) => (
                                <div className="w-auto d-inline-block pe-0 mb-2" key={document.id}>
                                    <Button
                                        variant={removeActionActive ? "outline-danger" : "outline-primary"}
                                        disabled={disabled}
                                        style={tileButtonStyle}
                                        className="border-0 lh-1"
                                        onClick={() => (removeActionActive ? handleRemoveDocument(document) : handleDownloadDocument(document))}
                                    >
                                        {removeActionActive && <FontAwesomeIcon icon={faTrash} />}
                                        <Image src={`/api/thumbnail/${document.documentThumbnailId}`} style={tileIconStyle} className="w-100 image-contain mb-2" />
                                        <span className="text-center w-100">{document.fileName}</span>
                                    </Button>
                                </div>
                            ))}
                        <div className="w-auto d-inline-block mb-2">
                            <Stack direction="vertical">
                                <Button
                                    variant="outline-primary"
                                    disabled={disabled || (currentFolder?.isRestricted && !(currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)))}
                                    style={addButtonStyle}
                                    onClick={() => handleFileSelect()}
                                >
                                    <Stack direction="vertical" gap={1}>
                                        <FontAwesomeIcon icon={faPlus} size="3x" />
                                        <span>Add File</span>
                                    </Stack>
                                </Button>
                            </Stack>
                        </div>
                        <div className="w-auto d-inline-block mb-2">
                            <Stack direction="vertical">
                                <Button
                                    variant="outline-danger"
                                    disabled={disabled || (currentFolder?.isRestricted && !(currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)))}
                                    style={addButtonStyle}
                                    onClick={() => setRemoveActionActive(!removeActionActive)}
                                >
                                    <Stack direction="vertical" gap={1}>
                                        <FontAwesomeIcon icon={faTrash} size="3x" />
                                        <span>Delete File</span>
                                    </Stack>
                                </Button>
                            </Stack>
                        </div>
                    </Row>
                )}

                {/* List view */}

                {selectedView === "list" && (
                    <Stack direction="vertical">
                        <div className="bg-light px-2 py-1 mb-2">
                            <Row>
                                <Col xs={6}>Name</Col>
                                <Col xs={3}>Date modified</Col>
                                <Col xs={3}>User</Col>
                            </Row>
                        </div>

                        {folders &&
                            folders.map((folder) => (
                                <Button
                                    key={folder.id}
                                    variant={removeActionActive ? "outline-danger" : "outline-primary"}
                                    disabled={disabled}
                                    className="w-100 mb-1 border-0"
                                    onClick={() => (removeActionActive ? handleRemoveFolder(folder) : handleSelectFolder(folder))}
                                >
                                    <Stack direction="horizontal" gap={2}>
                                        {removeActionActive && <FontAwesomeIcon icon={faTrash} />}
                                        <Image src={`/api/thumbnail/a43ab016-c96c-4911-86cd-b3a9687d89c2`} style={listIconStyle} className="image-contain" />
                                        {folder.name}
                                    </Stack>
                                </Button>
                            ))}
                        {documents &&
                            documents.map((document) => (
                                <Button
                                    key={document.id}
                                    variant={removeActionActive ? "outline-danger" : "outline-primary"}
                                    disabled={disabled}
                                    className="w-100 mb-1 border-0 text-start"
                                    onClick={() => (removeActionActive ? handleRemoveDocument(document) : handleDownloadDocument(document))}
                                >
                                    <Row>
                                        <Col xs={6}>
                                            <Stack direction="horizontal" gap={2}>
                                                {removeActionActive && <FontAwesomeIcon icon={faTrash} />}
                                                <Image src={`/api/thumbnail/${document.documentThumbnailId}`} style={listIconStyle} className="image-contain" />
                                                {document.fileName}
                                            </Stack>
                                        </Col>
                                        <Col xs={3} className="lh-lg">
                                            {`${document.dateAdded}`}
                                        </Col>
                                        <Col xs={3} className="lh-lg">
                                            <Avatar identity={document.identity} />
                                        </Col>
                                    </Row>
                                </Button>
                            ))}
                        <div>
                            <Button
                                variant="outline-primary"
                                disabled={disabled || (currentFolder?.isRestricted && !(currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)))}
                                className="w-auto mb-1 text-start"
                                onClick={() => handleFileSelect()}
                            >
                                <Stack direction="horizontal" gap={2}>
                                    <FontAwesomeIcon icon={faPlus} size="2x" className="me-1" />
                                    Add File
                                </Stack>
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="outline-danger"
                                disabled={disabled || (currentFolder?.isRestricted && !(currentUser && currentUser.identity.roles.some((role: IRole) => [Role.Admin].indexOf(role.name) >= 0)))}
                                className="w-auto mb-1 text-start"
                                onClick={() => setRemoveActionActive(!removeActionActive)}
                            >
                                <Stack direction="horizontal" gap={2}>
                                    <FontAwesomeIcon icon={faTrash} size="2x" className="me-1" />
                                    Delete File
                                </Stack>
                            </Button>
                        </div>
                    </Stack>
                )}
            </Stack>
            <AddFolder jobId={jobId} parentFolderId={currentFolder?.id} show={showAddFolder} onClose={handleFolderAdded} />
        </>
    );
};

export { Documents };
