import { ITask } from "@/customTypings/Task";
import { Documents } from "../_components/Documents";
import { jobService, proposalService, taskService } from "../_services";
import { useEffect, useState } from "react";
import { Button, Container, Form, Stack, Tab, Tabs, Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TaskDetails } from "./TaskDetails";
import { ErrorMessage, Field, Formik } from "formik";
import { LoadingSpinner } from "../_components/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPaperPlane, faPenToSquare, faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { TaskType } from "../_constants/TaskType";
import { NextActionsModel } from "../_components/NextAcrionsModel";
import { CreateTask } from "../_components/Task/CreateTask";
import { EditGivenTask } from "../_components/Task/EditGivenTask";
import { ITaskFlow, TaskFlowNextAction } from "../_components/Task/TaskFlow";
import { IProposal } from "@/customTypings/Proposal";
import { AddProposalModal } from "../_components/Task/AddProposalModal";
import { clone, currencyFormat } from "../_helpers/utils";
import { EditProposalModal } from "../_components/Task/EditProposalModal";
import { IJob } from "../customTypings/Job";
import dayjs from "dayjs";
import { EventCalendar } from "../_components/EventCalendar/EventCalendar";
import { TaskChecklists } from "./TaskChecklists";
import { ITaskMetadata } from "../customTypings/TaskMetadata";
import { TaskInvoices } from "./TaskInvoices";
import { TaskPlantMachineryChecklistFull } from "./TaskPlantMachineryChecklistFull";
import { ITaskChecklistItem } from "../customTypings/TaskChecklistItem";
import { TaskChecklistItem } from "./TaskChecklistItem";
import { JobStatus } from "../_constants/JobStatus";
import { AddDNOMetadataModal } from "../_components/Task/AddDNOMetadataModal";
import { SendProposalModal } from "../_components/Task/SendProposalModal";

interface LocationState {
    from?: {
        pathname: string;
    };
}

const TaskPage = () => {
    let params = useParams();
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state ? (location.state as LocationState).from?.pathname || "/" : "/";

    const [task, setTask] = useState<ITask>();
    const [showNextActions, setShowNextActions] = useState(false);
    const [showNextActionsTasks, setShowNextActionsTasks] = useState(false);
    const [nextActionsTaskTypes, setNextActionsTaskTypes] = useState<TaskType[]>();
    const [showEditTasks, setShowEditTasks] = useState(false);
    const [editTaskEditable, setEditTaskEditable] = useState<string[]>([]);
    const [showAddProposalModal, setShowAddProposalModal] = useState(false);
    const [showUpdateProposalModal, setShowUpdateProposalModal] = useState(false);
    const [showSendProposalModal, setShowSendProposalModal] = useState(false);
    const [showAddDNOMetadata, setShowAddDNOMetadata] = useState(false);
    const [editingProposal, setEditingProposals] = useState<IProposal>({} as IProposal);

    const [job, setJob] = useState<IJob>();
    const [checklist, setChecklist] = useState<ITaskChecklistItem[]>();

    const [actions, setActions] = useState<ITaskFlow[]>([]);
    const [proposals, setProposals] = useState<IProposal[]>([]);
    const [currentProposalId, setCurrentProposalId] = useState("addTab");
    const [currentProposal, setCurrentProposal] = useState<IProposal>({} as IProposal);
    const [nextActionsInfo, setNextActionsInfo] = useState<string | undefined>();

    const [calendarVisable, setCalendarVisable] = useState<boolean>(false);

    const handleNextActionSelection = (action: string) => {
        const dnoRequired = proposals.find((p) => p.status === "accepted")?.isDNOPermissionRequired ?? false;

        setShowNextActions(false);
        if (action.length > 0) {
            var tasksToBeCreated = clone(actions.find((item) => item.value === action));
            if (dnoRequired) {
                tasksToBeCreated?.tasks.push(TaskType.DNO);
            }
            if (tasksToBeCreated?.closeJob) {
                jobService.disable(task?.jobId ?? "");
            } else if (tasksToBeCreated?.tasks && tasksToBeCreated?.tasks.length > 0) {
                setNextActionsTaskTypes(tasksToBeCreated.tasks);
                setShowNextActionsTasks(true);
            } else {
                taskService.complete(task?.id ?? "").then(
                    (response) => {
                        if (response.status !== "Failure") {
                            navigate(from, {
                                replace: true,
                            });
                        } else {
                            console.log("task complete: error " + response.message);
                        }
                    },
                    (error) => {
                        if (error.status === 400) {
                            console.log("task complete: error " + error);
                        } else {
                            console.log("task complete: error " + error);
                        }
                    }
                );
            }
        }
    };

    const handleShowEdit = (editable: string[]) => {
        setEditTaskEditable(editable);
        setShowEditTasks(true);
    };

    useEffect(() => {
        getTask();
    }, [params.id]);

    const getTask = () => {
        if (params.id) {
            taskService.get(params.id).then((task) => {
                setTask(task);
                setActions(TaskFlowNextAction(task.taskType.toString()));
                if (task.taskType.toString() === TaskType[TaskType.Design] || task.taskType.toString() === TaskType[TaskType.Redesign]) {
                    getProposals(task.jobId);
                }
                if (task.taskType.toString() === TaskType[TaskType.SetInstallationDate] || task.taskType.toString() === TaskType[TaskType.EquipmentReminder]) {
                    getJob(task.jobId);
                }
                if (task.taskType.toString() === TaskType[TaskType.Checklists]) {
                    getChecklist(task.id);
                }
            });
        }
    };

    const getJob = (jobId: string) => {
        jobService.get(jobId).then((job: IJob) => {
            setJob(job);
        });
    };

    const getChecklist = (taskId: string) => {
        taskService.getChecklist(taskId).then((checklist: ITaskChecklistItem[]) => {
            setChecklist(checklist);
        });
    };

    const getProposals = (jobId: string, selectedProposalId?: string) => {
        proposalService.getAllForJob(jobId).then((proposals: IProposal[]) => {
            setProposals(proposals);
            setCurrentProposalId(selectedProposalId ?? proposals[0]?.id ?? "addTab");
            handleSetCurrentProposal(clone(proposals.find((proposal) => proposal.status === "accepted") ?? ({} as IProposal)));
        });
    };

    const handleAddProposal = (added: string | undefined) => {
        if (added) {
            getProposals(task?.jobId ?? "", added);
        }
        setShowAddProposalModal(false);
    };

    const handleSetProposalChosen = (selectedId: string) => {
        proposalService.setProposalChosen(selectedId).then(
            (response) => {
                if (response.status !== "Failure") {
                    var updatedProposals: IProposal[] = [];
                    proposals.map((proposal) => {
                        if (proposal.id === selectedId) {
                            proposal.status = "accepted";
                            handleSetCurrentProposal(clone(proposal));
                        } else {
                            proposal.status = "historic";
                        }
                        updatedProposals.push(proposal);
                    });
                    setProposals(updatedProposals);
                } else {
                    console.log(response.message);
                }
            },
            (error) => {
                console.log("SetProposalChosen: ", error);
            }
        );
    };

    const handleShowUpdateProposal = (proposal: IProposal) => {
        setEditingProposals(proposal);
        setShowUpdateProposalModal(true);
    };

    const handleUpdateProposal = (updated: string | undefined) => {
        if (updated) {
            getProposals(task?.jobId ?? "", updated);
        }
        setShowUpdateProposalModal(false);
    };

    const handleSetCurrentProposal = (proposal: IProposal) => {
        setCurrentProposal(proposal);
        if (proposal.isDNOPermissionRequired) {
            setNextActionsInfo("Proposal " + proposal.name + " selected.  This proposal requires a DNO task to be created.");
        } else {
            setNextActionsInfo("Proposal " + proposal.name + " selected.");
        }
    };

    const handleShowSendProposal = (proposal: IProposal) => {
        setEditingProposals(proposal);
        setShowSendProposalModal(true);
    };

    const handleSendProposal = (sent: boolean) => {
        setShowSendProposalModal(false);
    };

    return (
        <Container fluid>
            <Helmet>
                <title>Task Page</title>
            </Helmet>
            {task && (
                <Stack gap={3}>
                    <h1 className="my-3">{task.name}</h1>
                    <Stack direction="horizontal" gap={3}>
                        <Button
                            variant="primary"
                            onClick={() => setShowNextActions(true)}
                            disabled={
                                ((task.taskType.toString() === TaskType[TaskType.Design] || task.taskType.toString() === TaskType[TaskType.Redesign]) && currentProposal.status !== "accepted") ||
                                (task.taskType.toString() === TaskType[TaskType.DNO] && task.actionDate == undefined) ||
                                (task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && task.metadata?.find((item) => item.name === "EquipmentOrderd")?.value !== "true") ||
                                (task.taskType.toString() === TaskType[TaskType.AcceptanceByCustomer] && task.metadata?.find((item) => item.name === "AcceptanceByCustomer")?.value !== "true") ||
                                (task.taskType.toString() === TaskType[TaskType.Checklists] && checklist?.some((item) => !item.isComplete))
                            }
                        >
                            <FontAwesomeIcon icon={faCheck} /> Mark Completed
                        </Button>
                        {task.taskType.toString() === TaskType[TaskType.DNO] && (
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setShowAddDNOMetadata(true);
                                }}
                                disabled={task.actionDate != undefined}
                            >
                                Mark Request sent
                            </Button>
                        )}
                        <Stack direction="horizontal" gap={3} className="ms-auto">
                            <Button variant="secondary" onClick={() => handleShowEdit(["assignee"])}>
                                Reassign
                            </Button>
                            <Button variant="secondary" onClick={() => handleShowEdit(["dueDate"])}>
                                Change Due Date
                            </Button>
                            <Button variant="outline-secondary" onClick={() => navigate("/jobs/" + task.jobId)}>
                                Job Details
                            </Button>
                        </Stack>
                    </Stack>
                    <TaskDetails task={task} />
                    {task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && (
                        <Form noValidate>
                            <Stack gap={3}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={3} htmlFor="equipmentOrderd">
                                        Reserved equipment has been orderd
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Check
                                            type="switch"
                                            name="equipmentOrderd"
                                            defaultChecked={task.metadata?.find((item) => item.name === "EquipmentOrderd")?.value === "true"}
                                            className={"form-check"}
                                            onChange={(e) => {
                                                const checked = e.target.checked == null ? false : e.target.checked;

                                                const updatedTask: ITask = clone(task);
                                                var updatedMetadata = updatedTask.metadata?.map((item) => {
                                                    if (item.name === "EquipmentOrderd") {
                                                        item.value = checked.toString();
                                                    }
                                                    return item;
                                                });
                                                if (!task.metadata?.some((item) => item.name === "EquipmentOrderd")) {
                                                    const newMetadata: ITaskMetadata = {
                                                        id: undefined,
                                                        name: "EquipmentOrderd",
                                                        value: checked.toString(),
                                                    };
                                                    if (updatedMetadata) {
                                                        updatedMetadata = [...updatedMetadata, newMetadata];
                                                    } else {
                                                        updatedMetadata = [newMetadata];
                                                    }
                                                }
                                                updatedTask.metadata = updatedMetadata;
                                                taskService.edit(updatedTask).then(
                                                    () => {
                                                        setTask(updatedTask);
                                                    } //,
                                                    //error => {
                                                    //    setSubmitting(false);
                                                    //    if(error.status === 400){
                                                    //        setFieldError('equipmentOrderd', error.errors.equipmentOrderd)
                                                    //        setStatus(error.title);
                                                    //    } else {
                                                    //        setStatus(error);
                                                    //    }
                                                    //}
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Stack>
                        </Form>
                    )}
                    {task.taskType.toString() === TaskType[TaskType.AcceptanceByCustomer] && (
                        <Form noValidate>
                            <Stack gap={3}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm={3} htmlFor="acceptanceByCustomer">
                                        Customer has accepted
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Check
                                            type="switch"
                                            name="acceptanceByCustomer"
                                            defaultChecked={task.metadata?.find((item) => item.name === "AcceptanceByCustomer")?.value === "true"}
                                            className={"form-check"}
                                            onChange={(e) => {
                                                const checked = e.target.checked == null ? false : e.target.checked;

                                                const updatedTask: ITask = clone(task);
                                                var updatedMetadata = updatedTask.metadata?.map((item) => {
                                                    if (item.name === "AcceptanceByCustomer") {
                                                        item.value = checked.toString();
                                                    }
                                                    return item;
                                                });
                                                if (!task.metadata?.some((item) => item.name === "AcceptanceByCustomer")) {
                                                    const newMetadata: ITaskMetadata = {
                                                        id: undefined,
                                                        name: "AcceptanceByCustomer",
                                                        value: checked.toString(),
                                                    };
                                                    if (updatedMetadata) {
                                                        updatedMetadata = [...updatedMetadata, newMetadata];
                                                    } else {
                                                        updatedMetadata = [newMetadata];
                                                    }
                                                }
                                                updatedTask.metadata = updatedMetadata;
                                                taskService.edit(updatedTask).then(
                                                    () => {
                                                        setTask(updatedTask);
                                                    } //,
                                                    //error => {
                                                    //    setSubmitting(false);
                                                    //    if(error.status === 400){
                                                    //        setFieldError('AcceptanceByCustomer', error.errors.ecceptanceByCustomer)
                                                    //        setStatus(error.title);
                                                    //    } else {
                                                    //        setStatus(error);
                                                    //    }
                                                    //}
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                            </Stack>
                        </Form>
                    )}
                    {checklist !== undefined && <TaskChecklistItem checklist={checklist} onUpdate={(updatedChecklist) => setChecklist(updatedChecklist)} />}
                    <Formik
                        initialValues={{
                            notes: task.notes,
                        }}
                        onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                            setStatus();
                            const updatedTask: ITask = task;
                            updatedTask.notes = values.notes;
                            taskService.edit(updatedTask).then(
                                (response) => {
                                    setSubmitting(false);
                                    if (response.status !== "Failure") {
                                    } else {
                                        setStatus(response.message);
                                    }
                                },
                                (error) => {
                                    setSubmitting(false);
                                    if (error.status === 400) {
                                        setFieldError("notes", error.errors.notes);
                                        setStatus(error.title);
                                    } else {
                                        setStatus(error);
                                    }
                                }
                            );
                        }}
                    >
                        {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue, handleChange }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3" controlId="jobNotes">
                                    <Form.Label column sm={3}>
                                        Notes
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control as="textarea" name="notes" value={values.notes ?? ""} onChange={handleChange} isValid={touched.notes && !errors.notes} />
                                    </Col>
                                </Form.Group>
                                <Stack direction="horizontal" gap={3} className="mb-3">
                                    <div className="ms-auto">
                                        <Button variant="success" disabled={isSubmitting || values.notes === task.notes} type="submit">
                                            {isSubmitting ? <LoadingSpinner text="Saving notes..." /> : "Save notes"}
                                        </Button>
                                    </div>
                                </Stack>
                                {status && <div className={"alert alert-danger"}>{status}</div>}
                            </Form>
                        )}
                    </Formik>

                    {task.taskType.toString() === TaskType[TaskType.DNO] && (
                        <Tabs defaultActiveKey={"documents"} className="mb-3">
                            <Tab eventKey="documents" title="Documents">
                                <Documents jobId={task.jobId} defaultFolder={"/dno"} />
                            </Tab>
                        </Tabs>
                    )}

                    {(task.taskType.toString() === TaskType[TaskType.Design] || task.taskType.toString() === TaskType[TaskType.Redesign]) && (
                        <div>
                            <h2>Proposals</h2>
                            <Tabs
                                id="controlled-tab-proposals"
                                activeKey={currentProposalId ?? "addTab"}
                                className="mb-3"
                                onSelect={(k) => {
                                    if (k === "addTab") {
                                        setShowAddProposalModal(true);
                                    } else {
                                        if (k) setCurrentProposalId(k);
                                    }
                                }}
                            >
                                {proposals &&
                                    proposals.map((proposal) => (
                                        <Tab
                                            eventKey={proposal.id}
                                            title={
                                                <>
                                                    {proposal.name} {proposal.status === "accepted" && <FontAwesomeIcon icon={faStar} />}
                                                </>
                                            }
                                            key={proposal.id}
                                        >
                                            <Stack gap={3}>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            <Col md={3}>DNO Permission Required?</Col>
                                                            <Col md={9}>{proposal.isDNOPermissionRequired ? "yes" : "no"}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={3}>Cost</Col>
                                                            <Col md={9}>{proposal.cost ? currencyFormat(proposal.cost) : "-"}</Col>
                                                        </Row>
                                                    </Col>
                                                    <Col md="auto">
                                                        <Button
                                                            variant="secondary"
                                                            onClick={() => {
                                                                handleShowSendProposal(proposal);
                                                            }}
                                                            className="me-2"
                                                        >
                                                            <FontAwesomeIcon icon={faPaperPlane} /> Send proposal
                                                        </Button>

                                                        <Button
                                                            variant="secondary"
                                                            onClick={() => {
                                                                handleShowUpdateProposal(proposal);
                                                            }}
                                                            className="me-2"
                                                        >
                                                            <FontAwesomeIcon icon={faPenToSquare} /> Update Proposal
                                                        </Button>

                                                        <Button
                                                            variant="outline-success"
                                                            onClick={() => {
                                                                handleSetProposalChosen(proposal.id ?? "");
                                                            }}
                                                            className="me-2"
                                                            disabled={proposal.status === "accepted"}
                                                        >
                                                            {proposal.status === "accepted" ? <FontAwesomeIcon icon={faStar} size="lg" /> : <FontAwesomeIcon icon={farStar} size="lg" />}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Documents jobId={task.jobId} defaultFolder={"/proposals/" + proposal.name} />
                                            </Stack>
                                        </Tab>
                                    ))}

                                <Tab eventKey="addTab" title={<FontAwesomeIcon icon={faPlus} />}>
                                    <p>No current proposal.</p>
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            setShowAddProposalModal(true);
                                        }}
                                        className="me-2"
                                    >
                                        <FontAwesomeIcon icon={faPlus} /> Add Proposal
                                    </Button>
                                </Tab>
                            </Tabs>
                        </div>
                    )}

                    <Tabs
                        defaultActiveKey={job ? "details" : "documents"}
                        onSelect={(key) => {
                            setCalendarVisable(key === "calendar");
                        }}
                        className="mb-3"
                        mountOnEnter
                    >
                        {job && (
                            <Tab eventKey="details" title="Details">
                                <>
                                    {task.taskType.toString() === TaskType[TaskType.EquipmentReminder] ? (
                                        <Row>
                                            <Col xs={3}>Equipment location</Col>
                                            <Col>
                                                <Form.Control as="textarea" name="equipmentLocation" value={job.equipmentLocation ?? ""} disabled />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Formik
                                            initialValues={{
                                                equipmentLocation: job.equipmentLocation,
                                                installationDate: dayjs(job.installationDate).format("YYYY-MM-DD"),
                                            }}
                                            onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                                                setStatus();
                                                const updatedJob: IJob = job;
                                                updatedJob.equipmentLocation = values.equipmentLocation;
                                                updatedJob.installationDate = new Date(values.installationDate);
                                                jobService.edit(updatedJob).then(
                                                    (response) => {
                                                        setSubmitting(false);
                                                        if (response.status !== "Failure") {
                                                        } else {
                                                            setStatus(response.message);
                                                        }
                                                    },
                                                    (error) => {
                                                        setSubmitting(false);
                                                        if (error.status === 400) {
                                                            setFieldError("equipmentLocation", error.errors.equipmentLocation);
                                                            setFieldError("installationDate", error.errors.installationDate);
                                                            setStatus(error.title);
                                                        } else {
                                                            setStatus(error);
                                                        }
                                                    }
                                                );
                                            }}
                                        >
                                            {({ values, errors, status, touched, isSubmitting, handleSubmit, setFieldValue, handleChange }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Form.Group as={Row} className="mb-3" controlId="jobInstallationDate">
                                                        <Form.Label column sm={3}>
                                                            Installation Date
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Field
                                                                name="installationDate"
                                                                type="date"
                                                                autoFocus
                                                                className={"form-control" + (errors.installationDate && touched.installationDate ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage name="installationDate" component="div" className="invalid-feedback" />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} className="mb-3" controlId="equipmentLocation">
                                                        <Form.Label column sm={3}>
                                                            Equipment location
                                                        </Form.Label>
                                                        <Col sm={9}>
                                                            <Form.Control
                                                                as="textarea"
                                                                name="equipmentLocation"
                                                                value={values.equipmentLocation ?? ""}
                                                                onChange={handleChange}
                                                                isValid={touched.equipmentLocation && !errors.equipmentLocation}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Stack direction="horizontal" gap={3} className="mb-3">
                                                        <div className="ms-auto">
                                                            <Button
                                                                variant="success"
                                                                disabled={
                                                                    isSubmitting ||
                                                                    (values.equipmentLocation === job.equipmentLocation && values.installationDate === dayjs(job.installationDate).format("YYYY-MM-DD"))
                                                                }
                                                                type="submit"
                                                            >
                                                                {isSubmitting ? <LoadingSpinner text="Saving..." /> : "Save"}
                                                            </Button>
                                                        </div>
                                                    </Stack>
                                                    {status && <div className={"alert alert-danger"}>{status}</div>}
                                                </Form>
                                            )}
                                        </Formik>
                                    )}
                                </>
                            </Tab>
                        )}
                        {task.taskType.toString() !== TaskType[TaskType.Design] && task.taskType.toString() !== TaskType[TaskType.Redesign] && task.taskType.toString() !== TaskType[TaskType.DNO] && (
                            <Tab eventKey="documents" title="Documents">
                                <Documents jobId={task.jobId} />
                            </Tab>
                        )}
                        {task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && (
                            <Tab eventKey="checklists" title="Checklists">
                                <TaskChecklists
                                    task={task}
                                    onEdit={(updated) => {
                                        if (updated) {
                                            getTask();
                                        }
                                    }}
                                />
                            </Tab>
                        )}
                        {task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && (
                            <Tab eventKey="plantmachinerychecklist" title="Plant Machinery Checklist">
                                <TaskPlantMachineryChecklistFull
                                    task={task}
                                    onEdit={(updated) => {
                                        if (updated) {
                                            getTask();
                                        }
                                    }}
                                />
                            </Tab>
                        )}
                        {task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && (
                            <Tab eventKey="invoices" title="Invoices">
                                <TaskInvoices
                                    task={task}
                                    onEdit={(updated) => {
                                        if (updated) {
                                            getTask();
                                        }
                                    }}
                                />
                            </Tab>
                        )}
                        {task.taskType.toString() === TaskType[TaskType.SetInstallationDate] && (
                            <Tab eventKey="calendar" title="Calendar">
                                <div style={{ height: 500 }}>
                                    <EventCalendar newEventAllowed={true} updateEventAllowed={true} isTask={true} taskId={task.id} jobId={task.jobId} visable={calendarVisable} />
                                </div>
                            </Tab>
                        )}
                    </Tabs>
                    <NextActionsModel show={showNextActions} onClose={handleNextActionSelection} actions={actions} info={nextActionsInfo} />
                    <CreateTask
                        show={showNextActionsTasks}
                        onClose={(added) => {
                            setShowNextActionsTasks(false);
                            if (added) {
                                navigate(from, {
                                    replace: true,
                                });
                            }
                        }}
                        jobId={task.jobId}
                        tasktypes={nextActionsTaskTypes}
                        sorceTask={task}
                        jobStatus={
                            task.taskType.toString() === TaskType[TaskType.SetInstallationDate]
                                ? JobStatus.job
                                : task.taskType.toString() === TaskType[TaskType.Review]
                                ? JobStatus.complete
                                : undefined
                        }
                    />
                    <EditGivenTask
                        show={showEditTasks}
                        onClose={(updated) => {
                            if (updated) {
                                getTask();
                            }
                            setShowEditTasks(false);
                        }}
                        task={task}
                        editable={editTaskEditable}
                    />
                    <AddDNOMetadataModal
                        show={showAddDNOMetadata}
                        onClose={(updated) => {
                            if (updated) {
                                getTask();
                            }
                            setShowAddDNOMetadata(false);
                        }}
                        task={task}
                    />
                    {task.jobId && <AddProposalModal show={showAddProposalModal} onClose={(added) => handleAddProposal(added)} jobId={task.jobId} />}
                    {task.jobId && <EditProposalModal show={showUpdateProposalModal} onClose={(updated) => handleUpdateProposal(updated)} proposal={editingProposal} />}
                    {task.jobId && <SendProposalModal show={showSendProposalModal} onClose={(updated) => handleSendProposal(updated)} proposal={editingProposal} />}
                </Stack>
            )}
        </Container>
    );
};

export { TaskPage };
