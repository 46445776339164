import { ITask } from "@/customTypings/Task";
import { taskService, userService } from "../_services";
import { useEffect, useState } from "react";
import { Row, Col, Stack, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ITaskMetadata } from "../customTypings/TaskMetadata";
import { clone } from "../_helpers/utils";
import { ITaskChecklistItem } from "../customTypings/TaskChecklistItem";
import { TaskPlantMachineryChecklist } from "./TaskPlantMachineryChecklist";
import { IValueAndLabel } from "@/customTypings/ValueAndLabel";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../_styles/ReactSelectBootstrapStyle";

type Props = {
    task: ITask;
    onEdit: (updated: boolean) => void;
};

export interface ITaskMetadataPlantMachineryChecklist {
    identityId: string;
    checklistItems: ITaskChecklistItem[];
}

const TaskPlantMachineryChecklistFull: React.FC<Props> = ({ task, onEdit }) => {
    var test: ITaskMetadataPlantMachineryChecklist | undefined;
    if (task.metadata) {
        var data = task.metadata.find((item) => item.name === "plantMachineryChecklistTaskToCreate")?.value;
        if (data) {
            test = JSON.parse(data);
        }
    }
    const [currentTaskMetadata, setCurrentTaskMetadata] = useState<ITaskMetadata[] | undefined>(task.metadata);
    const [loadingLeadEngineerIdentity, setLoadingLeadEngineerIdentity] = useState(true);
    const [leadEngineerIdentity, setLeadEngineerIdentity] = useState<IValueAndLabel>();
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();

    useEffect(() => {
        if (test !== undefined) {
            userService.getAllListItems().then((identities) => {
                setAllIdentities(identities);
                setLeadEngineerIdentity(identities.find((item: IValueAndLabel) => item.value === test?.identityId));
                setLoadingLeadEngineerIdentity(false);
            });
            //checklistDefinitionService.getAllActive().then(checklistDefinitions => {
            //    setAllChecklistDefinitions(checklistDefinitions)
            //    setChecklistDefinition(checklistDefinitions.find((item: IChecklistDefinition) => item.id === checklistDefinitionId))
            //    setLoadingChecklistDefinition(false)
            //})
        }
    }, []);

    const handleAddChecklist = () => {
        var updatedChecklistTasksToCreate: ITaskMetadataPlantMachineryChecklist = { identityId: "", checklistItems: [] } as ITaskMetadataPlantMachineryChecklist;
        const updatedTaskMetadata = currentTaskMetadata?.map((item) => {
            if (item.name === "plantMachineryChecklistTaskToCreate") {
                item.value = JSON.stringify(updatedChecklistTasksToCreate);
            }
            return item;
        });
        if (!updatedTaskMetadata?.some((item) => item.name === "plantMachineryChecklistTaskToCreate")) {
            updatedTaskMetadata?.push({ id: undefined, name: "plantMachineryChecklistTaskToCreate", value: JSON.stringify(updatedChecklistTasksToCreate) } as ITaskMetadata);
        }
        handleUpdate(updatedTaskMetadata);
    };

    const handleEditChecklist = (identityId?: string, checklistItems?: ITaskChecklistItem[]) => {
        const updatedChecklistTasksToCreate = { identityId: identityId ?? "", checklistItems: checklistItems ?? [] } as ITaskMetadataPlantMachineryChecklist;
        const updatedTaskMetadata = currentTaskMetadata?.map((item) => {
            if (item.name === "plantMachineryChecklistTaskToCreate") {
                item.value = JSON.stringify(updatedChecklistTasksToCreate);
            }
            return item;
        });
        handleUpdate(updatedTaskMetadata);
    };

    const handleUpdate = (updatedTaskMetadata: ITaskMetadata[] | undefined) => {
        var updatedTask = clone(task);
        updatedTask.metadata = updatedTaskMetadata;
        taskService.edit(updatedTask).then((response) => {
            onEdit(true);
            setCurrentTaskMetadata(updatedTaskMetadata);
        });
    };

    return (
        <>
            <Stack direction="vertical">
                <div className="bg-light px-2 py-1 mb-2">
                    <Row>
                        <Col xs={3}>Lead Engineer</Col>
                        <Col xs={9}>Checklist Items</Col>
                    </Row>
                </div>
                {test ? (
                    <Row>
                        <Col xs={3}>
                            {allIdentities && (
                                <Select
                                    defaultValue={allIdentities.find((item) => item.value === test?.identityId)}
                                    styles={ReactSelectBootstrapStyle}
                                    className="stateManagedSelect z-2"
                                    onChange={(value) => {
                                        setLeadEngineerIdentity(value == null ? "" : value);
                                        handleEditChecklist((value == null ? "" : value)?.value, test?.checklistItems);
                                    }}
                                    isClearable
                                    options={allIdentities}
                                />
                            )}
                        </Col>
                        <Col xs={9}>
                            <TaskPlantMachineryChecklist
                                taskChecklistItemOptions={test.checklistItems}
                                onChange={(taskChecklistItems?: ITaskChecklistItem[]) => {
                                    handleEditChecklist(test?.identityId, taskChecklistItems);
                                }}
                            />
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <Button variant="outline-primary" className="w-auto mb-1 text-start" onClick={() => handleAddChecklist()}>
                            <Stack direction="horizontal" gap={2}>
                                <FontAwesomeIcon icon={faPlus} size="2x" className="me-1" />
                                Add Checklist
                            </Stack>
                        </Button>
                    </div>
                )}
            </Stack>
        </>
    );
};

export { TaskPlantMachineryChecklistFull };
