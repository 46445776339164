import { IValueAndLabel } from "../../customTypings/ValueAndLabel";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Col, Row } from "react-bootstrap";
import { LoadingSpinner } from "../LoadingSpinner";
import { taskService, userService } from "../../_services";
import { ITask } from "../../customTypings/Task";
import dayjs from "dayjs";
import Select from "react-select";
import { ReactSelectBootstrapStyle } from "../../_styles/ReactSelectBootstrapStyle";

type Props = {
    show: boolean;
    onClose: (updated: boolean) => void;
    task: ITask;
    editable?: string[];
};

const EditGivenTask: React.FC<Props> = ({ show, onClose, task, editable = [] }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [allIdentities, setAllIdentities] = useState<IValueAndLabel[]>();

    const handleClose = () => onClose(false);

    useEffect(() => {
        userService.getAllListItems().then((identities) => setAllIdentities(identities));
    }, []);

    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Formik
                initialValues={{
                    assignee: task.assigneeIdentityId,
                    assigneeTwo: task.secondAssigneeIdentityId,
                    name: task.name,
                    dueDate: dayjs(task.dueDate).format("YYYY-MM-DD"),
                    notes: task.notes,
                }}
                onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus();
                    setSubmitting(false);
                    const updatedTask: ITask = {
                        id: task.id,
                        name: values.name,
                        dueDate: new Date(values.dueDate),
                        notes: values.notes,
                        jobId: task.jobId,
                        taskType: task.taskType,
                        creationDate: task.creationDate,
                        completedDate: task.completedDate,
                        originalDueDate: task.dueDate,
                        assigneeIdentityId: values.assignee === "" ? undefined : values.assignee,
                        secondAssigneeIdentityId: values.assigneeTwo === "" ? undefined : values.assigneeTwo,
                        metadata: task.metadata,
                        actionDate: task.actionDate,
                    };
                    taskService.edit(updatedTask).then(
                        (response) => {
                            setSubmitting(false);
                            if (response.status !== "Failure") {
                                onClose(true);
                            } else {
                                setAlertVariant("danger");
                                setStatus(response.message);
                            }
                        },
                        (error) => {
                            setAlertVariant("danger");
                            if (error.status === 400) {
                                setStatus(error.title);
                                setFieldError("name", error.errors.Name);
                                setFieldError("job", error.errors.Job);
                                setFieldError("dueDate", error.errors.DueDate);
                            } else {
                                setStatus(error);
                            }
                            setSubmitting(false);
                        }
                    );
                }}
            >
                {({ errors, status, touched, isSubmitting, handleSubmit, values, handleChange, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Task</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {editable.includes("assignee") && allIdentities && (
                                <Form.Group as={Row} className="mb-3" controlId="editGivenTaskAssignee">
                                    <Form.Label column sm={3}>
                                        Assignee
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("assignee", value == null ? "" : value.value)}
                                            defaultValue={allIdentities.find((obj) => {
                                                return obj.value === task.assigneeIdentityId;
                                            })}
                                            isClearable
                                            options={allIdentities}
                                        />
                                        <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            {editable.includes("assignee") && allIdentities && (
                                <Form.Group as={Row} className="mb-3" controlId="editGivenTaskAssignee2">
                                    <Form.Label column sm={3}>
                                        Assignee 2
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Select
                                            styles={ReactSelectBootstrapStyle}
                                            className="stateManagedSelect"
                                            onChange={(value) => setFieldValue("assigneeTwo", value == null ? "" : value.value)}
                                            defaultValue={allIdentities.find((obj) => {
                                                return obj.value === task.secondAssigneeIdentityId;
                                            })}
                                            isClearable
                                            options={allIdentities}
                                        />
                                        <ErrorMessage name="job" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            {editable.includes("name") && (
                                <Form.Group as={Row} className="mb-3" controlId="editGivenTaskName">
                                    <Form.Label column sm={3}>
                                        Task Name
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            {editable.includes("dueDate") && (
                                <Form.Group as={Row} className="mb-3" controlId="editGivenTaskDueDate">
                                    <Form.Label column sm={3}>
                                        Due Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Field name="dueDate" type="date" className={"form-control" + (errors.dueDate && touched.dueDate ? " is-invalid" : "")} />
                                        <ErrorMessage name="dueDate" component="div" className="invalid-feedback" />
                                    </Col>
                                </Form.Group>
                            )}
                            {editable.includes("notes") && (
                                <Form.Group as={Row} className="mb-3" controlId="editGivenTaskNotes">
                                    <Form.Label column sm={3}>
                                        Notes
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control as="textarea" name="notes" value={values.notes} onChange={handleChange} isValid={touched.notes && !errors.notes} />
                                    </Col>
                                </Form.Group>
                            )}
                            {status && (
                                <Alert variant={alertVariant} className="mt-3">
                                    {status}
                                </Alert>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="form-group">
                                <Button variant="primary" disabled={isSubmitting} type="submit" className="me-2">
                                    {isSubmitting ? <LoadingSpinner text="Updating task..." /> : "Update task"}
                                </Button>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export { EditGivenTask };
