import { IJob } from '@/customTypings/Job';
import { IJobNew } from '@/customTypings/JobNew';
import { authHeader, handleResponse } from '../_helpers';

export const jobService = {
    getAllListItems,
    add,
    addFull,
    get,
    getJobFieldDefinitions,
    getExtraJobFieldDefinitions,
    getEnquiry,
    getCustomerDetails,
    disable,
    edit,
    getRelatedJobIds,
};

function getAllListItems() {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/job/alllistitems`, requestOptions).then(handleResponse);
}

function add(job: IJob) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(job)
    };
    return fetch(`api/job/add`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function addFull(job: IJobNew) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(job)
    };
    return fetch(`api/job/addfull`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function get(jobId: string) {
    const requestOptions:RequestInit = {
        method: 'GET',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/job/get/${jobId}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getJobFieldDefinitions() {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/jobfielddefinition/allstandard`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getExtraJobFieldDefinitions(jobTypeIds: string[]) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(jobTypeIds)
    };
    return fetch(`api/jobfielddefinition/allfilterd`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getEnquiry(id: string) {
    const requestOptions = { method: 'POST', headers: authHeader() };
    return fetch(`api/job/getenquiry?id=${id}`, requestOptions).then(handleResponse);
}

function getCustomerDetails(id: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/job/getcustomerdetails?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function disable(id: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader()
    };
    return fetch(`api/job/disable?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function edit(job: IJob){
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
        body: JSON.stringify(job)
    };
    return fetch(`api/job/edit`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}

function getRelatedJobIds(id: string) {
    const requestOptions:RequestInit = {
        method: 'POST',
        credentials: 'include', 
        headers: authHeader(),
    };
    return fetch(`api/job/getrelatedjobids?id=${id}`, requestOptions).then(handleResponse)
    .then(response => {
        return response;
    });
}