import React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
    show: boolean;
    onClose: (confirmed: boolean) => void;
    title?: string;
    body?: string;
    buttonSuccessVariant?: string;
    buttonSuccessText?: string;
    buttenCancelText?: string;
};
const ConfirmationModel: React.FC<Props> = ({ show, onClose, title, body, buttonSuccessVariant, buttonSuccessText, buttenCancelText }) => {
    const handleClose = () => onClose(false);
    return (
        <Modal centered show={show} keyboard={false} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title ? title : "Confirmation"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body ? body : "Are you sure?"}</Modal.Body>
            <Modal.Footer>
                <div className="form-group">
                    <Button variant={buttonSuccessVariant ? buttonSuccessVariant : "primary"} onClick={() => onClose(true)} className="me-2">
                        {buttonSuccessText ? buttonSuccessText : "Yes"}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {buttenCancelText ? buttenCancelText : "No"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
export { ConfirmationModel };
