import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";

import { invitationService, rolesService } from "../_services";
import { Alert, Button, Modal, Form, Col, Row, Stack, Placeholder } from "react-bootstrap";
import { LoadingSpinner } from "./LoadingSpinner";
import { IRole } from "../customTypings/role";
import { IInvitation } from "../customTypings/Invitation";
import Skeleton from "react-loading-skeleton";

type Props = {
    show: boolean;
    invitationId: string;
    onClose: (updated: boolean) => void;
};

const InvitationRoleEdit: React.FC<Props> = ({ show, invitationId, onClose }) => {
    const [alertVariant, setAlertVariant] = useState("danger");
    const [loading, setLoading] = useState(true);
    const [allRoles, setAllRoles] = useState<IRole[]>();
    const [invitation, setInvitation] = useState<IInvitation>();

    useEffect(() => {
        if (show) {
            setLoading(true);
            Promise.all([rolesService.getAllForGroup(0), invitationService.getById(invitationId)]).then((response) => {
                setAllRoles(response[0]);
                setInvitation(response[1]);
                setLoading(false);
            });
        }
    }, [invitationId, show]);

    const labelSpan = 3;
    const fieldSpan = 9;

    return (
        <Modal centered show={show} keyboard={false} onHide={() => onClose(false)}>
            {loading || !allRoles || !invitation ? (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Roles</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Email</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Organisation</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md={labelSpan}>Roles</Col>
                            <Col md={fieldSpan}>
                                <Skeleton width="20rem" height="2rem" />
                                <Skeleton width="20rem" height="2rem" />
                                <Skeleton width="20rem" height="2rem" />
                                <Skeleton width="20rem" height="2rem" />
                                <Skeleton width="20rem" height="2rem" />
                                <Skeleton width="20rem" height="2rem" />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Placeholder.Button variant="primary" xs={3} className="me-2" />
                        <Placeholder.Button variant="secondary" xs={2} />
                    </Modal.Footer>
                </>
            ) : (
                <Formik
                    initialValues={{
                        roles: invitation.roles.map((role) => role.id),
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        invitationService.updateRoles(invitation.tokenId, values.roles).then(
                            (response) => {
                                setSubmitting(false);
                                if (response.status !== "Failure") {
                                    onClose(true);
                                } else {
                                    setAlertVariant("danger");
                                    setStatus(response.message);
                                }
                            },
                            (error) => {
                                setAlertVariant("danger");
                                if (error.status === 400) {
                                    setStatus(error.title);
                                } else {
                                    setStatus(error);
                                }
                                setSubmitting(false);
                            }
                        );
                    }}
                >
                    {({ status, isSubmitting, handleSubmit }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Roles</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Stack gap={3}>
                                    <Form.Group as={Row} className="mb-3" controlId="email">
                                        <Form.Label column sm={labelSpan}>
                                            Email
                                        </Form.Label>
                                        <Col sm={fieldSpan}>
                                            <Form.Control type="text" placeholder={invitation.email} aria-label={invitation.email} disabled readOnly />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="organisationName">
                                        <Form.Label column sm={labelSpan}>
                                            Organisation
                                        </Form.Label>
                                        <Col sm={fieldSpan}>
                                            <Form.Control type="text" placeholder={invitation.organisationName} aria-label={invitation.organisationName} disabled readOnly />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="roleCheckboxs">
                                        <Form.Label column sm={labelSpan}>
                                            Roles
                                        </Form.Label>
                                        <Col sm={fieldSpan} className="pt-2">
                                            {allRoles &&
                                                allRoles.map((role) => (
                                                    <label key={role.id} className="d-block">
                                                        <Field type="checkbox" name="roles" value={role.id} className="me-2" />
                                                        {role.name}
                                                    </label>
                                                ))}
                                        </Col>
                                    </Form.Group>
                                    {status && (
                                        <Alert variant={alertVariant} className="mt-3">
                                            {status}
                                        </Alert>
                                    )}
                                </Stack>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="form-group">
                                    <Button variant="primary" disabled={isSubmitting} type="submit">
                                        {isSubmitting ? <LoadingSpinner text="Updating..." /> : "Update"}
                                    </Button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

export { InvitationRoleEdit };
